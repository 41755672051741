import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_CATEGORIES_DATA,
	CLEAR_ALL_CATEGORIES_ERRORS,
	CLEAR_CATEGORIES_SINGLE_ERROR,
	CATEGORY_CREATE_LOADING,
	SET_CATEGORY_ID,
	CATEGORY_CREATE_ERROR,
	ALL_CATEGORIES_LOADING,
	ALL_CATEGORIES_GET,
	ALL_CATEGORIES_ERROR,
	CATEGORY_INFO_LOADING,
	CATEGORY_INFO_GET,
	CATEGORY_INFO_ERROR,
	CATEGORY_UPDATE_LOADING,
	CATEGORY_UPDATE_ERROR,
	CATEGORY_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_categories_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_CATEGORIES_DATA
	});
}

export const clear_categories_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_CATEGORIES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_CATEGORIES_ERRORS
		});
	}
}

export const set_category_create_id = (categoryId) => dispatch => {
	dispatch({
		type: SET_CATEGORY_ID,
		payload: categoryId
	});
}

export const category_create = (messageApi, categoryBody, stepInfo) => dispatch => {
	dispatch(clear_categories_errors('category_create'));

	dispatch({
		type: CATEGORY_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/categories/create';

	Axios.post(url, categoryBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('ingredients.categories.create.successAlert'), 4);

		dispatch(set_category_create_id(res.data.category));

		dispatch({
			type: CATEGORY_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_categories({ step: stepInfo._id.$oid }));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CATEGORY_CREATE_ERROR,
			payload: { type: 'category_create', msg: err }
		});

		dispatch({
			type: CATEGORY_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_categories = (filters) => dispatch => {
	dispatch({
		type: ALL_CATEGORIES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/categories?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newCategoriesArray = add_key_prop(res.data.categories);
		res.data.categories = newCategoriesArray;

		dispatch({
			type: ALL_CATEGORIES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_CATEGORIES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_CATEGORIES_ERROR,
			payload: { type: 'get_all_categories', msg: err }
		});

		dispatch({
			type: ALL_CATEGORIES_LOADING,
			payload: false
		});
	})
}

export const get_category_info = (categoryId) => dispatch => {
	dispatch({
		type: CATEGORY_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/categories/${categoryId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: CATEGORY_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: CATEGORY_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CATEGORY_INFO_ERROR,
			payload: { type: 'get_category_info', msg: err }
		});

		dispatch({
			type: CATEGORY_INFO_LOADING,
			payload: false
		});
	})
}

export const category_update = (messageApi, categoryId, categoryBody, handleCancel) => dispatch => {
	dispatch(clear_categories_errors('category_update'));

	dispatch({
		type: CATEGORY_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/categories/${categoryId}/update`;

	Axios.put(url, categoryBody)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.categories.edit.successAlert'), 4);

		dispatch({
			type: CATEGORY_UPDATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: CATEGORY_UPDATE_ERROR,
				payload: { type: 'category_update', msg: err }
			});
		}

		dispatch({
			type: CATEGORY_UPDATE_LOADING,
			payload: false
		});
	})
}

export const category_delete = (messageApi, categoryId, filters) => dispatch => {
	dispatch({
		type: CATEGORY_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/categories/${categoryId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.categories.delete.successAlert'), 4);

		dispatch({
			type: CATEGORY_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_categories(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('ingredients.categories.delete.errorAlert'), 4);

		dispatch({
			type: CATEGORY_DELETE_LOADING,
			payload: false
		});
	})
}
