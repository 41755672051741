import {
	CLEAR_ALL_HAPTICS_DATA,
	CLEAR_ALL_HAPTICS_ERRORS,
	CLEAR_HAPTICS_SINGLE_ERROR,
	HAPTIC_CREATE_LOADING,
	HAPTIC_CREATE_ERROR,
	ALL_HAPTICS_LOADING,
	ALL_HAPTICS_GET,
	ALL_HAPTICS_ERROR,
	HAPTIC_INFO_LOADING,
	HAPTIC_INFO_GET,
	HAPTIC_INFO_ERROR,
	HAPTIC_UPDATE_LOADING,
	HAPTIC_UPDATE_ERROR,
	HAPTIC_DELETE_LOADING
} from '../types';

const initialState = {
	haptic_create_loading: false,
	all_haptics_loading: false,
	all_haptics: { count: 0, haptics: [] },
	haptic_info_loading: false,
	haptic_info: {},
	haptic_update_loading: false,
	haptic_delete_loading: false,
	haptics_errors: {}
}

export default function hapticsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_HAPTICS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_HAPTICS_ERRORS:
			return {
				...state,
				haptics_errors: {}
			}
		case CLEAR_HAPTICS_SINGLE_ERROR:
			return {
				...state,
				haptics_errors: Object.keys(state.haptics_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.haptics_errors[key];
					}
					return acc;
				}, {})
			}
		case HAPTIC_CREATE_LOADING:
			return {
				...state,
				haptic_create_loading: action.payload
			}
		case ALL_HAPTICS_LOADING:
			return {
				...state,
				all_haptics_loading: action.payload
			}
		case ALL_HAPTICS_GET:
			return {
				...state,
				all_haptics: {
					count: action.payload.count,
					haptics: action.payload.haptics
				}
			}
		case HAPTIC_INFO_LOADING:
			return {
				...state,
				haptic_info_loading: action.payload
			}
		case HAPTIC_INFO_GET:
			return {
				...state,
				haptic_info: action.payload
			}
		case HAPTIC_UPDATE_LOADING:
			return {
				...state,
				haptic_update_loading: action.payload
			}
		case HAPTIC_DELETE_LOADING:
			return {
				...state,
				haptic_delete_loading: action.payload
			}
		case HAPTIC_CREATE_ERROR:
		case ALL_HAPTICS_ERROR:
		case HAPTIC_INFO_ERROR:
		case HAPTIC_UPDATE_ERROR:
			return {
				...state,
				haptics_errors: {
					...state.haptics_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
