import Axios from 'axios';
import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode";

import { SET_CURRENT_USER  } from '../types';

import { get_user_info } from './usersActions';

const cookies = new Cookies ();

// set logged user
export const user_set_current = (decoded) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	}
}

// log user out
export const auth_token_remove = () => dispatch => {
	// const cookies = new Cookies ();
	process.env.REACT_APP_RUNTIME === 'Production' || process.env.REACT_APP_RUNTIME === 'Test' ?
		cookies.remove (process.env.REACT_APP_JWT_KEY, {
			path: '/', 
			domain: '.kitchenandmanners.com'
		}) :
		cookies.remove (process.env.REACT_APP_JWT_KEY, {
			path: '/', 
			// domain: '.localhost.com'
			domain: 'localhost'
		});
	
	// remove auth header for future requests
	localStorage.clear();
	window.location.href = process.env.REACT_APP_LOGIN;
	auth_token_set (null);
	dispatch (user_set_current ({}));
};

// check for user token
export const user_token_check = (store) => {
	let jwt = cookies.get (process.env.REACT_APP_JWT_KEY);

	if (jwt) {
		let decoded = jwtDecode (jwt);
		auth_token_set (jwt);           // set auth token header auth
		store.dispatch (user_set_current (decoded));
		store.dispatch (get_user_info(decoded.user));

		const authURL = process.env.REACT_APP_SERVER_URL + '/api/users/auth';
		// Axios.get(authURL)
		// .then(() => {
		// 	// Manage user auth (main) request
		// })
		// .catch(() => {
		// 	// Manage errors beacuse user is not authenticated
		// 	store.dispatch (auth_token_remove());
		// });
	}
	else {
		console.warn('Missing token. Redirecting to login page.')
	}
}

// set auth token for each request
const auth_token_set = token => {

	// Apply to every request
	if (token) Axios.defaults.headers.common['Authorization'] = token;
	else delete Axios.defaults.headers.common['Authorization'];
	
};



// queso blanco
// mano en el perejil
// sal en la mano

// nota - haptic quitar relleno