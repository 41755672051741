import {
	CLEAR_ALL_ACTIONS_DATA,
	CLEAR_ALL_ACTIONS_ERRORS,
	CLEAR_ACTIONS_SINGLE_ERROR,
	ACTION_CREATE_LOADING,
	ACTION_CREATE_ERROR,
	ALL_ACTIONS_LOADING,
	ALL_ACTIONS_GET,
	ALL_ACTIONS_ERROR,
	ACTION_INFO_LOADING,
	ACTION_INFO_GET,
	ACTION_INFO_ERROR,
	ACTION_UPDATE_LOADING,
	ACTION_UPDATE_ERROR,
	ACTION_DELETE_LOADING
} from '../types';

const initialState = {
	action_create_loading: false,
	all_actions_loading: false,
	all_actions: { count: 0, actions: [] },
	action_info_loading: false,
	action_info: {},
	action_update_loading: false,
	action_delete_loading: false,
	actions_errors: {}
}

export default function actionsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_ACTIONS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_ACTIONS_ERRORS:
			return {
				...state,
				actions_errors: {}
			}
		case CLEAR_ACTIONS_SINGLE_ERROR:
			return {
				...state,
				actions_errors: Object.keys(state.actions_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.actions_errors[key];
					}
					return acc;
				}, {})
			}
		case ACTION_CREATE_LOADING:
			return {
				...state,
				action_create_loading: action.payload
			}
		case ALL_ACTIONS_LOADING:
			return {
				...state,
				all_actions_loading: action.payload
			}
		case ALL_ACTIONS_GET:
			return {
				...state,
				all_actions: {
					count: action.payload.count,
					actions: action.payload.actions
				}
			}
		case ACTION_INFO_LOADING:
			return {
				...state,
				action_info_loading: action.payload
			}
		case ACTION_INFO_GET:
			return {
				...state,
				action_info: action.payload
			}
		case ACTION_UPDATE_LOADING:
			return {
				...state,
				action_update_loading: action.payload
			}
		case ACTION_DELETE_LOADING:
			return {
				...state,
				action_delete_loading: action.payload
			}
		case ACTION_CREATE_ERROR:
		case ALL_ACTIONS_ERROR:
		case ACTION_INFO_ERROR:
		case ACTION_UPDATE_ERROR:
			return {
				...state,
				actions_errors: {
					...state.actions_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
