import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Avatar, Button, Card, Col, Descriptions, Empty, Flex, Row, Space, Spin, Tabs, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import GameProgress from '../gameProgress/GameProgress';
import Icon from '../../utils/Icon';

// Actions
import { get_user_info } from '../../../redux/actions/usersActions';
import { get_all_recipes, get_recipe_game_data } from '../../../redux/actions/recipesActions';
import { get_all_difficulties } from '../../../redux/actions/difficultiesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const UserDetails = (props) => {
	const { messageApi } = props;

	const { user_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { user_info } = useSelector(state => state.users);

	const { recipe_game_data_loading, recipe_game_data } = useSelector(state => state.recipes);
	
	useEffect(() => {
		const paginationFilters = { skip: 0, limit: 0 };
		dispatch(get_user_info(user_id));
		dispatch(get_all_recipes(paginationFilters));
		dispatch(get_recipe_game_data({ user: user_id }));
		dispatch(get_all_difficulties(paginationFilters));
	}, [dispatch]);

	return (
		<div>
			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate('/users')}
						/>
						<Typography.Title>{t('users.details.title')}</Typography.Title>
					</Space>
				</Col>
			</Row>

			<Card
				bordered={false}
			>
				<Flex
					align='flex-start'
					justify='flex-start'

				>
					<div>
						<Avatar style={{ marginRight: '2rem', background: '#F18B71' }} size={64}>
							{user_info?.first_name?.charAt(0)}{user_info?.last_name?.charAt(0)}
						</Avatar>
					</div>
					<Space direction='vertical'>
						<Typography.Title
							level={4}
							style={{ margin: 0 }}
							copyable={{ text: user_info?._id?.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}
						>
							{user_info?.first_name} {user_info?.last_name}
						</Typography.Title>
						<Descriptions
							items={[
								{
									key: 'username',
									label: t('form.label.username'),
									children: user_info?.username
								},
								{
									key: 'email',
									label: t('form.label.email'),
									children: user_info?.email
								}
							]}
						/>
					</Space>
				</Flex>
			</Card>

			<Tabs
				style={{ marginTop: '2rem' }}
				defaultActiveKey='progress'
				items={[
					{
						key: 'progress',
						icon: (
							<span>
								<Icon icon='fork_right' size={20} marginEnd='0.7rem' sider />
								{t('users.details.progress.title')}
							</span>
						),
						children: (
							<Spin spinning={recipe_game_data_loading && isEmpty(recipe_game_data)} size='large'>
								{recipe_game_data?.worlds?.map((world, index) => (
									<GameProgress
										key={index}
										messageApi={messageApi}
										world={world}
									/>
								))}
							</Spin>
						)
					},
					{
						key: 'purchase',
						icon: (
							<span>
								<Icon icon='shopping_cart' size={18} marginEnd='0.7rem' sider />
								{t('users.details.purchases.title')}
							</span>
						),
						children: (
							<div style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
								<Empty description={<Typography.Text type='secondary'>{t('table.error.noData')}</Typography.Text>} />
							</div>
						)
					}
				]}
			/>
		</div>
	)
}

UserDetails.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default UserDetails;
