import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_ingredients, set_ingredient_create_id, ingredient_create, ingredient_update, get_ingredient_info } from '../../../redux/actions/ingredientsActions';
import { get_all_categories } from '../../../redux/actions/categoriesActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { ingredient_types_options } from '../../../utils/select_options';
import { create_haptic_select_options, create_select_options } from '../../../utils/create_select_options';

const IngredientsForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, ingredientToUpdate, setIngredientToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { ingredient_create_loading, ingredient_id, ingredient_update_loading } = useSelector(state => state.ingredients);

	const { all_categories_loading, all_categories } = useSelector(state => state.categories);

	const [fileList, setFileList] = useState([]);

	const INGREDIENT_TYPES = ingredient_types_options();
	
	const [form] = Form.useForm();

	useEffect(() => {
		const filters = { skip: 0, limit: 0 };
		dispatch(get_all_categories(filters));
	}, []);	

	useEffect(() => {
		if (!isEmpty(ingredientToUpdate)) {
			form.setFieldsValue({
				name: ingredientToUpdate.name,
				description: ingredientToUpdate.description,
				category: ingredientToUpdate.category ? ingredientToUpdate.category._id.$oid : undefined,
				ingredient_type: ingredientToUpdate.ingredient_type,
				scale: ingredientToUpdate.scale,
				has_product_asset: ingredientToUpdate.has_product_asset,
				particles: ingredientToUpdate.particles === undefined ? false : ingredientToUpdate.particles,
			});
		}
	}, [ingredientToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setIsModalOpen(false);
		setIngredientToUpdate({});
		dispatch(set_ingredient_create_id(null));

		if (finish) {
			if (pageSize === 0) {
				// Edit from ingredient details page
				dispatch(get_ingredient_info(ingredientToUpdate._id.$oid));
			}
			else {
				// Edit from ingredients table
				const filters = { skip: skip, limit: pageSize };
				dispatch(get_all_ingredients(filters));
			}
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					dispatch(ingredient_create(values));
				}
				else {
					// Update
					dispatch(ingredient_update(messageApi, ingredient_id, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('ingredients.create.modalTitle') : t('ingredients.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? ingredient_create_loading : ingredient_update_loading}
					onClick={ingredient_id && !update ? () => handleCancel(true) : onOk}
				>
					{ingredient_id ? t('buttons.finish') : t('buttons.save')}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 7 }}
				form={form}
				initialValues={{ has_product_asset: false, particles: false }}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.category')}
					name='category'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						loading={all_categories_loading}
						placeholder={`${t('form.placeholders.select')} ${t('form.label.category')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_select_options(all_categories.categories, 'name')}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.type')}
					name='ingredient_type'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.type')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_haptic_select_options(INGREDIENT_TYPES)}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.scale')}
					name='scale'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber step={0.1} style={{ width: '100%' }} placeholder={t('form.placeholders.scale')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.hasProductAsset')}
					name='has_product_asset'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label={t('form.label.particles')}
					name='particles'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
			</Form>
			{ingredient_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '7.8rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/recipes/ingredients/${ingredient_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
				</Space>
			)}
		</Modal>
	)
}

IngredientsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	ingredientToUpdate: PropTypes.object.isRequired,
	setIngredientToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
}

export default IngredientsForm;
