import {
	CLEAR_ALL_LEVELS_DATA,
	CLEAR_ALL_LEVELS_ERRORS,
	CLEAR_LEVELS_SINGLE_ERROR,
	LEVEL_CREATE_LOADING,
	SET_LEVEL_ID,
	LEVEL_CREATE_ERROR,
	ALL_LEVELS_LOADING,
	ALL_LEVELS_GET,
	ALL_LEVELS_ERROR,
	LEVEL_INFO_LOADING,
	LEVEL_INFO_GET,
	LEVEL_INFO_ERROR,
	LEVEL_UPDATE_LOADING,
	LEVEL_UPDATE_ERROR,
	LEVEL_DELETE_LOADING
} from '../types';

const initialState = {
	level_create_loading: false,
	level_id: null,
	all_levels_loading: false,
	all_levels: { count: 0, levels: [] },
	level_info_loading: false,
	level_info: {},
	level_update_loading: false,
	level_delete_loading: false,
	levels_errors: {}
}

export default function levelsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_LEVELS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_LEVELS_ERRORS:
			return {
				...state,
				levels_errors: {}
			}
		case CLEAR_LEVELS_SINGLE_ERROR:
			return {
				...state,
				levels_errors: Object.keys(state.levels_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.levels_errors[key];
					}
					return acc;
				}, {})
			}
		case LEVEL_CREATE_LOADING:
			return {
				...state,
				level_create_loading: action.payload
			}
		case SET_LEVEL_ID:
			return {
				...state,
				level_id: action.payload
			}
		case ALL_LEVELS_LOADING:
			return {
				...state,
				all_levels_loading: action.payload
			}
		case ALL_LEVELS_GET:
			return {
				...state,
				all_levels: {
					count: action.payload.count,
					levels: action.payload.levels
				}
			}
		case LEVEL_INFO_LOADING:
			return {
				...state,
				level_info_loading: action.payload
			}
		case LEVEL_INFO_GET:
			return {
				...state,
				level_info: action.payload
			}
		case LEVEL_UPDATE_LOADING:
			return {
				...state,
				level_update_loading: action.payload
			}
		case LEVEL_DELETE_LOADING:
			return {
				...state,
				level_delete_loading: action.payload
			}
		case LEVEL_CREATE_ERROR:
		case ALL_LEVELS_ERROR:
		case LEVEL_INFO_ERROR:
		case LEVEL_UPDATE_ERROR:
			return {
				...state,
				levels_errors: {
					...state.levels_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
