import {
	CLEAR_ALL_UNITS_DATA,
	CLEAR_ALL_UNITS_ERRORS,
	CLEAR_UNITS_SINGLE_ERROR,
	UNIT_CREATE_LOADING,
	UNIT_CREATE_ERROR,
	ALL_UNITS_LOADING,
	ALL_UNITS_GET,
	ALL_UNITS_ERROR,
	UNIT_INFO_LOADING,
	UNIT_INFO_GET,
	UNIT_INFO_ERROR,
	UNIT_UPDATE_LOADING,
	UNIT_UPDATE_ERROR,
	UNIT_DELETE_LOADING
} from '../types';

const initialState = {
	unit_create_loading: false,
	all_units_loading: false,
	all_units: { count: 0, units: [] },
	unit_info_loading: false,
	unit_info: {},
	unit_update_loading: false,
	unit_delete_loading: false,
	units_errors: {}
}

export default function unitsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_UNITS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_UNITS_ERRORS:
			return {
				...state,
				units_errors: {}
			}
		case CLEAR_UNITS_SINGLE_ERROR:
			return {
				...state,
				units_errors: Object.keys(state.units_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.units_errors[key];
					}
					return acc;
				}, {})
			}
		case UNIT_CREATE_LOADING:
			return {
				...state,
				unit_create_loading: action.payload
			}
		case ALL_UNITS_LOADING:
			return {
				...state,
				all_units_loading: action.payload
			}
		case ALL_UNITS_GET:
			return {
				...state,
				all_units: {
					count: action.payload.count,
					units: action.payload.units
				}
			}
		case UNIT_INFO_LOADING:
			return {
				...state,
				unit_info_loading: action.payload
			}
		case UNIT_INFO_GET:
			return {
				...state,
				unit_info: action.payload
			}
		case UNIT_UPDATE_LOADING:
			return {
				...state,
				unit_update_loading: action.payload
			}
		case UNIT_DELETE_LOADING:
			return {
				...state,
				unit_delete_loading: action.payload
			}
		case UNIT_CREATE_ERROR:
		case ALL_UNITS_ERROR:
		case UNIT_INFO_ERROR:
		case UNIT_UPDATE_ERROR:
			return {
				...state,
				units_errors: {
					...state.units_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
