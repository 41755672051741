import { toSeconds } from "./set_video_time";

/**
 * @description - Create step body for API request
 * @param {Object} formValues - Form values
 * @param {Object} stepIndex - Step index
 * @returns {Object} - Body for API request
 */
export const create_step_body = (formValues, stepIndex) => {
	const cooking_process = formValues.cooking_process.map(element => {
		return {
			start: typeof element.start === 'object' ? element.start.value : element.start,
			end: typeof element.end === 'object' ? element.end.value : element.end,
			quantity: element.quantity,
			action: typeof element.type === 'object' ? element.type.value : element.type
		};
	});

	const items = formValues.items.map(element =>
		typeof element === 'object' ? element.value : element
	);
	
	const ingredientsMix = formValues.ingredients_mix !== null && typeof formValues.ingredients_mix === 'object'
		? formValues.ingredients_mix.value 
		: formValues.ingredients_mix;

	const goToItem = formValues.go_to_item !== null && typeof formValues.go_to_item === 'object'
		? formValues.go_to_item.value 
		: formValues.go_to_item;

	const step_action = formValues.step_action_type !== null && typeof formValues.step_action_type === 'object' 
	? formValues.step_action_type.value 
	: formValues.step_action_type;

	return {
		recipe_type: formValues.recipe_type,
		index: stepIndex,
		name: formValues.name,
		description: formValues.description,
		video: formValues.video,
		video_start: formValues.video_start ? toSeconds(formValues.video_start) : formValues.video_start,
		video_end: formValues.video_end ? toSeconds(formValues.video_end) : formValues.video_end,
		cooking_process: cooking_process,
		ingredients_mix: ingredientsMix,
		result_goes_to: formValues.result_goes_to,
		go_to_item: goToItem,
		items: items,
		equipment: formValues.equipment,
		step_action: step_action
	}
}