import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_USERS_DATA,
	CLEAR_ALL_USERS_ERRORS,
	CLEAR_USERS_SINGLE_ERROR,
	USER_CREATE_LOADING,
	USER_CREATE_ERROR,
	ALL_USERS_LOADING,
	ALL_USERS_GET,
	ALL_USERS_ERROR,
	USER_INFO_LOADING,
	USER_INFO_GET,
	USER_INFO_ERROR,
	USER_UPDATE_LOADING,
	USER_UPDATE_ERROR,
	USER_PASSWORD_UPDATE_LOADING,
	USER_PASSWORD_UPDATE_ERROR,
	USER_DELETE_LOADING,
	USER_PURCHASES_LOADING,
	USER_PURCHASES_GET,
	USER_PURCHASES_ERROR
} from '../types';

import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_users_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_USERS_DATA
	});
}

export const clear_users_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_USERS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_USERS_ERRORS
		});
	}
}

export const user_create = (userBody, handleCancel) => dispatch => {
	dispatch({
		type: USER_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/users/create';

	Axios.post(url, userBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: USER_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: USER_CREATE_ERROR,
			payload: { type: 'user_create', msg: err.message }
		});

		dispatch({
			type: USER_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_users = (filters) => dispatch => {
	dispatch({
		type: ALL_USERS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/users?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newUsersArray = add_key_prop(res.data.users);
		res.data.users = newUsersArray;

		dispatch({
			type: ALL_USERS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_USERS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_USERS_ERROR,
			payload: { type: 'get_all_users', msg: err.message }
		});

		dispatch({
			type: ALL_USERS_LOADING,
			payload: false
		});
	})
}

export const get_user_info = (userId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/users/${userId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: USER_INFO_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: USER_INFO_ERROR,
			payload: { type: 'get_user_info', msg: err.message }
		});
	})
}

export const user_update = (messageApi, userId, userBody, handleCancel) => dispatch => {
	dispatch({
		type: USER_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/users/${userId}/update`;

	Axios.put(url, userBody)
	.then((res) => {
		messageApi.success(i18next.t('users.edit.successAlert'), 4);

		dispatch({
			type: USER_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: USER_UPDATE_ERROR,
			payload: { type: 'user_update', msg: err.message }
		});

		dispatch({
			type: USER_UPDATE_LOADING,
			payload: false
		});
	})
}

export const user_password_update = (messageApi, userId, userBody, resetFields) => dispatch => {
	dispatch({
		type: USER_PASSWORD_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/users/${userId}/password`;

	Axios.put(url, userBody)
	.then((res) => {
		messageApi.success(i18next.t('users.editPassword.successAlert'), 4);

		dispatch({
			type: USER_PASSWORD_UPDATE_LOADING,
			payload: false
		});

		resetFields();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: USER_PASSWORD_UPDATE_ERROR,
			payload: { type: 'user_password_update', msg: i18next.t('users.editPassword.errorAlert') }
		});

		dispatch({
			type: USER_PASSWORD_UPDATE_LOADING,
			payload: false
		});

		resetFields();
	})
}

export const user_delete = (messageApi, userId, filters) => dispatch => {
	dispatch({
		type: USER_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/users/${userId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('users.delete.successAlert'), 4);

		dispatch({
			type: USER_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_users(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('users.delete.errorAlert'), 4);

		dispatch({
			type: USER_DELETE_LOADING,
			payload: false
		});
	})
}

export const get_all_user_purchases = (filters) => dispatch => {
	dispatch({
		type: USER_PURCHASES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/users?'; // FIXME: Change this to the correct URL
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newUsersArray = add_key_prop(res.data.users); // FIXME: Change this to the correct key
		res.data.users = newUsersArray;

		dispatch({
			type: USER_PURCHASES_GET,
			payload: res.data
		});

		dispatch({
			type: USER_PURCHASES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: USER_PURCHASES_ERROR,
			payload: { type: 'get_all_user_purchases', msg: err.message }
		});

		dispatch({
			type: USER_PURCHASES_LOADING,
			payload: false
		});
	})
}
