const add_key_prop = (array) => {
	const newArray = array.map((item) => (
		{
			...item,
			key: item._id.$oid
		}
	));

	return newArray;
}

export default add_key_prop;