import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_WORLDS_DATA,
	CLEAR_ALL_WORLDS_ERRORS,
	CLEAR_WORLDS_SINGLE_ERROR,
	WORLD_CREATE_LOADING,
	SET_WORLD_ID,
	WORLD_CREATE_ERROR,
	ALL_WORLDS_LOADING,
	ALL_WORLDS_GET,
	ALL_WORLDS_ERROR,
	WORLD_INFO_LOADING,
	WORLD_INFO_GET,
	WORLD_INFO_ERROR,
	WORLD_UPDATE_LOADING,
	WORLD_UPDATE_ERROR,
	WORLD_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_worlds_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_WORLDS_DATA
	});
}

export const clear_worlds_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_WORLDS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_WORLDS_ERRORS
		});
	}
}

export const set_world_create_id = (worldId) => dispatch => {
	dispatch({
		type: SET_WORLD_ID,
		payload: worldId
	});
}

export const world_create = (worldBody) => dispatch => {
	dispatch({
		type: WORLD_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/worlds/create';

	Axios.post(url, worldBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_world_create_id(res.data.world));

		dispatch({
			type: WORLD_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORLD_CREATE_ERROR,
			payload: { type: 'world_create', msg: err.message }
		});

		dispatch({
			type: WORLD_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_worlds = (filters) => dispatch => {
	dispatch({
		type: ALL_WORLDS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/worlds?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newWorldsArray = add_key_prop(res.data.worlds);
		res.data.worlds = newWorldsArray;

		dispatch({
			type: ALL_WORLDS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_WORLDS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_WORLDS_ERROR,
			payload: { type: 'get_all_worlds', msg: err.message }
		});

		dispatch({
			type: ALL_WORLDS_LOADING,
			payload: false
		});
	})
}

export const get_world_info = (worldId) => dispatch => {
	dispatch({
		type: WORLD_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/worlds/${worldId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: WORLD_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: WORLD_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORLD_INFO_ERROR,
			payload: { type: 'get_world_info', msg: err.message }
		});

		dispatch({
			type: WORLD_INFO_LOADING,
			payload: false
		});
	})
}

export const world_update = (messageApi, worldId, worldBody, handleCancel) => dispatch => {
	dispatch({
		type: WORLD_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/worlds/${worldId}/update`;

	Axios.put(url, worldBody)
	.then((res) => {
		messageApi.success(i18next.t('worlds.edit.successAlert'), 4);

		dispatch({
			type: WORLD_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: WORLD_UPDATE_ERROR,
				payload: { type: 'world_update', msg: err.message }
			});
		}

		dispatch({
			type: WORLD_UPDATE_LOADING,
			payload: false
		});
	})
}

export const world_delete = (messageApi, worldId, filters) => dispatch => {
	dispatch({
		type: WORLD_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/worlds/${worldId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('worlds.delete.successAlert'), 4);

		dispatch({
			type: WORLD_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_worlds(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('worlds.delete.errorAlert'), 4);

		dispatch({
			type: WORLD_DELETE_LOADING,
			payload: false
		});
	})
}
