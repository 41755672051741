import {
	CLEAR_ALL_TYPES_DATA,
	CLEAR_ALL_TYPES_ERRORS,
	CLEAR_TYPES_SINGLE_ERROR,
	TYPE_CREATE_LOADING,
	TYPE_CREATE_ERROR,
	ALL_TYPES_LOADING,
	ALL_TYPES_GET,
	ALL_TYPES_ERROR,
	TYPE_INFO_LOADING,
	TYPE_INFO_GET,
	TYPE_INFO_ERROR,
	TYPE_UPDATE_LOADING,
	TYPE_UPDATE_ERROR,
	TYPE_DELETE_LOADING
} from '../types';

const initialState = {
	type_create_loading: false,
	all_types_loading: false,
	all_types: { count: 0, types: [] },
	type_info_loading: false,
	type_info: {},
	type_update_loading: false,
	type_delete_loading: false,
	types_errors: {}
}

export default function typesReducer (state = initialState, type) {
	switch (type.type) {
		case CLEAR_ALL_TYPES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_TYPES_ERRORS:
			return {
				...state,
				types_errors: {}
			}
		case CLEAR_TYPES_SINGLE_ERROR:
			return {
				...state,
				types_errors: Object.keys(state.types_errors).reduce((acc, key) => {
					if (key !== type.payload) {
						acc[key] = state.types_errors[key];
					}
					return acc;
				}, {})
			}
		case TYPE_CREATE_LOADING:
			return {
				...state,
				type_create_loading: type.payload
			}
		case ALL_TYPES_LOADING:
			return {
				...state,
				all_types_loading: type.payload
			}
		case ALL_TYPES_GET:
			return {
				...state,
				all_types: {
					count: type.payload.count,
					types: type.payload.types
				}
			}
		case TYPE_INFO_LOADING:
			return {
				...state,
				type_info_loading: type.payload
			}
		case TYPE_INFO_GET:
			return {
				...state,
				type_info: type.payload
			}
		case TYPE_UPDATE_LOADING:
			return {
				...state,
				type_update_loading: type.payload
			}
		case TYPE_DELETE_LOADING:
			return {
				...state,
				type_delete_loading: type.payload
			}
		case TYPE_CREATE_ERROR:
		case ALL_TYPES_ERROR:
		case TYPE_INFO_ERROR:
		case TYPE_UPDATE_ERROR:
			return {
				...state,
				types_errors: {
					...state.types_errors, 
					[`${type.payload.type}`]: { msg: type.payload.msg }
				}
			}
		default:
			return state;
	}
}
