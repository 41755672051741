import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Button, Card, Col, ColorPicker, Divider, Flex, Form, Input, Row, Select, Skeleton, Space, Tag, Typography } from 'antd';
import { CloseOutlined, EditFilled, FilePdfFilled, LeftOutlined, SaveFilled, SettingFilled, SettingOutlined, YoutubeFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RecipeImageRender from '../images/RecipeImageRender';
import RecipeTutorialUpload from '../tutorial/RecipeTutorialUpload';
import ImageUpload from '../../utils/ImageUpload';
import ImageRender from '../../utils/ImageRender';
import { IngredientsList, ItemsList } from '../../utils/ItemsList';
import Steps from '../steps/Steps';
import RecipeIngredients from '../ingredients/RecipeIngredients';
import LevelForm from '../levels/LevelConfigForm';

// Actions
import { clear_all_recipes_data, get_recipe_data, recipe_status_update, recipe_update } from '../../../redux/actions/recipesActions';
import { clear_all_worlds_data, get_all_worlds, get_world_info } from '../../../redux/actions/worldsActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';
import { clear_all_levels_data, get_all_levels } from '../../../redux/actions/levelsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { format_recipe_name } from '../../../utils/format_recipe_name';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';
import { get_recipe_status_color } from '../../../utils/get_status_color';
import { get_recipe_status_desc } from '../../../utils/get_status_desc';
import { recipe_status_options } from '../../../utils/select_options';
import { format_hex_color_with_alpha, transform_color_format } from '../../../utils/colors_handlers';

const RecipesDetails = (props) => {
	const { messageApi } = props;

	const { recipe_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { recipe_data_loading, recipe_data } = useSelector(state => state.recipes);

	const { world_info_loading, all_worlds, world_info } = useSelector(state => state.worlds);

	const { all_levels } = useSelector(state => state.levels);

	const [editing, setEditing] = useState(false);
	const [recipeInfo, setRecipeInfo] = useState({});
	const [fileList, setFileList] = useState([]);
	const [recipeStatusOptions, setRecipeStatusOptions] = useState([]);
	const [showLevelConfigModal, setShowLevelConfigModal] = useState(false);
	const [update, setUpdate] = useState(false);
	const [levelConfigToUpdate, setLevelConfigToUpdate] = useState({});
	const [width, setWidth]   = useState(window.innerWidth);

	const updateDimensions = () => setWidth(window.innerWidth);

	const RECIPE_STATUS_OPTIONS = recipe_status_options();
	
	useEffect(() => {
		window.addEventListener('resize', updateDimensions);

		setRecipeStatusOptions(create_select_options_from_array(RECIPE_STATUS_OPTIONS));

		dispatch(get_recipe_data(recipe_id));
		dispatch(get_all_levels({ recipe: recipe_id }));

		return () => {
			dispatch(clear_all_recipes_data());
			dispatch(clear_all_worlds_data());
			dispatch(clear_all_levels_data());

			window.removeEventListener('resize', updateDimensions);
		}
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(recipe_data)) {
			dispatch(get_world_info(recipe_data?.recipe?.world?.$oid));
		}
	}, [recipe_data]);

	useEffect(() => {
		if (editing) {
			dispatch(get_all_worlds({ skip: 0, limit: 0 }));
		}
	}, [editing]);

	const handleEdit = () => {
		setEditing(!editing);

		setRecipeInfo({
			name: recipe_data?.recipe?.name,
			description: recipe_data?.recipe?.description,
			color: transform_color_format(recipe_data?.recipe?.color),
			video: recipe_data?.recipe?.video,
			world: recipe_data?.recipe?.world?.$oid,
			status: recipe_data?.recipe?.status
		});
	}

	const onChangeColor = (colorValue) => {
		const hexString = typeof colorValue === 'string' ? colorValue : colorValue?.toHex();
		const newColorValue = format_hex_color_with_alpha(hexString);

		setRecipeInfo({ ...recipeInfo, color: newColorValue });
	}

	const saveChanges = () => {
		setEditing(!editing);

		recipeInfo.name = format_recipe_name(recipeInfo.name);
		dispatch(recipe_update(messageApi, recipe_id, recipeInfo));

		if (recipe_data?.recipe?.status !== recipeInfo.status) {
			dispatch(recipe_status_update(messageApi, recipe_id, recipeInfo.status));
		}
	}

	const addLevel = () => {
		setShowLevelConfigModal(true);
		setUpdate(false);
		setLevelConfigToUpdate({});
	}

	const editLevel = () => {
		setShowLevelConfigModal(true);
		setUpdate(true);
		setLevelConfigToUpdate(all_levels.levels[0]);
	}

	return (
		<div>
			<LevelForm
				messageApi={messageApi}
				recipeData={recipe_data}
				isModalOpen={showLevelConfigModal}
				setIsModalOpen={setShowLevelConfigModal}
				update={update}
				levelConfigToUpdate={levelConfigToUpdate}
				setLevelConfigToUpdate={setLevelConfigToUpdate}
			/>

			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate('/recipes')}
						/>
						<Typography.Title>{t('recipes.details.title')}</Typography.Title>
					</Space>
				</Col>
				<Col xs={24} sm={6} md={6} lg={6} xl={6}>
					<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
						{editing &&
							<Button
								style={{ marginRight: '1rem' }}
								size='large'
								icon={<CloseOutlined />}
								onClick={() => setEditing(false)}
							>
								{t('buttons.cancel')}
							</Button>
						}
						<Button
							type='primary'
							size='large'
							icon={!editing ? <EditFilled /> : <SaveFilled />}
							onClick={!editing ? handleEdit : saveChanges}
						>
							{!editing ? t('buttons.edit') : t('buttons.save')}
						</Button>
					</Flex>
				</Col>
			</Row>

			<Card
				bordered={false}
			>
				<Flex
					align='flex-start'
					justify='flex-start'
				>
					{editing
						?	<div style={{ marginRight: '2rem' }}>
								<ImageUpload
									action={process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipe_id}/image/upload`}
									actionFunction={(options) => dispatch(form_image_upload(options, false))}
									fileList={fileList}
									setFileList={setFileList}
								/>
							</div>
						:	<RecipeImageRender
								recipeInfo={recipe_data}
								fileList={fileList}
								setFileList={setFileList}
							/>
					}
					<Skeleton loading={recipe_data_loading} active>
						<Space direction='vertical' size='small' style={{ display: 'flex', width: '100%' }}>
							<Row>
								<Col xs={24} sm={editing ? 24 : 23} md={editing ? 24 : 23} lg={editing ? 24 : 23} xl={editing ? 24 : 23}>
									<Typography.Title
										level={2}
										style={{ marginTop: 0 }}
										editable={{
											editing: editing,
											onChange: (value) => setRecipeInfo({ ...recipeInfo, name: value })
										}}
									>
										{recipe_data?.recipe?.name}
									</Typography.Title>
								</Col>
								{!editing &&
									<Col
										xs={24} sm={1} md={1} lg={1} xl={1}
										style={{ textAlign: 'right' }}
									>
										{all_levels.levels.length > 0
											?	<SettingFilled
													style={{ color: '#9E9E9E', fontSize: '2rem' }}
													onClick={editLevel}
												/>
											:	<SettingOutlined
													style={{ color: '#9E9E9E', fontSize: '2rem' }}
													onClick={addLevel}
												/>
										}
									</Col>
								}
							</Row>
							<Typography.Text
								type='secondary'
								editable={{
									editing: editing,
									onChange: (value) => setRecipeInfo({ ...recipeInfo, description: value })
								}}
							>
								{recipe_data?.recipe?.description}
							</Typography.Text>

							{editing
								?	<div>
										<Form
											style={{ marginTop: '0.5rem' }}
											initialValues={{
												color: transform_color_format(recipe_data?.recipe?.color),
												video: recipeInfo.video,
												status: { label: get_recipe_status_desc(recipeInfo.status), value: recipeInfo.status }
											}}
										>
											<Row>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={4} style={{ paddingLeft: 0, paddingRight: '0.75rem' }}>
													<Form.Item
														label={t('form.label.color')}
														name='color'
													>
														<ColorPicker
															format='hex'
															value={transform_color_format(recipe_data?.recipe?.color)}
															onChange={(value) => onChangeColor(value)}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={10} style={{ paddingLeft: 0, paddingRight: '0.75rem' }}>
													<Form.Item
														label={t('form.label.state')}
														name='status'
													>
														<Select
															showSearch
															optionFilterProp='children'
															filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
															options={recipeStatusOptions}
															onChange={(value) => setRecipeInfo({ ...recipeInfo, status: value })}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={10} style={{ paddingLeft: 0, paddingRight: '0.75rem' }}>
													<Form.Item
														label={t('form.label.video')}
														name='video'
														// rules={[
														// 	{
														// 		type: 'url',
														// 		message: t('form.rules.url')
														// 	}
														// ]}
													>
														<Input
															onChange={(e) => setRecipeInfo({ ...recipeInfo, video: e.target.value })}
														/>
													</Form.Item>
												</Col>
											</Row>
										</Form>
										<RecipeTutorialUpload
											messageApi={messageApi}
											recipeId={recipe_id}
											reload={false}
										/>
									</div>
								:	<Space size={[100, 8]} align='flex-start' style={{ marginTop: '1rem' }} wrap>
										<Space>
											<Typography.Text type='secondary'>{t('form.label.color')}:</Typography.Text>
											<Tag color={`#${transform_color_format(recipe_data?.recipe?.color)}`}>{recipe_data?.recipe?.color?.toUpperCase()}</Tag>
										</Space>
										<Space>
											<Typography.Text type='secondary'>{t('form.label.state')}:</Typography.Text>
											<Tag color={get_recipe_status_color(recipe_data?.recipe?.status)}>{get_recipe_status_desc(recipe_data?.recipe?.status)}</Tag>
										</Space>
										<Typography.Link
											href={'https://www.youtube.com/watch?v=' + recipe_data?.recipe?.video}
											target='_blank'
										>
											<YoutubeFilled /> {t('recipes.details.video')}
										</Typography.Link>
										{recipe_data?.recipe?.tutorial
											?	<Typography.Link
													href={`/recipes/${recipe_id}/tutorial`}
												>
													<FilePdfFilled /> {t('recipes.details.pdf')}
												</Typography.Link>
											:	<RecipeTutorialUpload
													messageApi={messageApi}
													recipeId={recipe_id}
													reload={true}
												/>
										}
									</Space>
							}
						</Space>
					</Skeleton>
				</Flex>
				
				<Divider />
				{editing
					?	<div>
							<Form
								initialValues={{
									world: world_info?._id?.$oid
								}}
							>
								<Form.Item
									label={t('form.label.world')}
									name='world'
									rules={[
										{
											required: true,
											message: t('form.rules.required'),
										}
									]}
								>
									<Select
										showSearch
										placeholder={t('form.placeholders.world')}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={create_select_options(all_worlds.worlds, 'name')}
										onChange={(value) => setRecipeInfo({ ...recipeInfo, world: value })}
									/>
								</Form.Item>
							</Form>
							
							<RecipeIngredients
								messageApi={messageApi}
								recipeId={recipe_id}
								showFinishButton={false}
							/>
						</div>
					:	<Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
							<Col
								xs={24} sm={24} md={24} lg={8} xl={8}
								style={ width > 990 ? { borderRight: '1px solid #d9d9d9' } : { borderBottom: '1px solid #d9d9d9', paddingBottom: '1.5rem' }}
							>
								<Typography.Title level={4} style={{ marginTop: 0, marginBottom: '2rem' }}>{t('form.label.world')}</Typography.Title>
								<Space>
									{!isEmpty(world_info) &&
										<ImageRender
											url={`${process.env.REACT_APP_SERVER_URL}/api/game/worlds/${world_info?._id?.$oid}/image`}
											size={{ width: 55, height: 55 }}
										/>
									}
									<Skeleton loading={world_info_loading} active paragraph={{ rows: 0 }}>
										<Typography.Title level={3} style={{ margin: '0 0.5rem' }}>{world_info?.name}</Typography.Title>
									</Skeleton>
								</Space>
							</Col>

							<Col
								xs={24} sm={24} md={24} lg={8} xl={8}
								style={ width > 990 ? { borderRight: '1px solid #d9d9d9' } : { borderBottom: '1px solid #d9d9d9', paddingBottom: '1.5rem' }}
							>
								<Typography.Title level={4} style={{ marginTop: 0, marginBottom: '2rem' }}>{t('menuItems.ingredients.ingredients')}</Typography.Title>
								<IngredientsList
									ingredients={recipe_data?.ingredients}
								/>
							</Col>

							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Typography.Title level={4} style={{ marginTop: 0, marginBottom: '2rem' }}>{t('menuItems.items')}</Typography.Title>
								<ItemsList
									items={recipe_data?.items}
								/>
							</Col>
						</Row>
				}
				
				<Flex justify='flex-end' style={{ marginTop: '3rem' }}>
					<Typography.Text type='secondary'>
						{t('recipes.details.creationDate')} {new Date(recipe_data?.recipe?.date?.$date).toLocaleString(localStorage.getItem('i18nextLng'), { hour12: true })}
					</Typography.Text>
				</Flex>
			</Card>
			
			<Steps
				messageApi={messageApi}
				recipeData={recipe_data}
			/>
		</div>
	)
}

RecipesDetails.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default RecipesDetails;
