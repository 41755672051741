import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Card, Checkbox, Col, Divider, Flex, Form, Input, InputNumber, Row, Select, Space, Tooltip, Typography } from 'antd';
import { CloseOutlined, MinusCircleOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import SelectTags from '../../utils/SelectTags';
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_steps, step_create, step_update } from '../../../redux/actions/stepsActions';
import { clear_all_items_data, get_all_items } from '../../../redux/actions/itemsActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';
import { get_all_actions } from '../../../redux/actions/actionsActions';
import { get_all_types } from '../../../redux/actions/typesActions';

// Utils
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';
import { equipment_options, step_types_options } from '../../../utils/select_options';
import { set_step_form_values } from '../../../utils/set_step_form_values';
import isEmpty from '../../../utils/isEmpty';
import { create_step_body } from '../../../utils/create_step_body';
import { get_item_type_desc } from '../../../utils/get_status_desc';
import { toSeconds } from '../../../utils/set_video_time';

const StepsForm = (props) => {
	const { messageApi, recipeData, steps, setShowAddStep, updateStep, setUpdateStep, stepToUpdate, setStepToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { step_create_loading, step_update_loading, steps_errors } = useSelector(state => state.steps);
	
	const { all_items } = useSelector(state => state.items);

	const { all_states_loading, all_states } = useSelector(state => state.states);

	const { all_actions_loading, all_actions } = useSelector(state => state.actions);

	const { all_types_loading, all_types } = useSelector(state => state.types);

	const [form] = Form.useForm();

	const [showMultimeda, setShowMultimeda] = useState(false);
	const [recipeStepsInfo, setRecipeStepsInfo] = useState({ count: 0, steps: [] });
	const [nextStep, setNextStep] = useState(0);
	const [fileList, setFileList] = useState([]);
	const [itemsList, setItemsList] = useState([]);
	const [customItemList, setCustomItemList] = useState([]);
	const [stepTypes, setStepTypes] = useState([]);
	const [equipmentOptions, setEquipmentOptions] = useState([]);
	const [ingredientMixStatesOptions, setIngredientMixStatesOptions] = useState([]);
	const [stepActionTypesOptions, setStepActionTypesOptions] = useState([]);
	const [showGoToItemInput, setShowGoToItemInput] = useState(true);

	const STEP_TYPES = step_types_options();
	const EQUIPMENTE_OPTIONS = equipment_options();

	useEffect(() => {
		setStepTypes(create_select_options_from_array(STEP_TYPES));
		setEquipmentOptions(create_select_options_from_array(EQUIPMENTE_OPTIONS));

		dispatch(get_all_actions({ skip: 0, limit: 0 }));
		dispatch(get_all_items({ skip: 0, limit: 0 }));
		dispatch(get_all_types({ skip: 0, limit: 0 }));

		return () => {
			dispatch(clear_all_items_data());
		}
	}, [dispatch]);

	const debouncedSearchItem = debounce((dispatch, value) => {
		dispatch(get_all_items({ name: value }));
	}, 500);

	const onSearchItem = (value) => {
		if (value.length > 0) {
			debouncedSearchItem(dispatch, value);
		}
		else {
			setItemsList([]);
		}
	}

	useEffect(() => {
		setItemsList(all_items.items);

		const customItems = all_items.items.map(item => {
			const itemType = get_item_type_desc(item.item_type);
			return {
				value: item?._id?.$oid,
				label: item.name + ' - ' + itemType.charAt(0).toUpperCase()
			}
		});
		setCustomItemList(customItems);
	}, [all_items]);

	useEffect(() => {
		setRecipeStepsInfo({ count: steps.length, steps });
		setNextStep(steps.length + 1);
	}, [steps]);

	useEffect(() => {
		if (!isEmpty(stepToUpdate)) {
			const step_form_values = set_step_form_values(stepToUpdate, recipeData);
			
			form.setFieldsValue(step_form_values);
			setShowMultimeda(step_form_values.recipe_type > 1);
			setShowGoToItemInput(stepToUpdate.result_goes_to);
			
			if (step_form_values.ingredient !== undefined && step_form_values.ingredient !== null) {
				setIngredientMixStatesOptions(setIngredientStatesOptions(step_form_values.ingredient.value));
			}
			
			if (step_form_values.step_action !== undefined && step_form_values.step_action !== null) {
				setStepActionTypesOptions(setActionTypesOptions(step_form_values.step_action.value));
			}	
		}
	}, [stepToUpdate]);

	const validateCustomItems = (_, value) => {
		let countF = 0;
		let countH = 0;

		// Loop through the array and count the "- F" and "- H" suffixes
		if (value) {
			value.forEach(item => {
				if (item.label.endsWith(' - ' + get_item_type_desc(1).charAt(0).toUpperCase())) {
					countF += 1;
				}
				if (item.label.endsWith(' - ' + get_item_type_desc(2).charAt(0).toUpperCase())) {
					countH += 1;
				}
			});
	
			// Validate the count of "- F" and "- H" suffixes
			if (countF > 1) {
				return Promise.reject(new Error(t('form.rules.fillableItem')));
			}
			if (countH > 1) {
				return Promise.reject(new Error(t('form.rules.holdableItem')));
			}
		}

		// If the validation is successful, return a resolved promise
		return Promise.resolve();
	};

	const setIngredientStatesOptions = (value) => {
		let filterValue = value;

		if (typeof value === 'object') {
			filterValue = value.value;
		}

		const options = all_states.states.filter(state => state.ingredient?.$oid === filterValue);
		return create_select_options(options, 'name');
	}

	const setActionTypesOptions = (value) => {
		let filterValue = value;

		if (typeof value === 'object') {
			filterValue = value.value;
		}

		const options = all_types.types.filter(type => type.action?.$oid === filterValue);
		return create_select_options(options, 'name');
	}

	const setIngredientQuantityType = (value) => {
		let filterValue = value;

		if (typeof value === 'object') {
			filterValue = value.value;
		}
		
		const ingredient = recipeData?.ingredients?.find(i => i.ingredient._id.$oid === filterValue);
		if (ingredient) {
			return ingredient.properties.unit.abbreviation;
		}

		return '';
	}

	const addAllQuantity = (index) => {
		const cookingProcess = form.getFieldValue('cooking_process');
		const cookingProcessIng = cookingProcess[index]?.ingredient;
		
		const ingredient = recipeData?.ingredients?.find(i => i.ingredient._id.$oid === cookingProcessIng);

		if (ingredient) {
			const newQuantity = ingredient.properties.quantity;

			const updatedCookingProcess = [...cookingProcess];
			updatedCookingProcess[index].quantity = newQuantity;
			form.setFieldsValue({ cooking_process: updatedCookingProcess });
		}
	}

	const onValuesChange = async (changedValues, allValues) => {
		if (allValues.step_action !== undefined && allValues.step_action !== null) {
			setStepActionTypesOptions(setActionTypesOptions(allValues.step_action));
		}
		else {
			form.setFieldsValue({ step_action_type: undefined });
			setStepActionTypesOptions([]);
		}

		if (allValues.ingredient !== undefined && allValues.ingredient !== null) {
			setIngredientMixStatesOptions(setIngredientStatesOptions(allValues.ingredient));
		}
		else {
			form.setFieldsValue({ ingredients_mix: undefined });
			setIngredientMixStatesOptions([]);
		}

		if (!allValues.result_goes_to) {
			form.setFieldsValue({ go_to_item: undefined });
			setShowGoToItemInput(false);
		}
		else {
			setShowGoToItemInput(true);
		}

		if (allValues.recipe_type) {
			if (allValues.recipe_type === 1) {
				setShowMultimeda(false);
				form.setFieldsValue({ video: undefined, video_start: undefined, video_end: undefined });
			}
			else {
				setShowMultimeda(true);
			}
		}
	}

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setUpdateStep(false);
		setStepToUpdate({});
		setShowAddStep(false);

		if (finish) {
			dispatch(get_all_steps({ recipe: recipeData?.recipe?._id?.$oid }));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!updateStep) {
					// Create
					const cooking_process = values.cooking_process?.map(({ start, end, quantity, type }) => ({
						start,
						end,
						quantity,
						action: type
					})) || [];

					const stepBody = {
						recipe: recipeData?.recipe?._id?.$oid,
						recipe_type: values.recipe_type,
						index: recipeStepsInfo.count,
						name: values.name,
						description: values.description,
						video: values.video,
						video_start: values.video_start ? toSeconds(values.video_start) : undefined,
						video_end: values.video_end ? toSeconds(values.video_end) : undefined,
						cooking_process: cooking_process,
						ingredients_mix: values.ingredients_mix,
						result_goes_to: values.result_goes_to,
						go_to_item: values.go_to_item,
						items: values.items ? values.items.map(item => item.value) : [],
						equipment: values.equipment,
						step_action: values.step_action_type
					}
					
					dispatch(step_create(stepBody, () => handleCancel(true), () => setNextStep(nextStep + 1)));
				}
				else {
					// Update
					const index = stepToUpdate.index - 1;
					const stepBody = create_step_body(values, index);
					dispatch(step_update(messageApi, stepToUpdate?._id?.$oid, stepBody, () => handleCancel(false)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Card
			bordered={false}
		>
			<Form
				form={form}
				layout='vertical'
				onValuesChange={onValuesChange}
				initialValues={{ result_goes_to_bowl: true }}
			>
				<Row align='middle' style={{ width: '100%' }}>
					<Col style={{ paddingRight: '1rem' }} xs={24} sm={22} md={22} lg={22} xl={22}>
						{steps_errors.hasOwnProperty('step_create') &&
							<Alert
								style={{ textAlign: 'start', marginBottom: '1.5rem' }}
								message={t('steps.create.errorAlert')}
								type='error'
								showIcon
							/>
						}
						<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('steps.form.mainInformation')}</Divider>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.recipe_type')}
									name='recipe_type'
									rules={[
										{
											required: true,
											message: t('form.rules.required'),
										}
									]}
								>
									<Select
										showSearch
										placeholder={t('form.placeholders.recipe_type')}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={stepTypes}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.name')}
									name='name'
									rules={[
										{
											required: true,
											message: t('form.rules.required'),
										}
									]}
								>
									<Input placeholder={t('form.placeholders.name')} />
								</Form.Item>
							</Col>
						</Row>						
						<Form.Item
							label={t('form.label.instruction')}
							name='description'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Input.TextArea placeholder={t('form.placeholders.instruction')} rows={2} />
						</Form.Item>

						<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('menuItems.ingredients.ingredients')}</Divider>
						<Form.List name={['cooking_process']}>
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => (
										<Row
											key={key}
											gutter={[16, 16]}
										>
											<Col xs={24} sm={24} md={24} lg={4} xl={4}>
												<Form.Item
													{...restField}
													label={t('form.label.ingredient')}
													name={[name, 'ingredient']}
													rules={[
														{
															required: true,
															message: t('form.rules.required'),
														}
													]}
												>
													<Select
														showSearch
														placeholder={t('form.placeholders.ingredient')}
														optionFilterProp='children'
														filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
														options={create_select_options(recipeData?.ingredients?.map(i => i.ingredient), 'name')}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={4} xl={4}>
												<Form.Item
													{...restField}
													label={t('form.label.initialState')}
													name={[name, 'start']}
													rules={[
														{
															required: true,
															message: t('form.rules.required'),
														}
													]}
												>
													<Select
														showSearch
														placeholder={`${t('form.placeholders.select')} ${t('form.label.initialState')}`}
														loading={all_states_loading}
														optionFilterProp='children'
														filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
														options={setIngredientStatesOptions(form.getFieldValue('cooking_process')[name]?.ingredient)}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={4} xl={4}>
												<Form.Item
													{...restField}
													label={t('form.label.finalState')}
													name={[name, 'end']}
													rules={[
														{
															required: true,
															message: t('form.rules.required'),
														}
													]}
												>
													<Select
														showSearch
														placeholder={`${t('form.placeholders.select')} ${t('form.label.finalState')}`}
														loading={all_states_loading}
														optionFilterProp='children'
														filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
														options={setIngredientStatesOptions(form.getFieldValue('cooking_process')[name]?.ingredient)}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={3} xl={3}>
												<Form.Item
													{...restField}
													label={
														<Flex>
															<Typography.Text style={{ marginRight: '1rem' }}>{t('form.label.quantity')}</Typography.Text>
															<Tooltip title={t('buttons.all')}>
																<Button
																	type='primary'
																	shape='circle'
																	size='small'
																	icon={<PlusOutlined />}
																	disabled={form.getFieldValue('cooking_process')[name]?.ingredient === undefined}
																	onClick={() => addAllQuantity(key)}
																/>
															</Tooltip>
														</Flex>
													}
													name={[name, 'quantity']}
													rules={[
														{
															required: true,
															message: t('form.rules.required'),
														}
													]}
												>
													<InputNumber
														step={0.1}
														style={{ width: '100%' }}
														placeholder={t('form.placeholders.quantity')}
														suffix={setIngredientQuantityType(form.getFieldValue('cooking_process')[name]?.ingredient)}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={4} xl={4}>
												<Form.Item
													{...restField}
													label={t('form.label.action')}
													name={[name, 'action']}
													rules={[
														{
															required: form.getFieldValue('recipe_type') === 1 || form.getFieldValue('recipe_type') === 2,
															message: t('form.rules.required'),
														}
													]}
												>
													<Select
														showSearch
														placeholder={t('form.placeholders.action')}
														loading={all_actions_loading }
														optionFilterProp='children'
														filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
														options={create_select_options(all_actions.actions, 'name')}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={4} xl={4}>
												<Form.Item
													{...restField}
													label={t('form.label.actionType')}
													name={[name, 'type']}
													rules={[
														{
															required: form.getFieldValue('recipe_type') === 1 || form.getFieldValue('recipe_type') === 2,
															message: t('form.rules.required'),
														}
													]}
												>
													<Select
														showSearch
														placeholder={t('form.placeholders.actionType')}
														loading={all_types_loading}
														optionFilterProp='children'
														filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
														options={setActionTypesOptions(form.getFieldValue('cooking_process')[name]?.action)}
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={1} xl={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<MinusCircleOutlined
													style={{ color: '#E11D48', fontSize: '1.2rem' }}
													onClick={() => remove(name)}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
											{t('recipes.ingredients.addIngredients')}
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
						
						<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('menuItems.items')}</Divider>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('menuItems.items')}
									name='items'
									tooltip={
										<div>
											<p style={{ margin: 0 }}>{`${get_item_type_desc(1)} - ${get_item_type_desc(1).charAt(0).toUpperCase()}`}</p>
											<p style={{ margin: 0 }}>{`${get_item_type_desc(2)} - ${get_item_type_desc(2).charAt(0).toUpperCase()}`}</p>
										</div>
									}
									rules={[
										{
											validator: validateCustomItems
										}
									]}
								>
									<Select
										showSearch
										mode='multiple'
										placeholder={t('form.placeholders.cookware')}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										// filterOption={false}
										// onSearch={value => onSearchItem(value)}
										tagRender={SelectTags}
										options={customItemList}
										labelInValue={true}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.equipment')}
									name='equipment'
								>
									<Select
										showSearch
										placeholder={t('form.placeholders.recipe_type')}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={equipmentOptions}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('steps.form.globalAction')}</Divider>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.action')}
									name='step_action'
								>
									<Select
										showSearch
										allowClear
										placeholder={t('form.placeholders.action')}
										loading={all_actions_loading}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={create_select_options(all_actions.actions, 'name')}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.actionType')}
									name='step_action_type'
								>
									<Select
										showSearch
										allowClear
										placeholder={t('form.placeholders.actionType')}
										loading={all_types_loading}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={stepActionTypesOptions}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('steps.form.finalIngredient')}</Divider>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.ingredient')}
									name='ingredient'
								>
									<Select
										showSearch
										allowClear
										placeholder={t('form.placeholders.ingredient')}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={create_select_options(recipeData?.ingredients?.map(i => i.ingredient), 'name')}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Form.Item
									label={t('form.label.state')}
									name='ingredients_mix'
								>
									<Select
										showSearch
										allowClear
										placeholder={t('form.placeholders.state')}
										loading={all_states_loading}
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
										options={ingredientMixStatesOptions}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							name='result_goes_to'
							valuePropName='checked'
						>
							<Checkbox>{t('form.label.resultGoesToBowl')}</Checkbox>
						</Form.Item>
						{showGoToItemInput && (
							<Form.Item
								label={t('menuItems.items')}
								name='go_to_item'
							>
								<Select
									showSearch
									placeholder={t('form.placeholders.cookware')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									// filterOption={false}
									// onSearch={value => onSearchItem(value)}
									options={create_select_options(itemsList, 'name')}
								/>
							</Form.Item>
						)}
						
						{showMultimeda && (
							<div>
								<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>Multimedia</Divider>
								<Row gutter={[16, 16]}>
									<Col xs={24} sm={24} md={24} lg={4} xl={4}>
										<Space direction='vertical'>
											<Typography.Text>{t('form.label.image')}</Typography.Text>
											<ImageUpload
												action={process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${stepToUpdate?._id?.$oid}/image/upload`}
												actionFunction={(options) => dispatch(form_image_upload(options))}
												fileList={fileList}
												setFileList={setFileList}
											/>
										</Space>
									</Col>
									<Col xs={24} sm={24} md={24} lg={20} xl={20}>
										<Row gutter={[16]}>
											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Form.Item
													label={t('form.label.video')}
													name='video'
												>
													<Input placeholder={t('form.placeholders.video')} />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12} xl={12}>
												<Form.Item
													label={t('form.label.startVideo')}
													name='video_start'
												>
													<Input style={{ width: '100%' }} placeholder={t('form.placeholders.videoTime')} />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12} xl={12}>
												<Form.Item
													label={t('form.label.endVideo')}
													name='video_end'
												>
													<Input style={{ width: '100%' }} placeholder={t('form.placeholders.videoTime')} />
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						)}
					</Col>
					<Col xs={24} sm={2} md={2} lg={2} xl={2}>
						<Form.Item
							style={{ marginBottom: 0 }}
						>
							<Flex align='center' justify='center'>
								<Space>
									<Tooltip title={t('buttons.cancel')}>
										<Button
											key='submit'
											type='primary'
											className='btn-cancel'
											shape='circle'
											size='large'
											icon={<CloseOutlined />}
											onClick={() => handleCancel(false)}
										/>
									</Tooltip>
									<Tooltip title={t('buttons.save')}>
										<Button
											key='submit'
											type='primary'
											className='btn-save'
											shape='circle'
											size='large'
											loading={updateStep ? step_update_loading : step_create_loading}
											icon={<SaveFilled />}
											onClick={onOk}
										/>
									</Tooltip>
								</Space>
							</Flex>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}

StepsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeData: PropTypes.object.isRequired,
	steps: PropTypes.array.isRequired,
	setShowAddStep: PropTypes.func.isRequired,
	updateStep: PropTypes.bool.isRequired,
	setUpdateStep: PropTypes.func.isRequired,
	stepToUpdate: PropTypes.object.isRequired,
	setStepToUpdate: PropTypes.func.isRequired
}

export default StepsForm;
