import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// Packages
import { ConfigProvider, Layout, Menu, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import Icon from '../utils/Icon';

// Actions
import { auth_token_remove } from '../../redux/actions/authActions';

// Version
import version from '../../version.json';

const SiderMenu = (props) => {
	const { setSiderCollapsed, setIsModalOpen } = props;

	const { t } = useTranslation();

	const location = useLocation();

	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(auth_token_remove());
	}

	// This function helps to activate menu key based on the current path.
	const getSelectedKeys = () => {
		if (location.pathname.startsWith('/recipes')) {
			return ['/recipes'];
		}
		else if (location.pathname.startsWith('/ingredients')) {
			if (location.pathname.startsWith('/ingredients/categories')) {
				return ['/ingredients/categories'];
			}
			
			return ['/ingredients'];
		}
		else if (location.pathname.startsWith('/actions')) {
			return ['/actions'];
		}
		else if (location.pathname.startsWith('/users')) {
			return ['/users'];
		}
		
		return [location.pathname];
	};

	const openSettingsModal = () => {
		setIsModalOpen(true);
	}

	const handleClearLocalStorage = (keys) => {
		keys.forEach(item => localStorage.removeItem(item));
	};

	const menuItems = [
		{
			key: '/',
			icon: <Icon icon='home' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/'}>{t('menuItems.home')}</Link>
		},
		{
			key: 'game',
			icon: <Icon icon='stadia_controller' size={20} marginEnd='0.7rem' sider />,
			label: t('menuItems.game.title'),
			children: [
				{
					key: '/game/worlds',
					label: <Link to={'/game/worlds'} onClick={() => handleClearLocalStorage(['worldsSkip', 'worldsPageSize'])}>{t('menuItems.game.worlds')}</Link>
				},
				{
					key: '/game/medals',
					label: <Link to={'/game/medals'} onClick={() => handleClearLocalStorage(['medalsSkip', 'medalsPageSize'])}>{t('menuItems.game.medals')}</Link>
				},
				{
					key: '/game/packages',
					label: <Link to={'/game/packages'} onClick={() => handleClearLocalStorage(['packagesSkip', 'packagesPageSize'])}>{t('menuItems.game.packages')}</Link>
				}
			]
		},
		{
			key: '/recipes',
			icon: <Icon icon='menu_book' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/recipes'}  onClick={() => handleClearLocalStorage(['recipesSkip', 'recipesPageSize'])}>{t('menuItems.recipes')}</Link>
		},
		{
			key: 'ingredients',
			icon: <Icon icon='grocery' size={20} marginEnd='0.7rem' sider />,
			label: t('menuItems.ingredients.title'),
			children: [
				{
					key: '/ingredients',
					label: <Link to={'/ingredients'} onClick={() => handleClearLocalStorage(['ingredientsSkip', 'ingredientsPageSize'])}>{t('menuItems.ingredients.ingredients')}</Link>
				},
				{
					key: '/ingredients/categories',
					label: <Link to={'/ingredients/categories'} onClick={() => handleClearLocalStorage(['categoriesSkip', 'categoriesPageSize'])}>{t('menuItems.ingredients.categories')}</Link>
				}
			]
		},
		{
			key: '/items',
			icon: <Icon icon='stockpot' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/items'}  onClick={() => handleClearLocalStorage(['itemsSkip', 'itemsPageSize'])}>{t('menuItems.items')}</Link>
		},
		{
			key: '/units',
			icon: <Icon icon='square_foot' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/units'}  onClick={() => handleClearLocalStorage(['unitsSkip', 'unitsPageSize'])}>{t('menuItems.units')}</Link>
		},
		{
			key: '/actions',
			icon: <Icon icon='flatware' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/actions'}  onClick={() => handleClearLocalStorage(['actionsSkip', 'actionsPageSize'])}>{t('menuItems.actions')}</Link>
		},
		{
			key: '/users',
			icon: <Icon icon='person' size={20} marginEnd='0.7rem' sider />,
			label: <Link to={'/users'}  onClick={() => handleClearLocalStorage(['usersSkip', 'usersPageSize'])}>{t('menuItems.users')}</Link>
		}
	];

	const configItems = [
		{
			key: 'settings',
			icon: <Icon icon='settings' size={20} marginEnd='0.7rem' sider />,
			label: <Link onClick={openSettingsModal}>{t('menuItems.settings')}</Link>
		},
		{
			key: 'logout',
			icon: <Icon icon='logout' size={20} marginEnd='0.7rem' sider />,
			label: <Link onClick={handleLogout}>{t('menuItems.logout')}</Link>
		}
	];

	return (
		<Layout.Sider
			style={{
				background: '#F18B71',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				bottom: 0,
			}}
			// width={230}
			breakpoint='lg'
			collapsedWidth='0'
			// onBreakpoint={(broken) => {console.log(broken);}}
			onCollapse={(collapsed, type) => { setSiderCollapsed(collapsed) }}
		>
			<div className='sider-menu'>
				<div className='logo'>
					<img src={process.env.PUBLIC_URL + '/assets/KM_firma.png'} />
				</div>
				<ConfigProvider
					theme={{
						components: {
							Menu: {
								itemBg: '#f18b71',
								itemColor: '#ffffff',
								itemSelectedBg: '#cc6956',
								itemSelectedColor: '#ffffff',
								itemHoverBg: '#cc6956',
								itemHoverColor: '#ffffff',
								subMenuItemBg: '#E57E64'
							},
							Button: {
								colorLink: '#ffffff',
								colorLinkHover: '#ffffff'
							}
						}
					}}
				>
					<Menu
						defaultSelectedKeys={['1']}
						mode='inline'
						items={menuItems}
						selectedKeys={getSelectedKeys()}
					/>
					<div style={{ marginTop: 'auto' }}>
						<Menu
							defaultSelectedKeys={['1']}
							mode='inline'
							items={configItems}
							selectedKeys={getSelectedKeys()}
						/>
						<Typography.Text style={{ color: '#ffffff', textAlign: 'center', display: 'block', margin: '1rem' }}>
							{version.version_name}
						</Typography.Text>
					</div>
				</ConfigProvider>
			</div>
		</Layout.Sider>
	)
}

SiderMenu.propTypes = {
	setSiderCollapsed: PropTypes.func.isRequired,
	setIsModalOpen: PropTypes.func.isRequired
}

export default SiderMenu;
