import React, { useEffect, useState } from 'react';

// Pakages
import { BrowserRouter as  Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider, Layout, message } from 'antd';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Components
// Main
import SiderMenu from './components/main/SiderMenu';
import PrivateRoute from './router/PrivateRoute';
import Home from './components/main/Home';
import NotFound from './components/main/NotFound';
import Authentication from './components/main/Authentication';
import Settings from './components/main/Settings';
// Game
import Worlds from './components/game/worlds/Worlds';
import Medals from './components/game/medals/Medals';
import Packages from './components/game/packages/Packages';
// Recipes
import Recipes from './components/recipes/recipes/Recipes';
import RecipesAdd from './components/recipes/recipes/RecipesAdd';
import RecipeDetails from './components/recipes/recipes/RecipeDetails';
import RecipeTutorial from './components/recipes/tutorial/RecipeTutorial';
// Ingredients
import Ingredients from './components/ingredients/main/Ingredients';
import IngredientDetails from './components/ingredients/main/IngredientDetails';
import Categories from './components/ingredients/categories/Categories';
// Items
import Items from './components/items/main/Items';
// Units
import Units from './components/units/Units';
// Actions
import Actions from './components/actions/main/Actions';
import ActionDetails from './components/actions/main/ActionDetails'
import TypeDetails from './components/actions/types/TypeDetails';
// Users
import Users from './components/users/main/Users';
import UserDetails from './components/users/main/UserDetails';
// Legal
import PrivacyPolicy from './components/legal/PrivacyPolicy';

// Actions
import { user_token_check } from './redux/actions/authActions';

user_token_check (store);

const App = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [siderCollapsed, setSiderCollapsed] = useState(false);
	const [layoutMarginLeft, setLayoutMarginLeft] = useState('12rem');
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (siderCollapsed) {
			setLayoutMarginLeft('0');
		}
		else {
			setLayoutMarginLeft('12rem');
		
		}
	}, [siderCollapsed]);

	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: 'Montserrat, sans-serif',
					colorPrimary: '#F18B71',
				},
				components: {
					Pagination: {
						itemActiveBg: '#F18B71'
					},
					Card: {
						borderRadiusLG: '23px'
					},
					Divider: {
						colorSplit: '#d9d9d9'
					},
					Slider: {
						trackBg: '#F18B71',
						trackHoverBg: '#F18B71',
						dotActiveBorderColor: '#F18B71',
						handleColor: '#F18B71',
						colorPrimaryBorderHover: '#F18B71'
					}
				}
			}}
		>
		<Provider store={store}>
			<Settings
				messageApi={messageApi}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
			<Router>
				<Layout >
					<SiderMenu
						setSiderCollapsed={setSiderCollapsed}
						setIsModalOpen={setIsModalOpen}
					/>
					<Layout
						style={{
							marginLeft: layoutMarginLeft,
						}}
					>
						{contextHolder}
						<Layout.Content className='content'>
							<Routes>
								<Route path='/auth' element={ <Authentication /> } exact />

								<Route path='/privacy-policy' element={ <PrivacyPolicy /> } exact />

								<Route element={ <PrivateRoute /> } exact>
									<Route path='/' element={<Home />} />
									<Route path='*' element={<NotFound />} />

									{/* Game */}
									<Route path='/game/worlds' element={<Worlds messageApi={messageApi} />} />
									<Route path='/game/medals' element={<Medals messageApi={messageApi} />} />
									<Route path='/game/packages' element={<Packages messageApi={messageApi} />} />

									{/* Recipes */}
									<Route path='/recipes' element={<Recipes messageApi={messageApi} />} />
									<Route path='/recipes/add' element={<RecipesAdd messageApi={messageApi}/>} />
									<Route path='/recipes/:recipe_id/details' element={<RecipeDetails messageApi={messageApi} />} />
									<Route path='/recipes/:recipe_id/tutorial' element={<RecipeTutorial />} />

									{/* Ingredients */}
									<Route path='/ingredients' element={<Ingredients messageApi={messageApi} />} />
									<Route path='/ingredients/:ingredient_id/details' element={<IngredientDetails messageApi={messageApi}/>} />
									<Route path='/ingredients/categories' element={<Categories messageApi={messageApi} />} />
									
									{/* Items */}
									<Route path='/items' element={<Items messageApi={messageApi} />} />

									{/* Units */}
									<Route path='/units' element={<Units messageApi={messageApi} />} />

									{/* Actions */}
									<Route path='/actions' element={<Actions messageApi={messageApi} />} />
									<Route path='/actions/:action_id/details' element={<ActionDetails messageApi={messageApi} />} />
									<Route path='/actions/:action_id/details/type/:type_id' element={<TypeDetails messageApi={messageApi} />} />

									{/* Users */}
									<Route path='/users' element={<Users messageApi={messageApi} />} />
									<Route path='/users/:user_id/details' element={<UserDetails messageApi={messageApi} />} />
								</Route>
							</Routes>
						</Layout.Content>
					</Layout>
				</Layout>
			</Router>
		</Provider>
		</ConfigProvider>
	);
};

export default App;
