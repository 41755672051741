import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Card, Descriptions, Popconfirm, Space, Tag, Tooltip, Typography } from 'antd';
import { EditFilled, DeleteFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { is_property_form_modal_open, property_delete } from '../../../redux/actions/propertiesActions';
import isEmpty from '../../../utils/isEmpty';
import { transform_color_format } from '../../../utils/colors_handlers';
import { get_properties_matter_desc } from '../../../utils/get_status_desc';

const PropertyInfo = (props) => {
	const { messageApi, ingredientId, propertyInfo, updateProperty, isStateProperty } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { property_delete_loading } = useSelector(state => state.properties);

	const [propertyColor, setPropertyColor] = useState('');

	useEffect(() => {
		if (!isEmpty(propertyInfo)) {
			if (propertyInfo?.color !== null) {
				const _propertyInfo = { ...propertyInfo };
				const tagColor = transform_color_format(_propertyInfo.color);
				setPropertyColor(`#${tagColor}`);
			}
		}
	}, [propertyInfo]);

	const deleteProperty = () => {
		const filters = {
			ingredient: ingredientId
		};
		dispatch(property_delete(messageApi, propertyInfo?._id?.$oid, isStateProperty, filters));
		dispatch(is_property_form_modal_open(false));
	}

	return (
		<Card
			title={
				<Typography.Text copyable={{ text: propertyInfo?._id?.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{t('ingredients.properties.state.info')}
				</Typography.Text>
			}
			extra={
				<Space>
					<Tooltip title={t('buttons.edit')}>
						<Button
							type='text'
							shape='circle'
							icon={<EditFilled style={{ color: '#9E9E9E' }} />}
							onClick={() => updateProperty(propertyInfo)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('ingredients.properties.delete.confirmTitle')}
						description={t('ingredients.properties.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteProperty()}
						okButtonProps={{
							loading: property_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='text'
							shape='circle'
							icon={<DeleteFilled style={{ color: '#9E9E9E' }} />}
						/>
					</Popconfirm>
				</Space>
			}
		>
			<Descriptions 
				column={{
					xs: 1,
					sm: 1,
					md: 2,
					lg: 2,
					xl: 2,
					xxl: 3
				}}
				items={[
					{
						label: t('form.label.name'),
						children: propertyInfo?.name
					},
					// {
					// 	label: t('form.label.description'),
					// 	children: propertyInfo?.description
					// },
					{
						label: t('form.label.color'),
						children: (
							propertyInfo?.color !== null
							?	<Tag color={propertyColor}>{propertyInfo?.color}</Tag>
							:	t('table.error.noData')
						)
					},
					// {
					// 	label: t('form.label.texture'),
					// 	children: propertyInfo?.texture
					// },
					{
						label: t('form.label.scale'),
						children: propertyInfo?.lightning
					},
					// {
					// 	label: t('form.label.quantity'),
					// 	children: propertyInfo?.quantity
					// },
					{
						label: t('form.label.matter'),
						children: get_properties_matter_desc(propertyInfo?.matter)
					}
				]}
			/>
		</Card>
	)
}

PropertyInfo.propTypes = {
	messageApi: PropTypes.object.isRequired,
	ingredientId: PropTypes.string.isRequired,
	propertyInfo: PropTypes.object.isRequired,
	updateProperty: PropTypes.func.isRequired,
	isStateProperty: PropTypes.bool.isRequired
}

export default PropertyInfo;
