import {
	get_haptic_type_desc,
	get_step_type_desc,
	get_haptic_drag_element_type_desc,
	get_haptic_alignment_desc,
	get_haptic_magnitude_desc,
	get_haptic_speed_rate_desc,
	get_haptic_axis_desc,
	get_haptic_axis_direction_desc,
	get_item_type_desc,
	get_equipment_options_desc,
	get_medal_types_desc,
	get_challenge_types_desc,
	get_recipe_status_desc,
	get_level_status_desc,
	get_properties_matter_desc,
	get_item_usage_desc,
	get_ingredient_type_desc,
} from './get_status_desc';

const STEP_TYPE_EMPTY = 1;
const STEP_TYPE_BOTH = 2;
const STEP_TYPE_CONTENT = 3;

export const step_types_options = () => [
  get_step_type_desc(STEP_TYPE_EMPTY),
	get_step_type_desc(STEP_TYPE_BOTH),
	get_step_type_desc(STEP_TYPE_CONTENT)
];

// HapticType
const HAPTIC_TYPE_DRAG = 0;
const HAPTIC_TYPE_SLICE = 1;
const HAPTIC_TYPE_TAP = 2;
const HAPTIC_TYPE_SHAKE = 3;
const HAPTIC_TYPE_DIRECTED_MOVE = 4;
const HAPTIC_TYPE_ROTATE = 5;
const HAPTIC_TYPE_FREEZE = 6;
const HAPTIC_TYPE_INTERACT_WITH_OVERLAY = 7;

export const haptic_types_options = () => [
  get_haptic_type_desc(HAPTIC_TYPE_DRAG),
  get_haptic_type_desc(HAPTIC_TYPE_SLICE),
	get_haptic_type_desc(HAPTIC_TYPE_TAP),
	get_haptic_type_desc(HAPTIC_TYPE_SHAKE),
	get_haptic_type_desc(HAPTIC_TYPE_DIRECTED_MOVE),
	get_haptic_type_desc(HAPTIC_TYPE_ROTATE),
	get_haptic_type_desc(HAPTIC_TYPE_FREEZE),
	get_haptic_type_desc(HAPTIC_TYPE_INTERACT_WITH_OVERLAY)
];

// HapticDragElementType
const HAPTIC_DRAG_ELEMENT_TYPE_INGREDIENT = 0;
const HAPTIC_DRAG_ELEMENT_TYPE_ITEM = 1;

export const haptic_drag_element_types_options = () => [
  get_haptic_drag_element_type_desc(HAPTIC_DRAG_ELEMENT_TYPE_INGREDIENT),
  get_haptic_drag_element_type_desc(HAPTIC_DRAG_ELEMENT_TYPE_ITEM)
];

// HapticAlignment
const HAPTIC_ALIGNMENT_TOP_LEFT = 0;
const HAPTIC_ALIGNMENT_TOP_CENTER = 1;
const HAPTIC_ALIGNMENT_TOP_RIGHT = 2;
const HAPTIC_ALIGNMENT_CENTER_LEFT = 3;
const HAPTIC_ALIGNMENT_CENTER = 4;
const HAPTIC_ALIGNMENT_CENTER_RIGHT = 5;
const HAPTIC_ALIGNMENT_BOTTOM_LEFT = 6;
const HAPTIC_ALIGNMENT_BOTTOM_CENTER = 7;
const HAPTIC_ALIGNMENT_BOTTOM_RIGHT = 8;

export const haptic_alignment_options = () => [
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_TOP_LEFT),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_TOP_CENTER),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_TOP_RIGHT),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_CENTER_LEFT),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_CENTER),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_CENTER_RIGHT),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_BOTTOM_LEFT),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_BOTTOM_CENTER),
  get_haptic_alignment_desc(HAPTIC_ALIGNMENT_BOTTOM_RIGHT)
];

// HapticMagnitude
const HAPTIC_MAGNITUDE_LOW = 0;
const HAPTIC_MAGNITUDE_NORMAL = 1;
const HAPTIC_MAGNITUDE_HARD = 2;

export const haptic_magnitude_options = () => [
  get_haptic_magnitude_desc(HAPTIC_MAGNITUDE_LOW),
  get_haptic_magnitude_desc(HAPTIC_MAGNITUDE_NORMAL),
  get_haptic_magnitude_desc(HAPTIC_MAGNITUDE_HARD)
];

// HapticSpeedRate
const HAPTIC_SPEED_RATE_SLOW = 0;
const HAPTIC_SPEED_RATE_MEDIUM = 1;
const HAPTIC_SPEED_RATE_FAST = 2;

export const haptic_speed_rate_options = () => [
  get_haptic_speed_rate_desc(HAPTIC_SPEED_RATE_SLOW),
  get_haptic_speed_rate_desc(HAPTIC_SPEED_RATE_MEDIUM),
  get_haptic_speed_rate_desc(HAPTIC_SPEED_RATE_FAST)
];

// HapticAxis
const AXIS_HORIZONTAL = 0;
const AXIS_VERTICAL = 1;

export const haptic_axis_options = () => [
  get_haptic_axis_desc(AXIS_HORIZONTAL),
  get_haptic_axis_desc(AXIS_VERTICAL)
];

// HapticDirection
const AXIS_DIRECTION_UP = 0;
const AXIS_DIRECTION_RIGHT = 1;
const AXIS_DIRECTION_DOWN = 2;
const AXIS_DIRECTION_LEFT = 3;

export const haptic_axis_direction_options = () => [
  get_haptic_axis_direction_desc(AXIS_DIRECTION_UP),
  get_haptic_axis_direction_desc(AXIS_DIRECTION_RIGHT),
  get_haptic_axis_direction_desc(AXIS_DIRECTION_DOWN),
  get_haptic_axis_direction_desc(AXIS_DIRECTION_LEFT)
];

// ItemType
const ITEM_TYPE_NONE = 0;
const ITEM_TYPE_FILLABLE = 1;
const ITEM_TYPE_HOLDABLE = 2;

export const item_types_options = () => [
  get_item_type_desc(ITEM_TYPE_FILLABLE),
  get_item_type_desc(ITEM_TYPE_HOLDABLE)
];

// EquipmentOptions
const EQUIPMENT_OPTIONS_STOVE = 1;
const EQUIPMENT_OPTIONS_OVEN = 2;
const EQUIPMENT_OPTIONS_MICROWAVE = 3;
const EQUIPMENT_OPTIONS_FRIDGE = 4;
const EQUIPMENT_OPTIONS_DISHWASHER = 5;
const EQUIPMENT_OPTIONS_TABLE = 6;
const EQUIPMENT_OPTIONS_PLATE = 7;

export const equipment_options = () => [
  get_equipment_options_desc(EQUIPMENT_OPTIONS_STOVE),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_OVEN),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_MICROWAVE),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_FRIDGE),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_DISHWASHER),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_TABLE),
  get_equipment_options_desc(EQUIPMENT_OPTIONS_PLATE),
];

// Medal Types
const MEDAL_TYPE_TIME = 1;
const MEDAL_TYPE_WORLD = 2;
const MEDAL_TYPE_CHALLENGE = 3;
const MEDAL_TYPE_RECIPE = 4;
const MEDAL_TYPE_SCORE = 5;

export const medal_types_options = () => [
  get_medal_types_desc(MEDAL_TYPE_TIME),
  get_medal_types_desc(MEDAL_TYPE_WORLD),
  get_medal_types_desc(MEDAL_TYPE_CHALLENGE),
  get_medal_types_desc(MEDAL_TYPE_RECIPE),
  get_medal_types_desc(MEDAL_TYPE_SCORE)
];

// Challenge Types
const CHALLENGE_TYPE_TIME = 1;
const CHALLENGE_TYPE_HAPTIC = 2;
const CHALLENGE_TYPE_VALUE = 3;
const CHALLENGE_TYPE_ORDER = 4;

export const challenge_types_options = () => [
  get_challenge_types_desc(CHALLENGE_TYPE_TIME),
  get_challenge_types_desc(CHALLENGE_TYPE_HAPTIC),
  get_challenge_types_desc(CHALLENGE_TYPE_VALUE),
  get_challenge_types_desc(CHALLENGE_TYPE_ORDER)
];

// Level Status
const RECIPE_STATUS_AVAILABLE = 1
const RECIPE_STATUS_COMING_SOON = 2
const RECIPE_STATUS_DISABLED = 3

export const recipe_status_options = () => [
  get_recipe_status_desc(RECIPE_STATUS_AVAILABLE),
  get_recipe_status_desc(RECIPE_STATUS_COMING_SOON),
  get_recipe_status_desc(RECIPE_STATUS_DISABLED)
];

// Level Status
const LEVEL_STATUS_AVAILABLE = 1;
const LEVEL_STATUS_BLOCKED = 2;
const LEVEL_STATUS_PLAYED = 3;

export const level_status_options = () => [
  get_level_status_desc(LEVEL_STATUS_AVAILABLE),
  get_level_status_desc(LEVEL_STATUS_BLOCKED),
  get_level_status_desc(LEVEL_STATUS_PLAYED)
];

// Properties Matter
const PROPERTIES_MATTER_SOLID = 1
const PROPERTIES_MATTER_LIQUID = 2
const PROPERTIES_MATTER_GAS = 3
const PROPERTIES_MATTER_OTHER = 4

export const properties_matter_options = () => [
  get_properties_matter_desc(PROPERTIES_MATTER_SOLID),
  get_properties_matter_desc(PROPERTIES_MATTER_LIQUID),
  get_properties_matter_desc(PROPERTIES_MATTER_GAS),
  get_properties_matter_desc(PROPERTIES_MATTER_OTHER)
];

// Item Usage
const ITEM_USAGE_TOP = 0;
const ITEM_USAGE_GLASS = 1;

export const item_usage_options = () => [
  get_item_usage_desc(ITEM_USAGE_TOP),
  get_item_usage_desc(ITEM_USAGE_GLASS)
];

// Item Usage
const INGREDIENT_TYPE_NORMAL = 0;
const INGREDIENT_TYPE_COMPOUND = 1;

export const ingredient_types_options = () => [
  get_ingredient_type_desc(INGREDIENT_TYPE_NORMAL),
  get_ingredient_type_desc(INGREDIENT_TYPE_COMPOUND)
];
