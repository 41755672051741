import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_STEPS_DATA,
	CLEAR_ALL_STEPS_ERRORS,
	CLEAR_STEPS_SINGLE_ERROR,
	STEP_CREATE_LOADING,
	STEP_CREATE_ERROR,
	ALL_STEPS_LOADING,
	ALL_STEPS_GET,
	ALL_STEPS_ERROR,
	STEP_INFO_LOADING,
	STEP_INFO_GET,
	STEP_INFO_ERROR,
	STEP_UPDATE_LOADING,
	STEP_UPDATE_ERROR,
	ALL_STEPS_UPDATE_LOADING,
	ALL_STEPS_UPDATE_ERROR,
	STEP_DELETE_LOADING,
	STEP_GAME_MODAL_OPEN
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_steps_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_STEPS_DATA
	});
}

export const clear_steps_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_STEPS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_STEPS_ERRORS
		});
	}
}

export const step_create = (stepBody, handleCancel, setNextStep) => dispatch => {
	dispatch({
		type: STEP_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/steps/create';

	Axios.post(url, stepBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: STEP_CREATE_LOADING,
			payload: false
		});

		dispatch(clear_steps_errors('step_create'));
		handleCancel();
		setNextStep();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: STEP_CREATE_ERROR,
			payload: { type: 'step_create', msg: err.message }
		});

		dispatch({
			type: STEP_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_steps = (filters) => dispatch => {
	dispatch({
		type: ALL_STEPS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/steps?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		const newStepsArray = add_key_prop(res.data.steps).map((step) => {
			step.index++;
			return step;
		}).sort((a, b) => a.index - b.index);
		res.data.steps = newStepsArray;

		dispatch({
			type: ALL_STEPS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_STEPS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_STEPS_ERROR,
			payload: { type: 'get_all_steps', msg: err.message }
		});

		dispatch({
			type: ALL_STEPS_LOADING,
			payload: false
		});
	})
}

export const get_step_info = (stepId) => dispatch => {
	dispatch({
		type: STEP_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${stepId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: STEP_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: STEP_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: STEP_INFO_ERROR,
			payload: { type: 'get_step_info', msg: err.message }
		});

		dispatch({
			type: STEP_INFO_LOADING,
			payload: false
		});
	})
}

export const step_update = (messageApi, stepId, stepBody, handleCancel) => dispatch => {
	dispatch({
		type: STEP_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${stepId}/update`;

	Axios.put(url, stepBody)
	.then((res) => {
		// messageApi.success(i18next.t('errorForm.edit.msg.success'), 4);
		messageApi.success('Paso editado correctamente', 4);

		dispatch({
			type: STEP_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: STEP_UPDATE_ERROR,
				payload: { type: 'step_update', msg: err.message }
			});
		}

		dispatch({
			type: STEP_UPDATE_LOADING,
			payload: false
		});
	})
}

export const all_steps_update = (messageApi, stepsArray, filters) => dispatch => {
	dispatch({
		type: ALL_STEPS_UPDATE_LOADING,
		payload: true
	});

	const updateSteps = stepsArray.map((step, idx) => {
		let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${step?._id?.$oid}/update`;
		
		const cooking_process = step.cooking_process.length > 0
			? step.cooking_process.map(element => {
					return {
						start: element.start.$oid,
						end:element.end.$oid,
						quantity: element.quantity,
						action: element.action.$oid
					};
				})
			: [];

		const body = {
			recipe_type: step.recipe_type,
			index: idx,
			name: step.name,
			description: step.description,
			video: step.video,
			video_start: step.video_start,
			video_end: step.video_end,
			cooking_process: cooking_process,
			ingredients_mix: step.ingredients_mix?.$oid,
			result_goes_to: step.result_goes_to,
			go_to_item: step.go_to_item?.$oid,
			items: step.items?.map(item => item?.$oid),
			equipment: step.equipment,
			step_action: step.step_action?.$oid
		};

		return Axios.put(url, body);
	});

	Promise.all(updateSteps)
	.then((res) => {
		// console.log(res);
		// messageApi.success(i18next.t('errorForm.edit.msg.success'), 4);
		messageApi.success('Pasos editados correctamente', 4);

		dispatch({
			type: ALL_STEPS_UPDATE_LOADING,
			payload: false
		});

		dispatch(get_all_steps(filters));
	}).catch((err) => {
		// console.log(err);
		// messageApi.error(i18next.t('errorForm.edit.msg.error'), 4);
		if (err.response.status !== 400) {
			messageApi.error('Error al editar los pasos, intenta de nuevo mas tarde', 4);
		}

		dispatch(get_all_steps(filters));

		dispatch({
			type: ALL_STEPS_UPDATE_LOADING,
			payload: false
		});
	});
}

export const step_delete = (messageApi, stepId, filters, localSteps) => dispatch => {
	dispatch({
		type: STEP_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${stepId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('steps.delete.successAlert'), 4);

		dispatch({
			type: STEP_DELETE_LOADING,
			payload: false
		});

		// dispatch(get_all_steps(filters));
		const newStepsArray = localSteps.filter(step => step._id.$oid !== stepId);
		dispatch(all_steps_update(messageApi, newStepsArray, filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('steps.delete.errorAlert'), 4);

		dispatch({
			type: STEP_DELETE_LOADING,
			payload: false
		});
	})
}

export const set_step_info = (stepInfo) => dispatch => {
	dispatch({
		type: STEP_INFO_GET,
		payload: stepInfo
	});
}

export const is_step_game_modal_open = (open) => dispatch => {
	dispatch({
		type: STEP_GAME_MODAL_OPEN,
		payload: open
	});
}
