import React, { useState } from 'react';

// Packages
import { Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ImageUpload = (props) => {
	const { action, actionFunction, fileList, setFileList } = props;

	const { t } = useTranslation();

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	
	const handleCancel = () => {
		setPreviewOpen(false);
	}

	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
	}

	const handleChange = ({ file, fileList: newFileList }) => {
		if (file.status === 'error') {
			setFileList([
				{
					status: 'error',
					uid: file.uid,
					name: file.name
				}
			]);
		}
		else {
			setFileList(newFileList);
		}
	}
	
	const uploadButton = (
		<button
			style={{ border: 0, background: 'none' }}
			type='button'
		>
			<UploadOutlined />
			<div style={{ marginTop: 8 }}>
				{t('buttons.upload')}
			</div>
		</button>
	);

	return (
		<div id='recipe-img-upload'>
			<Upload
				action={action}
				customRequest={(options) => actionFunction(options)}
				listType='picture-card'
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				showUploadList={{
					showPreviewIcon: true,
					showRemoveIcon: true,
					showDownloadIcon: false
				}}
			>
				{fileList.length > 0 ? null : uploadButton}
			</Upload>
			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img
					alt='example'
					style={{
						width: '100%',
					}}
					src={previewImage}
				/>
			</Modal>
		</div>
	);
};

ImageUpload.propTypes = {
	action: PropTypes.string.isRequired,
	actionFunction: PropTypes.func.isRequired,
	fileList: PropTypes.array.isRequired,
	setFileList: PropTypes.func.isRequired
}

export default ImageUpload;
