import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Flex, Row, Typography  } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RecipesTable from './RecipesTable';

// Actions
import { get_all_recipes } from '../../../redux/actions/recipesActions';

const Recipes = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [pageSize, setPageSize] = useState(10);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		const savedSkip = parseInt(localStorage.getItem('recipesSkip')) || 0;
		setSkip(savedSkip);

		const savedPageSize = parseInt(localStorage.getItem('recipesPageSize')) || 10;
		setPageSize(savedPageSize);

		const filters = { skip: savedSkip, limit: savedPageSize };
		dispatch(get_all_recipes(filters));
	}, []);

	const handleAdd = () => {
		navigate('/recipes/add');
	}

	return (
		<div>
			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Typography.Title>{t('menuItems.recipes')}</Typography.Title>
				</Col>
				<Col xs={24} sm={6} md={6} lg={6} xl={6}>
					<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
						<Button
							type='primary'
							size='large'
							onClick={handleAdd}
						>
							{t('buttons.create')}
						</Button>
					</Flex>
				</Col>
			</Row>

			<RecipesTable
				messageApi={messageApi}
				pageSize={pageSize}
				setPageSize={setPageSize}
				skip={skip}
				setSkip={setSkip}
			/>
		</div>
	)
}

Recipes.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Recipes;
