import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Button, Col, Row, Spin } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import PropertyInfo from './PropertyInfo';

// Actions
import { clear_all_properties_data, get_all_properties, is_property_form_modal_open } from '../../../redux/actions/propertiesActions';

const Properties = (props) => {
	const { messageApi, setUpdateProperty, setPropertyToUpdate } = props;

	const { ingredient_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_properties_loading, all_properties } = useSelector(state => state.properties);

	useEffect(() => {
		dispatch(get_all_properties({ ingredient: ingredient_id, scope: 2 }));

		return () => {
			dispatch(clear_all_properties_data());
		}
	}, [dispatch]);

	const addIngredientProperty = () => {
		dispatch(is_property_form_modal_open(true));
		setUpdateProperty(false);
		setPropertyToUpdate({
			ingredient: { $oid: ingredient_id },
			state: null,
			scope: 2
		});
	}

	const updateIngredientProperty = (propertyInfo) => {
		dispatch(is_property_form_modal_open(true));
		setUpdateProperty(true);
		setPropertyToUpdate(propertyInfo);
	}

	return (
		<div>
			{all_properties_loading
				?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
						<Spin size='large' />
					</div>
				:	all_properties.count !== 0
					? <PropertyInfo
							messageApi={messageApi}
							ingredientId={ingredient_id}
							propertyInfo={all_properties.properties[0]}
							updateProperty={updateIngredientProperty}
							isStateProperty={false}
						/>
					:	<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={6}>
							<Button
								className='btn-add-ingredient-state'
								onClick={addIngredientProperty}
							>
								<PlusCircleFilled style={{ fontSize: '40px' }} />
								{t('ingredients.properties.create.modalTitle')}
							</Button>
						</Col>
					</Row>
			}
		</div>
	)
}

Properties.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setUpdateProperty: PropTypes.func.isRequired,
	setPropertyToUpdate: PropTypes.func.isRequired
}

export default Properties;
