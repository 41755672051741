import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Badge, Button, Col, Row, Spin, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import StateInfo from './StateInfo';

// Actions
import { get_all_states } from '../../../redux/actions/statesActions';
import { is_property_form_modal_open } from '../../../redux/actions/propertiesActions';

const States = (props) => {
	const { messageApi, setIsModalStateOpen, setUpdateState, setStateToUpdate, setUpdateProperty, setPropertyToUpdate } = props;

	const { ingredient_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_states_loading, all_states } = useSelector(state => state.states);

	useEffect(() => {
		const filters = { ingredient: ingredient_id };
		dispatch(get_all_states(filters));
	}, []);

	const handleAdd = () => {
		setIsModalStateOpen(true);
		setUpdateState(false);
		setStateToUpdate({});
	}

	const addStateProperty = (state) => {
		dispatch(is_property_form_modal_open(true));
		setUpdateProperty(false);
		setPropertyToUpdate({
			ingredient: { $oid: ingredient_id },
			state: state?._id,
			scope: 3
		});
	}

	const updateStateProperty = (propertyInfo) => {
		dispatch(is_property_form_modal_open(true));
		setUpdateProperty(true);
		setPropertyToUpdate(propertyInfo);
	}

	return (
		<div style={{ marginBottom: '3rem' }}>
			{all_states_loading
				?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
						<Spin size='large' />
					</div>
				:	<div>
						<Badge style={{ marginBottom: '1rem' }} color='red' text={<Typography.Text type='secondary'>{t('ingredients.states.hasProperties')}</Typography.Text>} />
						<Row gutter={[16, 16]}>
							{all_states.states.map((state, index) => (
								<Col key={state?._id?.$oid} xs={24} sm={24} md={12} lg={12} xl={6}>
									<StateInfo
										messageApi={messageApi}
										ingredientId={ingredient_id}
										state={state}
										setIsModalStateOpen={setIsModalStateOpen}
										setUpdateState={setUpdateState}
										setStateToUpdate={setStateToUpdate}
										addStateProperty={addStateProperty}
										updateStateProperty={(propertyInfo) => updateStateProperty(propertyInfo)}
									/>
								</Col>
							))}
							<Col xs={24} sm={24} md={12} lg={12} xl={6}>
								<Button
									className='btn-add-ingredient-state'
									onClick={handleAdd}
								>
									<PlusCircleFilled style={{ fontSize: '40px' }} />
									{t('ingredients.states.create.modalTitle')}
								</Button>
							</Col>
						</Row>
					</div>
			}
		</div>
	)
}

States.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalStateOpen: PropTypes.func.isRequired,
	setUpdateState: PropTypes.func.isRequired,
	setStateToUpdate: PropTypes.func.isRequired,
	setUpdateProperty: PropTypes.func.isRequired,
	setPropertyToUpdate: PropTypes.func.isRequired
}

export default States;
