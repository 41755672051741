import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Flex, Form, InputNumber, Row, Select, Space, Tooltip } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_recipe_ingredients, recipe_ingredient_create, recipe_ingredient_update } from '../../../redux/actions/recipesActions';
import { get_all_ingredients } from '../../../redux/actions/ingredientsActions';
import { get_all_units } from '../../../redux/actions/unitsActions';

// Utils
import { create_select_options } from '../../../utils/create_select_options';
import isEmpty from '../../../utils/isEmpty';

const RecipesIngredientsForm = (props) => {
	const { messageApi, recipeId, setShowForm, update, setUpdate, recipeIngredientToUpdate, setRecipeIngredientToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { recipe_ingredient_create_loading } = useSelector(state => state.recipes);

	const { all_ingredients } = useSelector(state => state.ingredients);

	const { all_units } = useSelector(state => state.units);

	const [form] = Form.useForm();

	useEffect(() => {
		dispatch(get_all_units({ skip: 0, limit: 0 }));
	}, []);

	useEffect(() => {
		if (!isEmpty(recipeIngredientToUpdate)) {
			form.setFieldsValue({
				ingredient: { label: recipeIngredientToUpdate.ingredient.name, value: recipeIngredientToUpdate.ingredient._id.$oid },
				quantity: recipeIngredientToUpdate.properties.quantity,
				unit: { label: recipeIngredientToUpdate.properties.unit.name, value: recipeIngredientToUpdate.properties.unit._id.$oid },
				price: recipeIngredientToUpdate.price
			});
		}
	}, [recipeIngredientToUpdate]);	

	useEffect(() => {
		setIngredientsList(all_ingredients.ingredients);
	}, [all_ingredients]);

	const [ingredientsList, setIngredientsList] = useState([]);

	const debouncedSearchIngredient = debounce((dispatch, value) => {
		dispatch(get_all_ingredients({ name: value }));
	}, 500);

	const onSearchIngredient = (value) => {
		if (value.length > 0) {
			debouncedSearchIngredient(dispatch, value);
		}
		else {
			setIngredientsList([]);
		}
	}	

	const handleCancel = (finish) => {
		setShowForm(false);
		setUpdate(false);
		form.resetFields();
		setRecipeIngredientToUpdate({});

		if (finish) {
			dispatch(get_all_recipe_ingredients(recipeId));
		}
	}

	const onFinish = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create new recipe ingredient
					dispatch(recipe_ingredient_create(messageApi, recipeId, values, () => handleCancel(true)));
				}
				else {
					// Update recipe ingredient
					const recipeIngredientBody = {
						ingredient: recipeIngredientToUpdate._id.$oid,
						quantity: values.quantity,
						unit: values.unit !== null && typeof values.unit === 'object'
							? values.unit.value 
							: values.unit,
						price: values.price
					}
					dispatch(recipe_ingredient_update(messageApi, recipeId, recipeIngredientBody, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<div>
			<Form
				form={form}
				layout='vertical'
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} align='middle'>
					<Col xs={24} sm={24} md={24} lg={5} xl={5}>
						<Form.Item
							label={t('form.label.quantity')}
							name='quantity'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<InputNumber style={{ width: '100%' }} placeholder={t('form.placeholders.quantity')} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={5} xl={5}>
						<Form.Item
							label={t('form.label.unit')}
							name='unit'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('form.placeholders.unit')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(all_units.units, 'name')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={5} xl={5}>
						<Form.Item
							label={t('form.label.ingredient')}
							name='ingredient'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								disabled={update}
								placeholder={t('form.placeholders.ingredient')}
								optionFilterProp='children'
								filterOption={false}
								onSearch={value => onSearchIngredient(value)}
								options={create_select_options(ingredientsList, 'name')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={5} xl={5}>
						<Form.Item
							label={t('form.label.price')}
							name='price'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<InputNumber style={{ width: '100%' }} placeholder={t('form.placeholders.price')} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={4} xl={4}>
						<Form.Item>
							<Flex align='center' justify='center'>
								<Space>
									<Tooltip title={t('buttons.cancel')}>
										<Button
											type='primary'
											className='btn-cancel'
											shape='circle'
											icon={<CloseOutlined />}
											onClick={() => handleCancel(false)}
										/>
									</Tooltip>
									<Tooltip title={t('buttons.save')}>
										<Button
											htmlType='submit'
											type='primary'
											className='btn-save'
											shape='circle'
											loading={recipe_ingredient_create_loading}
											icon={<SaveOutlined />}
										/>
									</Tooltip>
								</Space>
							</Flex>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

RecipesIngredientsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeId: PropTypes.string.isRequired,
	setShowForm: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	setUpdate: PropTypes.func.isRequired,
	recipeIngredientToUpdate: PropTypes.object.isRequired,
	setRecipeIngredientToUpdate: PropTypes.func.isRequired
}

export default RecipesIngredientsForm;
