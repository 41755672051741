import {
	CLEAR_ALL_USERS_DATA,
	CLEAR_ALL_USERS_ERRORS,
	CLEAR_USERS_SINGLE_ERROR,
	USER_CREATE_LOADING,
	USER_CREATE_ERROR,
	ALL_USERS_LOADING,
	ALL_USERS_GET,
	ALL_USERS_ERROR,
	USER_INFO_LOADING,
	USER_INFO_GET,
	USER_INFO_ERROR,
	USER_UPDATE_LOADING,
	USER_UPDATE_ERROR,
	USER_PASSWORD_UPDATE_LOADING,
	USER_PASSWORD_UPDATE_ERROR,
	USER_DELETE_LOADING,
	USER_PURCHASES_LOADING,
	USER_PURCHASES_GET,
	USER_PURCHASES_ERROR
} from '../types';

const initialState = {
	user_create_loading: false,
	all_users_loading: false,
	all_users: { count: 0, users: [] },
	user_info: {},
	user_update_loading: false,
	user_password_update_loading: false,
	user_delete_loading: false,
	user_purchases_loading: false,
	all_users_purchases: [], // FIXME: Check if is object or array
	users_errors: {}
}

export default function usersReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_USERS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_USERS_ERRORS:
			return {
				...state,
				users_errors: {}
			}
		case CLEAR_USERS_SINGLE_ERROR:
			return {
				...state,
				users_errors: Object.keys(state.users_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.users_errors[key];
					}
					return acc;
				}, {})
			}
		case USER_CREATE_LOADING:
			return {
				...state,
				user_create_loading: action.payload
			}
		case ALL_USERS_LOADING:
			return {
				...state,
				all_users_loading: action.payload
			}
		case ALL_USERS_GET:
			return {
				...state,
				all_users: {
					count: action.payload.count,
					users: action.payload.users
				}
			}
		case USER_INFO_GET:
			return {
				...state,
				user_info: action.payload
			}
		case USER_UPDATE_LOADING:
			return {
				...state,
				user_update_loading: action.payload
			}
		case USER_PASSWORD_UPDATE_LOADING:
			return {
				...state,
				user_password_update_loading: action.payload
			}
		case USER_DELETE_LOADING:
			return {
				...state,
				user_delete_loading: action.payload
			}
		case USER_PURCHASES_LOADING:
			return {
				...state,
				user_purchases_loading: action.payload
			}
		case USER_PURCHASES_GET:
			return {
				...state,
				all_users_purchases: action.payload
			}
		case USER_CREATE_ERROR:
		case ALL_USERS_ERROR:
		case USER_INFO_ERROR:
		case USER_UPDATE_ERROR:
		case USER_PASSWORD_UPDATE_ERROR:
		case USER_PURCHASES_ERROR:
			return {
				...state,
				users_errors: {
					...state.users_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
