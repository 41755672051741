import Axios from 'axios';

import {
	ALL_DIFFICULTIES_LOADING,
	ALL_DIFFICULTIES_GET,
	ALL_DIFFICULTIES_ERROR
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const get_all_difficulties = (filters) => dispatch => {
	dispatch({
		type: ALL_DIFFICULTIES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/difficulties?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newDifficultiesArray = add_key_prop(res.data.difficulties);
		res.data.difficulties = newDifficultiesArray;

		dispatch({
			type: ALL_DIFFICULTIES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_DIFFICULTIES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_DIFFICULTIES_ERROR,
			payload: { type: 'get_all_difficulties', msg: err.message }
		});

		dispatch({
			type: ALL_DIFFICULTIES_LOADING,
			payload: false
		});
	})
}
