import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_HAPTICS_DATA,
	CLEAR_ALL_HAPTICS_ERRORS,
	CLEAR_HAPTICS_SINGLE_ERROR,
	HAPTIC_CREATE_LOADING,
	HAPTIC_CREATE_ERROR,
	ALL_HAPTICS_LOADING,
	ALL_HAPTICS_GET,
	ALL_HAPTICS_ERROR,
	HAPTIC_INFO_LOADING,
	HAPTIC_INFO_GET,
	HAPTIC_INFO_ERROR,
	HAPTIC_UPDATE_LOADING,
	HAPTIC_UPDATE_ERROR,
	HAPTIC_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_haptics_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_HAPTICS_DATA
	});
}

export const clear_haptics_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_HAPTICS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_HAPTICS_ERRORS
		});
	}
}

export const haptic_create = (hapticBody, handleCancel) => dispatch => {
	dispatch({
		type: HAPTIC_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/haptics/create';

	Axios.post(url, hapticBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: HAPTIC_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HAPTIC_CREATE_ERROR,
			payload: { type: 'haptic_create', msg: err.message }
		});

		dispatch({
			type: HAPTIC_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_haptics = (filters) => dispatch => {
	dispatch({
		type: ALL_HAPTICS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/haptics/multiple?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newWorldsArray = add_key_prop(res.data.haptics);
		res.data.haptics = newWorldsArray;

		dispatch({
			type: ALL_HAPTICS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_HAPTICS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_HAPTICS_ERROR,
			payload: { type: 'get_all_haptics', msg: err.message }
		});

		dispatch({
			type: ALL_HAPTICS_LOADING,
			payload: false
		});
	})
}

export const get_haptic_info = (hapticId) => dispatch => {
	dispatch({
		type: HAPTIC_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/haptics/${hapticId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: HAPTIC_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: HAPTIC_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HAPTIC_INFO_ERROR,
			payload: { type: 'get_haptic_info', msg: err.message }
		});

		dispatch({
			type: HAPTIC_INFO_LOADING,
			payload: false
		});
	})
}

export const haptic_update = (messageApi, hapticId, hapticBody, handleCancel) => dispatch => {
	dispatch({
		type: HAPTIC_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/haptics/${hapticId}/update`;

	Axios.put(url, hapticBody)
	.then((res) => {
		messageApi.success(i18next.t('actions.haptics.edit.successAlert'), 4);

		dispatch({
			type: HAPTIC_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: HAPTIC_UPDATE_ERROR,
				payload: { type: 'haptic_update', msg: err.message }
			});
		}

		dispatch({
			type: HAPTIC_UPDATE_LOADING,
			payload: false
		});
	})
}

export const haptic_delete = (messageApi, hapticId, filters) => dispatch => {
	dispatch({
		type: HAPTIC_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/haptics/${hapticId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('actions.haptics.delete.successAlert'), 4);

		dispatch({
			type: HAPTIC_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_haptics(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('actions.haptics.delete.errorAlert'), 4);

		dispatch({
			type: HAPTIC_DELETE_LOADING,
			payload: false
		});
	})
}
