import {
	CLEAR_ALL_STATES_DATA,
	CLEAR_ALL_STATES_ERRORS,
	CLEAR_STATES_ERROR,
	STATE_CREATE_LOADING,
	SET_STATE_ID,
	STATE_CREATE_ERROR,
	ALL_STATES_LOADING,
	ALL_STATES_GET,
	ALL_STATES_ERROR,
	STATE_INFO_LOADING,
	STATE_INFO_GET,
	STATE_INFO_ERROR,
	STATE_UPDATE_LOADING,
	STATE_UPDATE_ERROR,
	STATE_DELETE_LOADING
} from '../types';

const initialState = {
	state_create_loading: false,
	state_id: null,
	all_states_loading: false,
	all_states: { count: 0, states: [] },
	state_info_loading: false,
	state_info: {},
	state_update_loading: false,
	state_delete_loading: false,
	states_errors: {}
}

export default function statesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_STATES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_STATES_ERRORS:
			return {
				...state,
				states_errors: {}
			}
		case CLEAR_STATES_ERROR:
			return {
				...state,
				states_errors: Object.keys(state.states_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.states_errors[key];
					}
					return acc;
				}, {})
			}
		case STATE_CREATE_LOADING:
			return {
				...state,
				state_create_loading: action.payload
			}
		case SET_STATE_ID:
			return {
				...state,
				state_id: action.payload
			}
		case ALL_STATES_LOADING:
			return {
				...state,
				all_states_loading: action.payload
			}
		case ALL_STATES_GET:
			return {
				...state,
				all_states: {
					count: action.payload.count,
					states: action.payload.states
				}
			}
		case STATE_INFO_LOADING:
			return {
				...state,
				state_info_loading: action.payload
			}
		case STATE_INFO_GET:
			return {
				...state,
				state_info: action.payload
			}
		case STATE_UPDATE_LOADING:
			return {
				...state,
				state_update_loading: action.payload
			}
		case STATE_DELETE_LOADING:
			return {
				...state,
				state_delete_loading: action.payload
			}
		case STATE_CREATE_ERROR:
		case ALL_STATES_ERROR:
		case STATE_INFO_ERROR:
		case STATE_UPDATE_ERROR:
			return {
				...state,
				states_errors: {
					...state.states_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
