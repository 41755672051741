import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Col, ColorPicker, Form, Input, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { recipe_create } from '../../../redux/actions/recipesActions';
import { get_all_worlds } from '../../../redux/actions/worldsActions';

// Utils
import { format_recipe_name } from '../../../utils/format_recipe_name';
import { create_select_options } from '../../../utils/create_select_options';
import { format_hex_color_with_alpha } from '../../../utils/colors_handlers';

const RecipesAddForm = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { recipe_create_loading, recipe_id, recipes_errors } = useSelector(state => state.recipes);

	const { all_worlds } = useSelector(state => state.worlds);

	useEffect(() => {
		dispatch(get_all_worlds({}));
	}, []);
	
	const [form] = Form.useForm();

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.color) {
			const hexString = typeof changedValues.color === 'string' ? changedValues.color : changedValues.color?.toHex();
			form.setFieldsValue({ color: hexString });
		}
	}

	const onFinish = () => {
		form
			.validateFields()
			.then((values) => {
				values.name = format_recipe_name(values.name);
				const hexString = typeof values.color === 'string' ? values.color : values.color?.toHex();
				values.color = format_hex_color_with_alpha(hexString);

				const recipeBody = {
					name: values.name,
					description: values.description,
					color: values.color,
					video: values.video,
					world: values.world
				}
				
				dispatch(recipe_create(messageApi, recipeBody));
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<div>
			<Typography.Title level={4} style={{ textAlign: 'center', marginTop: 0 }}>{t('recipes.create.recipeDataTitle')}</Typography.Title>
			
			<Form
				form={form}
				layout='vertical'
				onValuesChange={onValuesChange}
				onFinish={onFinish}
			>
				{recipes_errors.hasOwnProperty('recipe_create') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={recipes_errors.recipe_create.msg}
						type='error'
						showIcon
					/>
				}
				<Row gutter={24}>
					<Col xs={24} sm={24} md={24} lg={7} xl={7}>
						<Form.Item
							label={t('form.label.name')}
							name='name'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Input placeholder={t('form.placeholders.name')} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={17} xl={17}>
						<Form.Item
							label={t('form.label.description')}
							name='description'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Input placeholder={t('form.placeholders.description')} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col xs={24} sm={24} md={24} lg={2} xl={2}>
						<Form.Item
							label={t('form.label.color')}
							name='color'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<ColorPicker format='hex' />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={5} xl={5}>
						<Form.Item
							label={t('form.label.world')}
							name='world'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('form.placeholders.world')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(all_worlds.worlds, 'name')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={17} xl={17}>
						<Form.Item
							label={t('form.label.video')}
							name='video'
							rules={[
								{
									required: true,
									message: t('form.rules.required')
								},
								// {
								// 	type: 'url',
								// 	message: t('form.rules.url')
								// }
							]}
						>
							<Input placeholder={t('form.placeholders.video')} />
						</Form.Item>
					</Col>
				</Row>
				{recipe_id === null &&
					<Form.Item
						style={{ marginBottom: 0 }}
					>
						<Button
							type='primary'
							htmlType='submit'
							loading={recipe_create_loading}
							block
						>
							{t('buttons.continue')}
						</Button>
					</Form.Item>
				}
			</Form>
		</div>
	)
}

RecipesAddForm.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default RecipesAddForm;
