import React from 'react';

// Packages
import { Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const SelectTags = (props) => {
	const { label, closable, onClose } = props;

	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Tag
			style={{
				marginInlineEnd: 4,
				color: 'black'
			}}
			color='#FFB4A2'
			closeIcon={<CloseOutlined style={{ color: 'black', marginLeft: '10px' }} />}
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
		>
			{label}
		</Tag>
	);
};

export default SelectTags;
