import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Button, Col, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Buffer } from 'buffer';

// Actions
import { get_recipe_data } from '../../../redux/actions/recipesActions';

const RecipeTutorial = () => {
	const { recipe_id } = useParams();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { recipe_data } = useSelector(state => state.recipes);

	const [loadingRecipePDF, setLoadingRecipePDF] = useState(true);
	const [recipePDFSrc, setRecipePDFSrc] = useState(null);

	useEffect(() => {
		dispatch(get_recipe_data(recipe_id));
	}, []);

	useEffect(() => {
		let isMounted = true;
		setLoadingRecipePDF(true);

		Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/recipes/${recipe_id}/tutorial`, {
			responseType: 'arraybuffer',
		})
		.then((response) => {
			if (isMounted) {
				const data = `data:${response.headers['content-type']};base64, ${Buffer.from(response.data, 'binary').toString('base64')}#zoom=73`;
				setLoadingRecipePDF(false);
				setRecipePDFSrc(data);
			}
		})
		.catch(() => {
			if (isMounted) {
				setLoadingRecipePDF(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<div style={{ height: '85vh' }}>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate(`/recipes/${recipe_data?.recipe?._id?.$oid}/details`)}
						/>
						<Typography.Title>{recipe_data?.recipe?.name}</Typography.Title>
					</Space>
				</Col>
			</Row>

			<iframe src={recipePDFSrc} width='100%' height='100%' />
		</div>
	)
}

export default RecipeTutorial;
