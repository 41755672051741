import {
	CLEAR_ALL_CATEGORIES_DATA,
	CLEAR_ALL_CATEGORIES_ERRORS,
	CLEAR_CATEGORIES_SINGLE_ERROR,
	CATEGORY_CREATE_LOADING,
	SET_CATEGORY_ID,
	CATEGORY_CREATE_ERROR,
	ALL_CATEGORIES_LOADING,
	ALL_CATEGORIES_GET,
	ALL_CATEGORIES_ERROR,
	CATEGORY_INFO_LOADING,
	CATEGORY_INFO_GET,
	CATEGORY_INFO_ERROR,
	CATEGORY_UPDATE_LOADING,
	CATEGORY_UPDATE_ERROR,
	CATEGORY_DELETE_LOADING
} from '../types';

const initialState = {
	category_create_loading: false,
	category_id: null,
	all_categories_loading: false,
	all_categories: { count: 0, categories: [] },
	category_info_loading: false,
	category_info: {},
	category_update_loading: false,
	category_delete_loading: false,
	categories_errors: {}
}

export default function categoriesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_CATEGORIES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_CATEGORIES_ERRORS:
			return {
				...state,
				categories_errors: {}
			}
		case CLEAR_CATEGORIES_SINGLE_ERROR:
			return {
				...state,
				categories_errors: Object.keys(state.categories_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.categories_errors[key];
					}
					return acc;
				}, {})
			}
		case CATEGORY_CREATE_LOADING:
			return {
				...state,
				category_create_loading: action.payload
			}
		case SET_CATEGORY_ID:
			return {
				...state,
				category_id: action.payload
			}
		case ALL_CATEGORIES_LOADING:
			return {
				...state,
				all_categories_loading: action.payload
			}
		case ALL_CATEGORIES_GET:
			return {
				...state,
				all_categories: {
					count: action.payload.count,
					categories: action.payload.categories
				}
			}
		case CATEGORY_INFO_LOADING:
			return {
				...state,
				category_info_loading: action.payload
			}
		case CATEGORY_INFO_GET:
			return {
				...state,
				category_info: action.payload
			}
		case CATEGORY_UPDATE_LOADING:
			return {
				...state,
				category_update_loading: action.payload
			}
		case CATEGORY_DELETE_LOADING:
			return {
				...state,
				category_delete_loading: action.payload
			}
		case CATEGORY_CREATE_ERROR:
		case ALL_CATEGORIES_ERROR:
		case CATEGORY_INFO_ERROR:
		case CATEGORY_UPDATE_ERROR:
			return {
				...state,
				categories_errors: {
					...state.categories_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
