import {
	ALL_DIFFICULTIES_LOADING,
	ALL_DIFFICULTIES_GET,
	ALL_DIFFICULTIES_ERROR
} from '../types';

const initialState = {
	all_difficulties_loading: false,
	all_difficulties: { count: 0, difficulties: [] },
	difficulties_errors: {}
}

export default function difficultiesReducer (state = initialState, action) {
	switch (action.type) {
		case ALL_DIFFICULTIES_LOADING:
			return {
				...state,
				all_difficulties_loading: action.payload
			}
		case ALL_DIFFICULTIES_GET:
			return {
				...state,
				all_difficulties: {
					count: action.payload.count,
					difficulties: action.payload.difficulties
				}
			}
		case ALL_DIFFICULTIES_ERROR:
			return {
				...state,
				difficulties_errors: {
					...state.difficulties_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
