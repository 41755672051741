import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_PROPERTIES_DATA,
	CLEAR_ALL_PROPERTIES_ERRORS,
	CLEAR_PROPERTIES_SINGLE_ERROR,
	PROPERTY_FORM_MODAL_OPEN,
	PROPERTY_CREATE_LOADING,
	PROPERTY_CREATE_ERROR,
	ALL_PROPERTIES_LOADING,
	ALL_PROPERTIES_GET,
	ALL_PROPERTIES_ERROR,
	PROPERTY_INFO_LOADING,
	PROPERTY_INFO_GET,
	PROPERTY_INFO_ERROR,
	PROPERTY_UPDATE_LOADING,
	PROPERTY_UPDATE_ERROR,
	PROPERTY_DELETE_LOADING
} from '../types';

// Actions
import { get_all_states } from './statesActions';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_properties_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_PROPERTIES_DATA
	});
}

export const clear_properties_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_PROPERTIES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_PROPERTIES_ERRORS
		});
	}
}

export const is_property_form_modal_open = (open) => dispatch => {
	dispatch({
		type: PROPERTY_FORM_MODAL_OPEN,
		payload: open
	});
}

export const property_create = (propertyBody, handleCancel, isStateProperty, ingredientId) => dispatch => {
	dispatch({
		type: PROPERTY_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/properties/create';

	Axios.post(url, propertyBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: PROPERTY_CREATE_LOADING,
			payload: false
		});

		handleCancel();

		// If the property is a state property, we need to get all the states again
		if (isStateProperty) {
			dispatch(get_all_states({ ingredient: ingredientId }));
		}
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: PROPERTY_CREATE_ERROR,
			payload: { type: 'property_create', msg: err.message }
		});

		dispatch({
			type: PROPERTY_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_properties = (filters) => dispatch => {
	dispatch({
		type: ALL_PROPERTIES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/properties?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newPropertiesArray = add_key_prop(res.data.properties);
		res.data.properties = newPropertiesArray;

		dispatch({
			type: ALL_PROPERTIES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_PROPERTIES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_PROPERTIES_ERROR,
			payload: { type: 'get_all_properties', msg: err.message }
		});

		dispatch({
			type: ALL_PROPERTIES_LOADING,
			payload: false
		});
	})
}

export const get_property_info = (propertyId) => dispatch => {
	dispatch({
		type: PROPERTY_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/properties/${propertyId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: PROPERTY_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: PROPERTY_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: PROPERTY_INFO_ERROR,
			payload: { type: 'get_property_info', msg: err.message }
		});

		dispatch({
			type: PROPERTY_INFO_LOADING,
			payload: false
		});
	})
}

export const property_update = (messageApi, propertyId, propertyBody, handleCancel, isStateProperty, ingredientId) => dispatch => {
	dispatch({
		type: PROPERTY_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/properties/${propertyId}/update`;

	Axios.put(url, propertyBody)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.properties.edit.successAlert'), 4);

		dispatch({
			type: PROPERTY_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();

		// If the property is a state property, we need to get all the states again
		if (isStateProperty) {
			dispatch(get_all_states({ ingredient: ingredientId }));
		}
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: PROPERTY_UPDATE_ERROR,
			payload: { type: 'property_update', msg: err.message }
		});

		dispatch({
			type: PROPERTY_UPDATE_LOADING,
			payload: false
		});
	})
}

export const property_delete = (messageApi, propertyId, isStateProperty, filters) => dispatch => {
	dispatch({
		type: PROPERTY_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/properties/${propertyId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.properties.delete.successAlert'), 4);

		dispatch({
			type: PROPERTY_DELETE_LOADING,
			payload: false
		});

		// If the property is a state property, we need to get all the states again
		if (isStateProperty) {
			dispatch(get_all_states(filters));
		}
		else {
			dispatch(get_all_properties(filters));
		}
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('ingredients.properties.delete.errorAlert'), 4);

		dispatch({
			type: PROPERTY_DELETE_LOADING,
			payload: false
		});
	})
}
