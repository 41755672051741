import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_CHALLENGES_DATA,
	CLEAR_ALL_CHALLENGES_ERRORS,
	CLEAR_CHALLENGES_SINGLE_ERROR,
	CHALLENGE_CREATE_LOADING,
	SET_CHALLENGE_ID,
	CHALLENGE_CREATE_ERROR,
	ALL_CHALLENGES_LOADING,
	ALL_CHALLENGES_GET,
	ALL_CHALLENGES_ERROR,
	CHALLENGE_INFO_LOADING,
	CHALLENGE_INFO_GET,
	CHALLENGE_INFO_ERROR,
	CHALLENGE_UPDATE_LOADING,
	CHALLENGE_UPDATE_ERROR,
	CHALLENGE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_challenges_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_CHALLENGES_DATA
	});
}

export const clear_challenges_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_CHALLENGES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_CHALLENGES_ERRORS
		});
	}
}

export const set_challenge_create_id = (challengeId) => dispatch => {
	dispatch({
		type: SET_CHALLENGE_ID,
		payload: challengeId
	});
}

export const challenge_create = (messageApi, challengeBody, stepInfo) => dispatch => {
	dispatch(clear_challenges_errors('challenge_create'));

	dispatch({
		type: CHALLENGE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/challenges/create';

	Axios.post(url, challengeBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('challenges.create.successAlert'), 4);

		dispatch(set_challenge_create_id(res.data.challenge));

		dispatch({
			type: CHALLENGE_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_challenges({ step: stepInfo._id.$oid }));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CHALLENGE_CREATE_ERROR,
			payload: { type: 'challenge_create', msg: err }
		});

		dispatch({
			type: CHALLENGE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_challenges = (filters) => dispatch => {
	dispatch({
		type: ALL_CHALLENGES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/challenges?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newChallengesArray = add_key_prop(res.data.challenges);
		res.data.challenges = newChallengesArray;

		dispatch({
			type: ALL_CHALLENGES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_CHALLENGES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_CHALLENGES_ERROR,
			payload: { type: 'get_all_challenges', msg: err }
		});

		dispatch({
			type: ALL_CHALLENGES_LOADING,
			payload: false
		});
	})
}

export const get_challenge_info = (challengeId) => dispatch => {
	dispatch({
		type: CHALLENGE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/challenges/${challengeId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: CHALLENGE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: CHALLENGE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CHALLENGE_INFO_ERROR,
			payload: { type: 'get_challenge_info', msg: err }
		});

		dispatch({
			type: CHALLENGE_INFO_LOADING,
			payload: false
		});
	})
}

export const challenge_update = (messageApi, challengeId, challengeBody) => dispatch => {
	dispatch(clear_challenges_errors('challenge_update'));

	dispatch({
		type: CHALLENGE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/challenges/${challengeId}/update`;

	Axios.put(url, challengeBody)
	.then((res) => {
		messageApi.success(i18next.t('challenges.edit.successAlert'), 4);

		dispatch({
			type: CHALLENGE_UPDATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: CHALLENGE_UPDATE_ERROR,
			payload: { type: 'challenge_update', msg: err }
		});

		dispatch({
			type: CHALLENGE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const challenge_delete = (messageApi, challengeId, form, stepInfo) => dispatch => {
	dispatch({
		type: CHALLENGE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/challenges/${challengeId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('challenges.delete.successAlert'), 4);

		dispatch({
			type: CHALLENGE_DELETE_LOADING,
			payload: false
		});

		form.resetFields();
		dispatch(get_all_challenges({ step: stepInfo._id.$oid }));
		dispatch(clear_challenges_errors());
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('challenges.delete.errorAlert'), 4);

		dispatch({
			type: CHALLENGE_DELETE_LOADING,
			payload: false
		});
	})
}
