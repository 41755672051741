import {
	CLEAR_ALL_MEDALS_DATA,
	CLEAR_ALL_MEDALS_ERRORS,
	CLEAR_MEDALS_SINGLE_ERROR,
	MEDAL_CREATE_LOADING,
	SET_MEDAL_ID,
	MEDAL_CREATE_ERROR,
	ALL_MEDALS_LOADING,
	ALL_MEDALS_GET,
	ALL_MEDALS_ERROR,
	MEDAL_INFO_LOADING,
	MEDAL_INFO_GET,
	MEDAL_INFO_ERROR,
	MEDAL_UPDATE_LOADING,
	MEDAL_UPDATE_ERROR,
	MEDAL_DELETE_LOADING
} from '../types';

const initialState = {
	medal_create_loading: false,
	medal_id: null,
	all_medals_loading: false,
	all_medals: { count: 0, medals: [] },
	medal_info_loading: false,
	medal_info: {},
	medal_update_loading: false,
	medal_delete_loading: false,
	medals_errors: {}
}

export default function medalsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_MEDALS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_MEDALS_ERRORS:
			return {
				...state,
				medals_errors: {}
			}
		case CLEAR_MEDALS_SINGLE_ERROR:
			return {
				...state,
				medals_errors: Object.keys(state.medals_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.medals_errors[key];
					}
					return acc;
				}, {})
			}
		case MEDAL_CREATE_LOADING:
			return {
				...state,
				medal_create_loading: action.payload
			}
		case SET_MEDAL_ID:
			return {
				...state,
				medal_id: action.payload
			}
		case ALL_MEDALS_LOADING:
			return {
				...state,
				all_medals_loading: action.payload
			}
		case ALL_MEDALS_GET:
			return {
				...state,
				all_medals: {
					count: action.payload.count,
					medals: action.payload.medals
				}
			}
		case MEDAL_INFO_LOADING:
			return {
				...state,
				medal_info_loading: action.payload
			}
		case MEDAL_INFO_GET:
			return {
				...state,
				medal_info: action.payload
			}
		case MEDAL_UPDATE_LOADING:
			return {
				...state,
				medal_update_loading: action.payload
			}
		case MEDAL_DELETE_LOADING:
			return {
				...state,
				medal_delete_loading: action.payload
			}
		case MEDAL_CREATE_ERROR:
		case ALL_MEDALS_ERROR:
		case MEDAL_INFO_ERROR:
		case MEDAL_UPDATE_ERROR:
			return {
				...state,
				medals_errors: {
					...state.medals_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
