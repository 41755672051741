import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_STATES_DATA,
	CLEAR_ALL_STATES_ERRORS,
	CLEAR_STATES_ERROR,
	STATE_CREATE_LOADING,
	SET_STATE_ID,
	STATE_CREATE_ERROR,
	ALL_STATES_LOADING,
	ALL_STATES_GET,
	ALL_STATES_ERROR,
	STATE_INFO_LOADING,
	STATE_INFO_GET,
	STATE_INFO_ERROR,
	STATE_UPDATE_LOADING,
	STATE_UPDATE_ERROR,
	STATE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_states_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_STATES_DATA
	});
}

export const clear_states_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_STATES_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_STATES_ERRORS
		});
	}
}

export const set_state_create_id = (stateId) => dispatch => {
	dispatch({
		type: SET_STATE_ID,
		payload: stateId
	});
}

export const state_create = (stateBody) => dispatch => {
	dispatch({
		type: STATE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/states/create';

	Axios.post(url, stateBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_state_create_id(res.data.state));

		dispatch({
			type: STATE_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: STATE_CREATE_ERROR,
			payload: { type: 'state_create', msg: err.message }
		});

		dispatch({
			type: STATE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_states = (filters) => dispatch => {
	dispatch({
		type: ALL_STATES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/states?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newStatesArray = add_key_prop(res.data.states);
		res.data.states = newStatesArray;

		dispatch({
			type: ALL_STATES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_STATES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_STATES_ERROR,
			payload: { type: 'get_all_states', msg: err.message }
		});

		dispatch({
			type: ALL_STATES_LOADING,
			payload: false
		});
	})
}

export const get_state_info = (stateId) => dispatch => {
	dispatch({
		type: STATE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/states/${stateId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: STATE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: STATE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: STATE_INFO_ERROR,
			payload: { type: 'get_state_info', msg: err.message }
		});

		dispatch({
			type: STATE_INFO_LOADING,
			payload: false
		});
	})
}

export const state_update = (messageApi, stateId, stateBody, handleCancel) => dispatch => {
	dispatch({
		type: STATE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/states/${stateId}/update`;

	Axios.put(url, stateBody)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.states.edit.successAlert'), 4);

		dispatch({
			type: STATE_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: STATE_UPDATE_ERROR,
				payload: { type: 'state_update', msg: err.message }
			});
		}

		dispatch({
			type: STATE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const state_delete = (messageApi, stateId, filters) => dispatch => {
	dispatch({
		type: STATE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/states/${stateId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.states.delete.successAlert'), 4);

		dispatch({
			type: STATE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_states(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('ingredients.states.delete.errorAlert'), 4);

		dispatch({
			type: STATE_DELETE_LOADING,
			payload: false
		});
	})
}
