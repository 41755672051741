import isEmpty from "./isEmpty";
import { get_item_type_desc } from "./get_status_desc";
import { toMinutes } from "./set_video_time";

/**
 * @description - Set step form values and quantity types
 * @param {Object} stepToUpdate - Step info to update
 * @returns {Object} - Form values and quantity types	
 */
export const set_step_form_values = (stepToUpdate) => {
	let cooking_process = [];

	stepToUpdate.cooking_process.forEach((element) => {
		cooking_process.push({
			ingredient: { label: element.start.ingredient.name, value: element.start.ingredient._id.$oid },
			start: { label: element.start.name, value: element.start._id.$oid },
			end: { label: element.end.name, value: element.end._id.$oid },
			quantity: element.quantity || 0,
			action: { label: element.action.action.name, value: element.action.action._id.$oid },
			type: { label: element.action.name, value: element.action._id.$oid },
		});
	});

	// Set ingredient & state mix
	let ingredientsMix = null;
	let ingredientsMixState = null;

	if (!isEmpty(stepToUpdate.ingredients_mix)) {
		ingredientsMix = {
			label: stepToUpdate.ingredients_mix?.ingredient?.name,
			value: stepToUpdate.ingredients_mix?.ingredient?._id?.$oid
		}
		ingredientsMixState = {
			label: stepToUpdate.ingredients_mix?.name,
			value: stepToUpdate.ingredients_mix?._id?.$oid
		}
	}

	// Set global action & action type
	let stepAction = null;
	let	stepActionType = null;

	if (stepToUpdate.step_action !== null) {
		stepAction = {
			label: stepToUpdate.step_action?.action?.name,
			value: stepToUpdate.step_action?.action?._id?.$oid
		}
		stepActionType = {
			label: stepToUpdate.step_action?.name,
			value: stepToUpdate.step_action?._id?.$oid
		}
	}

	// Set global action & action type
	let goToItem = null;

	if (stepToUpdate.go_to_item !== null) {
		goToItem = {
			label: stepToUpdate.go_to_item?.name,
			value: stepToUpdate.go_to_item?._id?.$oid
		}
	}

	// Items custom format for select
	const customItems = stepToUpdate.items.map(item => {
		const itemType = get_item_type_desc(item.item_type);
		return {
			value: item?._id?.$oid,
			label: item.name + ' - ' + itemType.charAt(0).toUpperCase()
		}
	});
	
	const step_form_values = {
		recipe_type: stepToUpdate.recipe_type,
		name: stepToUpdate.name,
		description: stepToUpdate.description,
		cooking_process: cooking_process,
		ingredient: ingredientsMix,
		ingredients_mix: ingredientsMixState,
		result_goes_to: stepToUpdate.result_goes_to,
		go_to_item: goToItem,
		items: customItems, 
		equipment: stepToUpdate.equipment,
		step_action: stepAction,
		step_action_type: stepActionType,
		video: stepToUpdate.video,
		video_start: toMinutes(stepToUpdate.video_start),
		video_end: toMinutes(stepToUpdate.video_end)
	};

	return step_form_values;
}
