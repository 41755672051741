import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_UNITS_DATA,
	CLEAR_ALL_UNITS_ERRORS,
	CLEAR_UNITS_SINGLE_ERROR,
	UNIT_CREATE_LOADING,
	UNIT_CREATE_ERROR,
	ALL_UNITS_LOADING,
	ALL_UNITS_GET,
	ALL_UNITS_ERROR,
	UNIT_INFO_LOADING,
	UNIT_INFO_GET,
	UNIT_INFO_ERROR,
	UNIT_UPDATE_LOADING,
	UNIT_UPDATE_ERROR,
	UNIT_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_units_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_UNITS_DATA
	});
}

export const clear_units_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_UNITS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_UNITS_ERRORS
		});
	}
}

export const unit_create = (unitBody, handleCancel) => dispatch => {
	dispatch({
		type: UNIT_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/units/create';

	Axios.post(url, unitBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: UNIT_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: UNIT_CREATE_ERROR,
			payload: { type: 'unit_create', msg: err.message }
		});

		dispatch({
			type: UNIT_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_units = (filters) => dispatch => {
	dispatch({
		type: ALL_UNITS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/units?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newUnitsArray = add_key_prop(res.data.units);
		res.data.units = newUnitsArray;

		dispatch({
			type: ALL_UNITS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_UNITS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_UNITS_ERROR,
			payload: { type: 'get_all_units', msg: err.message }
		});

		dispatch({
			type: ALL_UNITS_LOADING,
			payload: false
		});
	})
}

export const get_unit_info = (unitId) => dispatch => {
	dispatch({
		type: UNIT_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/units/${unitId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: UNIT_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: UNIT_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: UNIT_INFO_ERROR,
			payload: { type: 'get_unit_info', msg: err.message }
		});

		dispatch({
			type: UNIT_INFO_LOADING,
			payload: false
		});
	})
}

export const unit_update = (messageApi, unitId, unitBody, handleCancel) => dispatch => {
	dispatch({
		type: UNIT_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/units/${unitId}/update`;

	Axios.put(url, unitBody)
	.then((res) => {
		messageApi.success(i18next.t('units.edit.successAlert'), 4);

		dispatch({
			type: UNIT_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: UNIT_UPDATE_ERROR,
			payload: { type: 'unit_update', msg: err.message }
		});

		dispatch({
			type: UNIT_UPDATE_LOADING,
			payload: false
		});
	})
}

export const unit_delete = (messageApi, unitId, filters) => dispatch => {
	dispatch({
		type: UNIT_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/units/${unitId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('units.delete.successAlert'), 4);

		dispatch({
			type: UNIT_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_units(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('units.delete.errorAlert'), 4);

		dispatch({
			type: UNIT_DELETE_LOADING,
			payload: false
		});
	})
}
