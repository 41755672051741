import {
	CLEAR_ALL_PACKAGES_DATA,
	CLEAR_ALL_PACKAGES_ERRORS,
	CLEAR_PACKAGES_SINGLE_ERROR,
	PACKAGE_CREATE_LOADING,
	SET_PACKAGE_ID,
	PACKAGE_CREATE_ERROR,
	ALL_PACKAGES_LOADING,
	ALL_PACKAGES_GET,
	ALL_PACKAGES_ERROR,
	PACKAGE_INFO_LOADING,
	PACKAGE_INFO_GET,
	PACKAGE_INFO_ERROR,
	PACKAGE_UPDATE_LOADING,
	PACKAGE_UPDATE_ERROR,
	PACKAGE_DELETE_LOADING
} from '../types';

const initialState = {
	package_create_loading: false,
	package_id: null,
	all_packages_loading: false,
	all_packages: { count: 0, packages: [] },
	package_info_loading: false,
	package_info: {},
	package_update_loading: false,
	package_delete_loading: false,
	packages_errors: {}
}

export default function packagesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_PACKAGES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_PACKAGES_ERRORS:
			return {
				...state,
				packages_errors: {}
			}
		case CLEAR_PACKAGES_SINGLE_ERROR:
			return {
				...state,
				packages_errors: Object.keys(state.packages_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.packages_errors[key];
					}
					return acc;
				}, {})
			}
		case PACKAGE_CREATE_LOADING:
			return {
				...state,
				package_create_loading: action.payload
			}
		case SET_PACKAGE_ID:
			return {
				...state,
				package_id: action.payload
			}
		case ALL_PACKAGES_LOADING:
			return {
				...state,
				all_packages_loading: action.payload
			}
		case ALL_PACKAGES_GET:
			return {
				...state,
				all_packages: {
					count: action.payload.count,
					packages: action.payload.packages
				}
			}
		case PACKAGE_INFO_LOADING:
			return {
				...state,
				package_info_loading: action.payload
			}
		case PACKAGE_INFO_GET:
			return {
				...state,
				package_info: action.payload
			}
		case PACKAGE_UPDATE_LOADING:
			return {
				...state,
				package_update_loading: action.payload
			}
		case PACKAGE_DELETE_LOADING:
			return {
				...state,
				package_delete_loading: action.payload
			}
		case PACKAGE_CREATE_ERROR:
		case ALL_PACKAGES_ERROR:
		case PACKAGE_INFO_ERROR:
		case PACKAGE_UPDATE_ERROR:
			return {
				...state,
				packages_errors: {
					...state.packages_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
