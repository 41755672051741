import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_MEDALS_DATA,
	CLEAR_ALL_MEDALS_ERRORS,
	CLEAR_MEDALS_SINGLE_ERROR,
	MEDAL_CREATE_LOADING,
	SET_MEDAL_ID,
	MEDAL_CREATE_ERROR,
	ALL_MEDALS_LOADING,
	ALL_MEDALS_GET,
	ALL_MEDALS_ERROR,
	MEDAL_INFO_LOADING,
	MEDAL_INFO_GET,
	MEDAL_INFO_ERROR,
	MEDAL_UPDATE_LOADING,
	MEDAL_UPDATE_ERROR,
	MEDAL_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_medals_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_MEDALS_DATA
	});
}

export const clear_medals_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_MEDALS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_MEDALS_ERRORS
		});
	}
}

export const set_medal_create_id = (medalId) => dispatch => {
	dispatch({
		type: SET_MEDAL_ID,
		payload: medalId
	});
}

export const medal_create = (medalBody) => dispatch => {
	dispatch({
		type: MEDAL_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/medals/create';

	Axios.post(url, medalBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_medal_create_id(res.data.medal));

		dispatch({
			type: MEDAL_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MEDAL_CREATE_ERROR,
			payload: { type: 'medal_create', msg: err.message }
		});

		dispatch({
			type: MEDAL_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_medals = (filters) => dispatch => {
	dispatch({
		type: ALL_MEDALS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/medals?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		console.log(res.data);
		const newMedalsArray = add_key_prop(res.data.medals);
		res.data.medals = newMedalsArray;

		dispatch({
			type: ALL_MEDALS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_MEDALS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_MEDALS_ERROR,
			payload: { type: 'get_all_medals', msg: err.message }
		});

		dispatch({
			type: ALL_MEDALS_LOADING,
			payload: false
		});
	})
}

export const get_medal_info = (medalId) => dispatch => {
	dispatch({
		type: MEDAL_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/medals/${medalId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: MEDAL_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: MEDAL_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MEDAL_INFO_ERROR,
			payload: { type: 'get_medal_info', msg: err.message }
		});

		dispatch({
			type: MEDAL_INFO_LOADING,
			payload: false
		});
	})
}

export const medal_update = (messageApi, medalId, medalBody, handleCancel) => dispatch => {
	dispatch({
		type: MEDAL_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/medals/${medalId}/update`;

	Axios.put(url, medalBody)
	.then((res) => {
		messageApi.success(i18next.t('medals.edit.successAlert'), 4);

		dispatch({
			type: MEDAL_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: MEDAL_UPDATE_ERROR,
				payload: { type: 'medal_update', msg: err.message }
			});
		}

		dispatch({
			type: MEDAL_UPDATE_LOADING,
			payload: false
		});
	})
}

export const medal_delete = (messageApi, medalId, filters) => dispatch => {
	dispatch({
		type: MEDAL_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/medals/${medalId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('medals.delete.successAlert'), 4);

		dispatch({
			type: MEDAL_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_medals(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('medals.delete.errorAlert'), 4);

		dispatch({
			type: MEDAL_DELETE_LOADING,
			payload: false
		});
	})
}
