import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Badge, Button, Col, Row, Space, Spin, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import TypeInfo from './TypeInfo';

// Actions
import { get_all_types } from '../../../redux/actions/typesActions';

const Types = (props) => {
	const { messageApi, setIsModalTypeOpen, setUpdateType, setTypeToUpdate } = props;

	const { action_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_types_loading, all_types } = useSelector(state => state.types);

	useEffect(() => {
		const filters = { action: action_id };
		dispatch(get_all_types(filters));
	}, []);

	const handleAdd = () => {
		setIsModalTypeOpen(true);
		setUpdateType(false);
		setTypeToUpdate({});
	}

	return (
		<div style={{ marginBottom: '3rem' }}>
			{all_types_loading
				?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
						<Spin size='large' />
					</div>
				:	<div>
						<Space direction='vertical' style={{ width: '100%', marginBottom: '2rem' }}>
							<Badge color='red' text={<Typography.Text type='secondary'>{t('actions.types.hasHaptics')}</Typography.Text>} />
							<Badge color='blue' text={<Typography.Text type='secondary'>{t('actions.types.isInteractive')}</Typography.Text>} />
						</Space>
						<Row gutter={[16, 16]}>
							{all_types.types.map((type, index) => (
								<Col key={type?._id?.$oid} xs={24} sm={24} md={12} lg={12} xl={6}>
									<TypeInfo
										messageApi={messageApi}
										actionId={action_id}
										type={type}
										setIsModalTypeOpen={setIsModalTypeOpen}
										setUpdateType={setUpdateType}
										setTypeToUpdate={setTypeToUpdate}
									/>
								</Col>
							))}
							<Col xs={24} sm={24} md={12} lg={12} xl={6}>
								<Button
									className='btn-add-ingredient-state'
									onClick={handleAdd}
								>
									<PlusCircleFilled style={{ fontSize: '40px' }} />
									{t('actions.types.create.modalTitle')}
								</Button>
							</Col>
						</Row>
					</div>
			}
		</div>
	)
}

Types.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalTypeOpen: PropTypes.func.isRequired,
	setUpdateType: PropTypes.func.isRequired,
	setTypeToUpdate: PropTypes.func.isRequired
}

export default Types;
