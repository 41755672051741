import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_TYPES_DATA,
	CLEAR_ALL_TYPES_ERRORS,
	CLEAR_TYPES_SINGLE_ERROR,
	TYPE_CREATE_LOADING,
	TYPE_CREATE_ERROR,
	ALL_TYPES_LOADING,
	ALL_TYPES_GET,
	ALL_TYPES_ERROR,
	TYPE_INFO_LOADING,
	TYPE_INFO_GET,
	TYPE_INFO_ERROR,
	TYPE_UPDATE_LOADING,
	TYPE_UPDATE_ERROR,
	TYPE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_types_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_TYPES_DATA
	});
}

export const clear_types_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_TYPES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_TYPES_ERRORS
		});
	}
}

export const type_create = (typeBody, handleCancel) => dispatch => {
	dispatch({
		type: TYPE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/types/create';

	Axios.post(url, typeBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: TYPE_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: TYPE_CREATE_ERROR,
			payload: { type: 'type_create', msg: err.message }
		});

		dispatch({
			type: TYPE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_types = (filters) => dispatch => {
	dispatch({
		type: ALL_TYPES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/types?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newWorldsArray = add_key_prop(res.data.types);
		res.data.types = newWorldsArray;

		dispatch({
			type: ALL_TYPES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_TYPES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_TYPES_ERROR,
			payload: { type: 'get_all_types', msg: err.message }
		});

		dispatch({
			type: ALL_TYPES_LOADING,
			payload: false
		});
	})
}

export const get_type_info = (typeId) => dispatch => {
	dispatch({
		type: TYPE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/types/${typeId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: TYPE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: TYPE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: TYPE_INFO_ERROR,
			payload: { type: 'get_type_info', msg: err.message }
		});

		dispatch({
			type: TYPE_INFO_LOADING,
			payload: false
		});
	})
}

export const type_update = (messageApi, typeId, typeBody, handleCancel) => dispatch => {
	dispatch({
		type: TYPE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/types/${typeId}/update`;

	Axios.put(url, typeBody)
	.then((res) => {
		messageApi.success(i18next.t('actions.types.edit.successAlert'), 4);

		dispatch({
			type: TYPE_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: TYPE_UPDATE_ERROR,
				payload: { type: 'type_update', msg: err.message }
			});
		}

		dispatch({
			type: TYPE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const type_delete = (messageApi, typeId, filters) => dispatch => {
	dispatch({
		type: TYPE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/types/${typeId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('actions.types.delete.successAlert'), 4);

		dispatch({
			type: TYPE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_types(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('actions.types.delete.errorAlert'), 4);

		dispatch({
			type: TYPE_DELETE_LOADING,
			payload: false
		});
	})
}
