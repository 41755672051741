/**
 * @description - Function to create the options for a select component.
 * @param {Array} options - Array of data to create the select options.
 * @param {String} label - Value to look for in the options array.
 * @returns {Array} - Array of objects with the value and label to be used in the select component.
 */
export const create_select_options = (options, label) => {
	return options.map((option) => {
		return {
			value: option?._id?.$oid,
			label: option[label]
		}
	})
}

/**
 * @description - Function to create the options for a select component from array.
 * @param {Array} options - Array of data to create the select options.
 * @returns {Array} - Array of objects with label, name and the value to be used in the select component.
 */
export const create_select_options_from_array = (options) => {
	return options.map((option, idx) => {
		return {
			label: option,
			name: option,
			value: (idx + 1),
		}
	})
}

/**
 * @description - Function to create the options for a select component from array.
 * @param {Array} options - Array of data to create the select options.
 * @returns {Array} - Array of objects with label, name and the value to be used in the select component.
 */
export const create_haptic_select_options = (options) => {
	return options.map((option, idx) => {
		return {
			value: idx,
			label: option
		}
	})
}
