import {
	CLEAR_ALL_WORLDS_DATA,
	CLEAR_ALL_WORLDS_ERRORS,
	CLEAR_WORLDS_SINGLE_ERROR,
	WORLD_CREATE_LOADING,
	SET_WORLD_ID,
	WORLD_CREATE_ERROR,
	ALL_WORLDS_LOADING,
	ALL_WORLDS_GET,
	ALL_WORLDS_ERROR,
	WORLD_INFO_LOADING,
	WORLD_INFO_GET,
	WORLD_INFO_ERROR,
	WORLD_UPDATE_LOADING,
	WORLD_UPDATE_ERROR,
	WORLD_DELETE_LOADING
} from '../types';

const initialState = {
	world_create_loading: false,
	world_id: null,
	all_worlds_loading: false,
	all_worlds: { count: 0, worlds: [] },
	world_info_loading: false,
	world_info: {},
	world_update_loading: false,
	world_delete_loading: false,
	worlds_errors: {}
}

export default function worldsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_WORLDS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_WORLDS_ERRORS:
			return {
				...state,
				worlds_errors: {}
			}
		case CLEAR_WORLDS_SINGLE_ERROR:
			return {
				...state,
				worlds_errors: Object.keys(state.worlds_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.worlds_errors[key];
					}
					return acc;
				}, {})
			}
		case WORLD_CREATE_LOADING:
			return {
				...state,
				world_create_loading: action.payload
			}
		case SET_WORLD_ID:
			return {
				...state,
				world_id: action.payload
			}
		case ALL_WORLDS_LOADING:
			return {
				...state,
				all_worlds_loading: action.payload
			}
		case ALL_WORLDS_GET:
			return {
				...state,
				all_worlds: {
					count: action.payload.count,
					worlds: action.payload.worlds
				}
			}
		case WORLD_INFO_LOADING:
			return {
				...state,
				world_info_loading: action.payload
			}
		case WORLD_INFO_GET:
			return {
				...state,
				world_info: action.payload
			}
		case WORLD_UPDATE_LOADING:
			return {
				...state,
				world_update_loading: action.payload
			}
		case WORLD_DELETE_LOADING:
			return {
				...state,
				world_delete_loading: action.payload
			}
		case WORLD_CREATE_ERROR:
		case ALL_WORLDS_ERROR:
		case WORLD_INFO_ERROR:
		case WORLD_UPDATE_ERROR:
			return {
				...state,
				worlds_errors: {
					...state.worlds_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
