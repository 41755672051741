import Axios from 'axios';
import i18next from 'i18next';

import {
	
} from '../types';

export const form_image_upload = (options, reload) => dispatch => {
	const { file, onSuccess, onError, action, onProgress } = options;
	
	onProgress({ percent: 0 }, file);
	
	const formData = new FormData();
	formData.append('image', file);

	Axios.post(action, formData, {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
	.then((res) => {
		// console.log(res.data);
		onSuccess(res.data, file);

		if (reload) {
			window.location.reload();
		}
	}).catch((err) => {
		// console.log(err);
		onError(err);
	})
}
