import React from 'react';

// Packages
import PropTypes from 'prop-types';

const Icon = (props) => {
	const { icon, size, color, marginEnd, sider } = props;

	const iconStyle = {
		fontSize: size,

		// If color exists, then add the following object to the iconStyle object
		...(color && {
			color: color
		}),

		// If marginEnd exists, then add the following object to the iconStyle object
		...(marginEnd && {
			marginInlineEnd: marginEnd
		})
	};
	
	return (
		<span 
			className={'material-symbols-outlined' + (sider ? ' material-symbols-outlined-400' : ' material-symbols-outlined-200')}
			style={iconStyle}
		>
			{icon}
		</span>
	)
}

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired,
	color: PropTypes.string,
	marginEnd: PropTypes.string,
	sider: PropTypes.bool
}

export default Icon;
