import {
	CLEAR_ALL_ITEMS_DATA,
	CLEAR_ALL_ITEMS_ERRORS,
	CLEAR_ITEMS_SINGLE_ERROR,
	ITEM_CREATE_LOADING,
	SET_ITEM_ID,
	ITEM_CREATE_ERROR,
	ALL_ITEMS_LOADING,
	ALL_ITEMS_GET,
	ALL_ITEMS_ERROR,
	ITEM_INFO_LOADING,
	ITEM_INFO_GET,
	ITEM_INFO_ERROR,
	ITEM_UPDATE_LOADING,
	ITEM_UPDATE_ERROR,
	ITEM_DELETE_LOADING
} from '../types';

const initialState = {
	item_create_loading: false,
	item_id: null,
	all_items_loading: false,
	all_items: { count: 0, items: [] },
	item_info_loading: false,
	item_info: {},
	item_update_loading: false,
	item_delete_loading: false,
	items_errors: {}
}

export default function itemsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_ITEMS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_ITEMS_ERRORS:
			return {
				...state,
				items_errors: {}
			}
		case CLEAR_ITEMS_SINGLE_ERROR:
			return {
				...state,
				items_errors: Object.keys(state.items_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.items_errors[key];
					}
					return acc;
				}, {})
			}
		case ITEM_CREATE_LOADING:
			return {
				...state,
				item_create_loading: action.payload
			}
		case SET_ITEM_ID:
			return {
				...state,
				item_id: action.payload
			}
		case ALL_ITEMS_LOADING:
			return {
				...state,
				all_items_loading: action.payload
			}
		case ALL_ITEMS_GET:
			return {
				...state,
				all_items: {
					count: action.payload.count,
					items: action.payload.items
				}
			}
		case ITEM_INFO_LOADING:
			return {
				...state,
				item_info_loading: action.payload
			}
		case ITEM_INFO_GET:
			return {
				...state,
				item_info: action.payload
			}
		case ITEM_UPDATE_LOADING:
			return {
				...state,
				item_update_loading: action.payload
			}
		case ITEM_DELETE_LOADING:
			return {
				...state,
				item_delete_loading: action.payload
			}
		case ITEM_CREATE_ERROR:
		case ALL_ITEMS_ERROR:
		case ITEM_INFO_ERROR:
		case ITEM_UPDATE_ERROR:
			return {
				...state,
				items_errors: {
					...state.items_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
