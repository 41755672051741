import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Col, Collapse, Empty, Flex, Popconfirm, Row, Typography } from 'antd';
import { LoadingOutlined, LockFilled, QuestionCircleFilled } from '@ant-design/icons';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { unlock_user_recipe } from '../../../redux/actions/recipesActions';

// Utils
import { get_color_contrast, transform_color_format } from '../../../utils/colors_handlers';

const GameProgress = (props) => {
	const { messageApi, world } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user_info } = useSelector(state => state.users);

	const { all_difficulties } = useSelector(state => state.difficulties);

	const { unlock_user_recipe_loading } = useSelector(state => state.recipes);

	const [recipesUnlocked, setRecipesUnlocked] = useState([]);
	const [worldRecipes, setWorldRecipes] = useState([]);

	useEffect(() => {
		if (world !== undefined) {
			let isMounted = true;

			Axios.get(process.env.REACT_APP_SERVER_URL + `/api/recipes/multiple?world=${world._id.$oid}`)
			.then((response) => {
				if (isMounted) {
					setWorldRecipes(response.data.recipes);
				}
			})
			.catch(() => {
				if (isMounted) {
					setWorldRecipes({});
				}
			});

			const recipeOids = [];
			if (Array.isArray(world.levels) && world.levels.length > 0) {
				world.levels.forEach((levelInfo) => {
					const { level, history } = levelInfo;  // Access 'level' and 'history' from 'levelInfo'

					// Loop through 'history' object
					for (const key in history) {
						const entry = history[key];
						
						// Check if 'status' is equal to 1 or 3
						if (entry.status === 1 || entry.status === 3) {
							// Add the recipe id to the array
							recipeOids.push(level.recipe.$oid);
							break;
						}
					};
				});
			}
			setRecipesUnlocked(recipeOids);
		}
	}, [world]);

	const unlock_recipe = (recipeId) => {
		const begginerDiff = all_difficulties.difficulties.find((difficulty) => difficulty.value === 0)?._id?.$oid;

		if (!begginerDiff) {
			messageApi.error(t('users.details.progress.difficultyError'));
			return;
		}

		const body = {
			recipe: recipeId,
			user: user_info?._id?.$oid,
			difficulty: begginerDiff
		}

		dispatch(unlock_user_recipe(messageApi, body));
	}

	return (
		<Collapse style={{ marginBottom: '1rem' }}
			items={[{
				key: world._id,
				label: world.name,
				children:(
					<Row gutter={[16, 16]}>
						{worldRecipes.length > 0 &&
							worldRecipes.map((recipe, idx) => (
								<Col key={recipe?._id?.$oid} xs={8} sm={5} md={4} lg={3} xl={3} xxl={2}>
									<div
										className='recipe-circle'
										style={{
											backgroundColor: !recipesUnlocked.includes(recipe?._id?.$oid) ? '#D8D8D8' : `#${transform_color_format(recipe.color)}`,
											cursor: !recipesUnlocked.includes(recipe?._id?.$oid) ? 'pointer' : 'default'
										}}
									>
										<p
											style={{
												color: !recipesUnlocked.includes(recipe?._id?.$oid) ? '#FFFFFF' : get_color_contrast(`#${transform_color_format(recipe.color)}`)
											}}
										>
											{recipe.name}
										</p>
										{!recipesUnlocked.includes(recipe?._id?.$oid) && (
											<Popconfirm
												className='lock-overlay'
												title={t('users.details.progress.unlock.confirmTitle')}
												description={t('users.details.progress.unlock.confirmText')}
												icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
												onConfirm={() => unlock_recipe(recipe?._id?.$oid)}
												okButtonProps={{
													loading: false
												}}
												okText={t('buttons.confirm')}
												cancelText={t('buttons.cancel')}
											>
												{unlock_user_recipe_loading
													?	<LoadingOutlined className='lock-icon' />
													:	<LockFilled className='lock-icon' />
												}
												
											</Popconfirm>
										)}
									</div>
								</Col>
							))
						}
						{worldRecipes.length === 0 &&
							<Flex style={{ width: '100%' }} justify='center'>
								<Empty description={<Typography.Text type='secondary'>{t('table.error.noData')}</Typography.Text>} />
							</Flex>
						}
					</Row>
				)
			}]}
		/>
	)
}

GameProgress.propTypes = {
	messageApi: PropTypes.object.isRequired,
	world: PropTypes.object.isRequired
}

export default GameProgress;
