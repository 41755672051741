import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_PACKAGES_DATA,
	CLEAR_ALL_PACKAGES_ERRORS,
	CLEAR_PACKAGES_SINGLE_ERROR,
	PACKAGE_CREATE_LOADING,
	SET_PACKAGE_ID,
	PACKAGE_CREATE_ERROR,
	ALL_PACKAGES_LOADING,
	ALL_PACKAGES_GET,
	ALL_PACKAGES_ERROR,
	PACKAGE_INFO_LOADING,
	PACKAGE_INFO_GET,
	PACKAGE_INFO_ERROR,
	PACKAGE_UPDATE_LOADING,
	PACKAGE_UPDATE_ERROR,
	PACKAGE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_packages_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_PACKAGES_DATA
	});
}

export const clear_packages_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_PACKAGES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_PACKAGES_ERRORS
		});
	}
}

export const set_package_create_id = (packageId) => dispatch => {
	dispatch({
		type: SET_PACKAGE_ID,
		payload: packageId
	});
}

export const package_create = (messageApi, packageBody) => dispatch => {
	dispatch({
		type: PACKAGE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/packs/create';

	Axios.post(url, packageBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_package_create_id(res.data.pack));

		dispatch({
			type: PACKAGE_CREATE_LOADING,
			payload: false
		});

		messageApi.success(i18next.t('packages.create.successAlert'), 4);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: PACKAGE_CREATE_ERROR,
			payload: { type: 'package_create', msg: err.message }
		});

		dispatch({
			type: PACKAGE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_packages = (filters) => dispatch => {
	dispatch({
		type: ALL_PACKAGES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/packs?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newPackagesArray = add_key_prop(res.data.packs);
		res.data.packages = newPackagesArray;

		dispatch({
			type: ALL_PACKAGES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_PACKAGES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_PACKAGES_ERROR,
			payload: { type: 'get_all_packages', msg: err.message }
		});

		dispatch({
			type: ALL_PACKAGES_LOADING,
			payload: false
		});
	})
}

export const get_package_info = (packageId) => dispatch => {
	dispatch({
		type: PACKAGE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/packs/${packageId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: PACKAGE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: PACKAGE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: PACKAGE_INFO_ERROR,
			payload: { type: 'get_package_info', msg: err.message }
		});

		dispatch({
			type: PACKAGE_INFO_LOADING,
			payload: false
		});
	})
}

export const package_update = (messageApi, packageId, packageBody, handleCancel) => dispatch => {
	dispatch({
		type: PACKAGE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/packs/${packageId}/update`;

	Axios.put(url, packageBody)
	.then((res) => {
		messageApi.success(i18next.t('packages.edit.successAlert'), 4);

		dispatch({
			type: PACKAGE_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: PACKAGE_UPDATE_ERROR,
				payload: { type: 'package_update', msg: err.message }
			});
		}

		dispatch({
			type: PACKAGE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const package_status_update = (messageApi, packageId, status) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/packs/${packageId}/status/update`;

	Axios.put(url, { status });
}

export const package_delete = (messageApi, packageId, filters) => dispatch => {
	dispatch({
		type: PACKAGE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/packs/${packageId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('packages.delete.successAlert'), 4);

		dispatch({
			type: PACKAGE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_packages(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('packages.delete.errorAlert'), 4);

		dispatch({
			type: PACKAGE_DELETE_LOADING,
			payload: false
		});
	})
}
