import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_ITEMS_DATA,
	CLEAR_ALL_ITEMS_ERRORS,
	CLEAR_ITEMS_SINGLE_ERROR,
	ITEM_CREATE_LOADING,
	SET_ITEM_ID,
	ITEM_CREATE_ERROR,
	ALL_ITEMS_LOADING,
	ALL_ITEMS_GET,
	ALL_ITEMS_ERROR,
	ITEM_INFO_LOADING,
	ITEM_INFO_GET,
	ITEM_INFO_ERROR,
	ITEM_UPDATE_LOADING,
	ITEM_UPDATE_ERROR,
	ITEM_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_items_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_ITEMS_DATA
	});
}

export const clear_items_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_ITEMS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_ITEMS_ERRORS
		});
	}
}

export const set_item_create_id = (itemId) => dispatch => {
	dispatch({
		type: SET_ITEM_ID,
		payload: itemId
	});
}

export const item_create = (itemBody) => dispatch => {
	dispatch(clear_items_errors('item_create'));
	
	dispatch({
		type: ITEM_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/items/create';

	Axios.post(url, itemBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_item_create_id(res.data.item));

		dispatch({
			type: ITEM_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ITEM_CREATE_ERROR,
			payload: { type: 'item_create', msg: err.message }
		});

		dispatch({
			type: ITEM_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_items = (filters) => dispatch => {
	dispatch({
		type: ALL_ITEMS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/items?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newItemsArray = add_key_prop(res.data.items);
		res.data.items = newItemsArray;

		dispatch({
			type: ALL_ITEMS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_ITEMS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_ITEMS_ERROR,
			payload: { type: 'get_all_items', msg: err.message }
		});

		dispatch({
			type: ALL_ITEMS_LOADING,
			payload: false
		});
	})
}

export const get_item_info = (itemId) => dispatch => {
	dispatch({
		type: ITEM_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/items/${itemId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ITEM_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: ITEM_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ITEM_INFO_ERROR,
			payload: { type: 'get_item_info', msg: err.message }
		});

		dispatch({
			type: ITEM_INFO_LOADING,
			payload: false
		});
	})
}

export const item_update = (messageApi, itemId, itemBody, handleCancel) => dispatch => {
	dispatch({
		type: ITEM_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/items/${itemId}/update`;

	Axios.put(url, itemBody)
	.then((res) => {
		messageApi.success(i18next.t('items.edit.successAlert'), 4);

		dispatch({
			type: ITEM_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: ITEM_UPDATE_ERROR,
				payload: { type: 'item_update', msg: err.message }
			});
		}

		dispatch({
			type: ITEM_UPDATE_LOADING,
			payload: false
		});
	})
}

export const item_delete = (messageApi, itemId, filters) => dispatch => {
	dispatch({
		type: ITEM_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/items/${itemId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('items.delete.successAlert'), 4);

		dispatch({
			type: ITEM_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_items(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('items.delete.errorAlert'), 4);

		dispatch({
			type: ITEM_DELETE_LOADING,
			payload: false
		});
	})
}
