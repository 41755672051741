import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Flex, Modal, Space, Spin, Tabs } from 'antd';
import { StarOutlined, TrophyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RuleForm from '../stepRules/RuleForm';
import ChallengeForm from '../stepChallenges/ChallengeForm';

// Actions
import { is_step_game_modal_open, set_step_info } from '../../../redux/actions/stepsActions';
import { clear_challenges_errors, get_all_challenges } from '../../../redux/actions/challengesActions';
import { clear_rules_errors, get_all_rules } from '../../../redux/actions/rulesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const StepGameModal = (props) => {
	const { messageApi, stepInfo, setHasUpdates } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { step_game_modal_open } = useSelector(state => state.steps);

	const { all_rules_loading, all_rules } = useSelector(state => state.rules);

	const { all_challenges_loading, all_challenges } = useSelector(state => state.challenges);

	useEffect(() => {
		if (!isEmpty(stepInfo)) {
			setHasUpdates(false);
			dispatch(get_all_rules({ step: stepInfo._id.$oid }));
			dispatch(get_all_challenges({ step: stepInfo._id.$oid }));
		}
	}, [stepInfo]);

	const handleCancel = () => {
		dispatch(is_step_game_modal_open(false));
		dispatch(clear_rules_errors());
		dispatch(clear_challenges_errors());
		dispatch(set_step_info({}));
		setHasUpdates(true);
	}
	
	return (
		<Modal
			title={stepInfo.name}
			centered
			open={step_game_modal_open}
			onCancel={handleCancel}
			width={700}
			maskClosable={false}
			footer={null}
		>
			<Tabs
				defaultActiveKey='1'
				centered
				items={[
					{
						label: (
							<Space size={3}>
								<TrophyOutlined /> <div>{t('rules.title')}</div>
							</Space>
						),
						key: '1',
						children: all_rules_loading ? (
							<Flex align='center' justify='center' style={{ height: '228px' }}>
								<Spin size='large' />
							</Flex>
						) : (
							<RuleForm
								messageApi={messageApi}
								stepInfo={stepInfo}
								update={all_rules.rules.length > 0}
								ruleToUpdate={all_rules.rules[0]}
							/>
						)
					},
					{
						label: (
							<Space size={3}>
								<StarOutlined /> <div>{t('challenges.title')}</div>
							</Space>
						),
						key: '2',
						children: all_challenges_loading ? (
							<Flex align='center' justify='center' style={{ height: '336px' }}>
								<Spin size='large' />
							</Flex>
						) : (
							<ChallengeForm
								messageApi={messageApi}
								stepInfo={stepInfo}
								update={all_challenges.challenges.length > 0}
								challengeToUpdate={all_challenges.challenges[0]}
							/>
						)
					}
				]}
			/>
		</Modal>
	)
}

StepGameModal.propTypes = {
	messageApi: PropTypes.object.isRequired,
	stepInfo: PropTypes.object.isRequired,
	setHasUpdates: PropTypes.func.isRequired
}

export default StepGameModal;
