import {
	CLEAR_ALL_CHALLENGES_DATA,
	CLEAR_ALL_CHALLENGES_ERRORS,
	CLEAR_CHALLENGES_SINGLE_ERROR,
	CHALLENGE_CREATE_LOADING,
	SET_CHALLENGE_ID,
	CHALLENGE_CREATE_ERROR,
	ALL_CHALLENGES_LOADING,
	ALL_CHALLENGES_GET,
	ALL_CHALLENGES_ERROR,
	CHALLENGE_INFO_LOADING,
	CHALLENGE_INFO_GET,
	CHALLENGE_INFO_ERROR,
	CHALLENGE_UPDATE_LOADING,
	CHALLENGE_UPDATE_ERROR,
	CHALLENGE_DELETE_LOADING
} from '../types';

const initialState = {
	challenge_create_loading: false,
	challenge_id: null,
	all_challenges_loading: false,
	all_challenges: { count: 0, challenges: [] },
	challenge_info_loading: false,
	challenge_info: {},
	challenge_update_loading: false,
	challenge_delete_loading: false,
	challenges_errors: {}
}

export default function challengesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_CHALLENGES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_CHALLENGES_ERRORS:
			return {
				...state,
				challenges_errors: {}
			}
		case CLEAR_CHALLENGES_SINGLE_ERROR:
			return {
				...state,
				challenges_errors: Object.keys(state.challenges_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.challenges_errors[key];
					}
					return acc;
				}, {})
			}
		case CHALLENGE_CREATE_LOADING:
			return {
				...state,
				challenge_create_loading: action.payload
			}
		case SET_CHALLENGE_ID:
			return {
				...state,
				challenge_id: action.payload
			}
		case ALL_CHALLENGES_LOADING:
			return {
				...state,
				all_challenges_loading: action.payload
			}
		case ALL_CHALLENGES_GET:
			return {
				...state,
				all_challenges: {
					count: action.payload.count,
					challenges: action.payload.challenges
				}
			}
		case CHALLENGE_INFO_LOADING:
			return {
				...state,
				challenge_info_loading: action.payload
			}
		case CHALLENGE_INFO_GET:
			return {
				...state,
				challenge_info: action.payload
			}
		case CHALLENGE_UPDATE_LOADING:
			return {
				...state,
				challenge_update_loading: action.payload
			}
		case CHALLENGE_DELETE_LOADING:
			return {
				...state,
				challenge_delete_loading: action.payload
			}
		case CHALLENGE_CREATE_ERROR:
		case ALL_CHALLENGES_ERROR:
		case CHALLENGE_INFO_ERROR:
		case CHALLENGE_UPDATE_ERROR:
			return {
				...state,
				challenges_errors: {
					...state.challenges_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
