import {
	CLEAR_ALL_RULES_DATA,
	CLEAR_ALL_RULES_ERRORS,
	CLEAR_RULES_SINGLE_ERROR,
	RULE_CREATE_LOADING,
	SET_RULE_ID,
	RULE_CREATE_ERROR,
	ALL_RULES_LOADING,
	ALL_RULES_GET,
	ALL_RULES_ERROR,
	RULE_INFO_LOADING,
	RULE_INFO_GET,
	RULE_INFO_ERROR,
	RULE_UPDATE_LOADING,
	RULE_UPDATE_ERROR,
	RULE_DELETE_LOADING
} from '../types';

const initialState = {
	rule_create_loading: false,
	rule_id: null,
	all_rules_loading: false,
	all_rules: { count: 0, rules: [] },
	rule_info_loading: false,
	rule_info: {},
	rule_update_loading: false,
	rule_delete_loading: false,
	rules_errors: {}
}

export default function rulesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_RULES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_RULES_ERRORS:
			return {
				...state,
				rules_errors: {}
			}
		case CLEAR_RULES_SINGLE_ERROR:
			return {
				...state,
				rules_errors: Object.keys(state.rules_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.rules_errors[key];
					}
					return acc;
				}, {})
			}
		case RULE_CREATE_LOADING:
			return {
				...state,
				rule_create_loading: action.payload
			}
		case SET_RULE_ID:
			return {
				...state,
				rule_id: action.payload
			}
		case ALL_RULES_LOADING:
			return {
				...state,
				all_rules_loading: action.payload
			}
		case ALL_RULES_GET:
			return {
				...state,
				all_rules: {
					count: action.payload.count,
					rules: action.payload.rules
				}
			}
		case RULE_INFO_LOADING:
			return {
				...state,
				rule_info_loading: action.payload
			}
		case RULE_INFO_GET:
			return {
				...state,
				rule_info: action.payload
			}
		case RULE_UPDATE_LOADING:
			return {
				...state,
				rule_update_loading: action.payload
			}
		case RULE_DELETE_LOADING:
			return {
				...state,
				rule_delete_loading: action.payload
			}
		case RULE_CREATE_ERROR:
		case ALL_RULES_ERROR:
		case RULE_INFO_ERROR:
		case RULE_UPDATE_ERROR:
			return {
				...state,
				rules_errors: {
					...state.rules_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
