import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_categories, set_category_create_id, category_create, category_update } from '../../../redux/actions/categoriesActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const CategoryForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, categoryToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { category_create_loading, category_id, category_update_loading } = useSelector(state => state.categories);

	const [fileList, setFileList] = useState([]);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(categoryToUpdate)) {
			form.setFieldsValue(categoryToUpdate);
		}
	}, [categoryToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setIsModalOpen(false);
		dispatch(set_category_create_id(null));

		if (finish) {
			const filters = { skip: skip, limit: pageSize };
			dispatch(get_all_categories(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					dispatch(category_create(messageApi, values));
				}
				else {
					// Update
					dispatch(category_update(messageApi, category_id, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('ingredients.categories.create.modalTitle') : t('ingredients.categories.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? category_create_loading : category_update_loading}
					onClick={category_id && !update ? () => handleCancel(true) : onOk}
				>
					{category_id ? t('buttons.finish') : t('buttons.save')}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
			</Form>
			{category_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/recipes/categories/${category_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
				</Space>
			)}
		</Modal>
	)
}

CategoryForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	categoryToUpdate: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default CategoryForm;
