import React from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { recipe_tutorial_upload } from '../../../redux/actions/recipesActions';

const RecipeTutorialUpload = (props) => {
	const { messageApi, recipeId, reload } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const checkFileType = (file) => {
		const isPDF = file.type === 'application/pdf';

		if (!isPDF) {
			messageApi.error(`${file.name} ${t('form.rules.pdf')}`, 7);
		}
		
		return isPDF || Upload.LIST_IGNORE;
	}

	return (
		<Form>
			<Form.Item
				style={{ marginBottom: 0 }}
				label={t('form.label.pdf')}
				name='file'
			>
				<Upload
					action={process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/tutorial/upload`}
					customRequest={(options) => dispatch(recipe_tutorial_upload(options, reload))}
					beforeUpload={checkFileType}
				>
					<Button icon={<UploadOutlined />}>{t('form.placeholders.pdf')}</Button>
				</Upload>
			</Form.Item>
		</Form>
	)
}

RecipeTutorialUpload.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeId: PropTypes.string,
	reload: PropTypes.bool.isRequired
}

export default RecipeTutorialUpload;
