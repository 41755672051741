import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { EditFilled, DeleteFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_units, unit_delete } from '../../redux/actions/unitsActions';

const UnitsTable = (props) => {
	const { messageApi, setIsModalOpen, setUpdate, setUnitToUpdate, pageSize, setPageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_units_loading, all_units, unit_delete_loading } = useSelector(state => state.units);

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setUnitToUpdate(record);
	}

	const deleteUnit = (record) => {
		const filters = { skip: skip, limit: pageSize };
		dispatch(unit_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			key: 'name',
			title: t('form.label.name'),
			dataIndex: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			key: 'description',
			title: t('form.label.description'),
			dataIndex: 'description'
		},
		{
			key: 'abbreviation',
			title: t('form.label.abbreviation'),
			dataIndex: 'abbreviation'
		},
		{
			key: 'action',
			title: t('table.headers.actions'),
			dataIndex: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('buttons.edit')}>
						<Button
							type='primary'
							style={{ backgroundColor: '#80B4B9' }}
							shape='circle'
							size='large'
							icon={<EditFilled />}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('units.delete.confirmTitle')}
						description={t('units.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteUnit(record)}
						okButtonProps={{
							loading: unit_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<DeleteFilled />}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pagination.pageSize;
		setSkip(skip);
		localStorage.setItem('unitsSkip', skip);

		const limit = pagination.pageSize;
		setPageSize(limit);
		localStorage.setItem('unitsPageSize', limit);

		const unitsFilters = { skip, limit };
		dispatch(get_all_units(unitsFilters));
	};

	return (
		<Spin spinning={all_units_loading}>
			<Table
				columns={columns}
				dataSource={all_units.units}
				onChange={handleTableChange}
				pagination={{
					pageSize: pageSize, 
					total: all_units.count,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('table.pagination.of')} ${total}`,
					showTitle: false,
					responsive: true,
					locale: { items_per_page: `/ ${t('table.pagination.page')}` }
				}}
			/>
		</Spin>
	)
}

UnitsTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setUnitToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default UnitsTable;
