import {
	CLEAR_ALL_INGREDIENTS_DATA,
	CLEAR_ALL_INGREDIENTS_ERRORS,
	CLEAR_INGREDIENTS_ERROR,
	INGREDIENT_CREATE_LOADING,
	SET_INGREDIENT_ID,
	INGREDIENT_CREATE_ERROR,
	ALL_INGREDIENTS_LOADING,
	ALL_INGREDIENTS_GET,
	ALL_INGREDIENTS_ERROR,
	INGREDIENT_INFO_LOADING,
	INGREDIENT_INFO_GET,
	INGREDIENT_INFO_ERROR,
	INGREDIENT_UPDATE_LOADING,
	INGREDIENT_UPDATE_ERROR,
	INGREDIENT_DELETE_LOADING
} from '../types';

const initialState = {
	ingredient_create_loading: false,
	ingredient_id: null,
	all_ingredients_loading: false,
	all_ingredients: { count: 0, ingredients: [] },
	ingredient_info_loading: false,
	ingredient_info: {},
	ingredient_update_loading: false,
	ingredient_delete_loading: false,
	ingredients_errors: {}
}

export default function ingredientsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_INGREDIENTS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_INGREDIENTS_ERRORS:
			return {
				...state,
				ingredients_errors: {}
			}
		case CLEAR_INGREDIENTS_ERROR:
			return {
				...state,
				ingredients_errors: Object.keys(state.ingredients_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.ingredients_errors[key];
					}
					return acc;
				}, {})
			}
		case INGREDIENT_CREATE_LOADING:
			return {
				...state,
				ingredient_create_loading: action.payload
			}
		case SET_INGREDIENT_ID:
			return {
				...state,
				ingredient_id: action.payload
			}
		case ALL_INGREDIENTS_LOADING:
			return {
				...state,
				all_ingredients_loading: action.payload
			}
		case ALL_INGREDIENTS_GET:
			return {
				...state,
				all_ingredients: {
					count: action.payload.count,
					ingredients: action.payload.ingredients
				}
			}
		case INGREDIENT_INFO_LOADING:
			return {
				...state,
				ingredient_info_loading: action.payload
			}
		case INGREDIENT_INFO_GET:
			return {
				...state,
				ingredient_info: action.payload
			}
		case INGREDIENT_UPDATE_LOADING:
			return {
				...state,
				ingredient_update_loading: action.payload
			}
		case INGREDIENT_DELETE_LOADING:
			return {
				...state,
				ingredient_delete_loading: action.payload
			}
		case INGREDIENT_CREATE_ERROR:
		case ALL_INGREDIENTS_ERROR:
		case INGREDIENT_INFO_ERROR:
		case INGREDIENT_UPDATE_ERROR:
			return {
				...state,
				ingredients_errors: {
					...state.ingredients_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
