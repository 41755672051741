import React, { useEffect, useState } from 'react';

// Packages
import { Flex, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const Authentication = () => {
	const { t } = useTranslation();

	const [time, setTime] = useState(3);

	useEffect(() => {
		if (time > 0) {
			const timer = setTimeout(() => {
				setTime(time - 1);
			}, 1000);

			return () => clearTimeout(timer);
		}
	}, [time]);

	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			window.location.href = process.env.REACT_APP_LOGIN;
		}, 3000);
		return () => clearTimeout(redirectTimer);
	}, []);

	return (
		<Flex vertical align='center' justify='center' style={{ height: '90vh' }}>
			<Result
				status='403'
				title={t('auth.title')}
				subTitle={`${t('auth.subTitle')} ${time}`}
			/>
		</Flex>
	)
}

export default Authentication;
