// RECIPE_STATUS_NONE = 0
// RECIPE_STATUS_AVAILABLE = 1
// RECIPE_STATUS_COMING_SOON = 2
// RECIPE_STATUS_DISABLED = 3
export const get_recipe_status_color = (status = 0) => {
	let color = '';

	switch (parseInt(status)) {
		case 0:   color = '';      break;
		case 1:   color = 'green'; break;
		case 2:   color = 'blue';  break;
		case 3:   color = 'red';   break;
		default:  color = '';      break;
	}

	return color;
}
