import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Affix, Badge, Button, Col, Flex, Row, Space, Spin, Typography } from 'antd';
import { CloseOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import StepsForm from './StepsForm';
import StepInfo from './StepInfo';
import Icon from '../../utils/Icon';
import StepGameModal from './StepGameModal';

// Actions
import { all_steps_update, clear_all_steps_data, get_all_steps } from '../../../redux/actions/stepsActions';
import { get_all_states } from '../../../redux/actions/statesActions';

const SortableItem = (props) => {
	const { id, children, isDragEnabled } = props;

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: id,
		disabled: !isDragEnabled
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.5 : 1,
		cursor: isDragEnabled ? 'grab' : 'default'
	};

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			{children}
		</div>
	);
};

SortableItem.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	isDragEnabled: PropTypes.bool.isRequired
};


const Steps = (props) => {
	const { messageApi, recipeData } = props;

	const { t } = useTranslation();

	const { recipe_id } = useParams();

	const dispatch = useDispatch();

	const { all_steps_loading, all_steps, step_info } = useSelector(state => state.steps);

	const [showAddStep, setShowAddStep] = useState(false);
	const [updateStep, setUpdateStep] = useState(false);
	const [stepToUpdate, setStepToUpdate] = useState({});

	const [localSteps, setLocalSteps] = useState([]);
	const [isDragEnabled, setIsDragEnabled] = useState(false);

	// Trigger to re-render StepInfo when rules or challenges are updated.
	const [hasUpdates, setHasUpdates] = useState(false);

	const sensors = useSensors(
		useSensor(PointerSensor, { activationConstraint: { distance: 5 }, enabled: isDragEnabled }),
		useSensor(KeyboardSensor, { enabled: isDragEnabled })
	);

	useEffect(() => {
		dispatch(get_all_steps({ recipe: recipe_id }));

		return () => {
			dispatch(clear_all_steps_data());
		};
	}, [dispatch, recipe_id]);

	useEffect(() => {
		// Initialize local state with steps loaded from Redux
		setLocalSteps(all_steps.steps);
	}, [all_steps.steps]);

	const handleDragEnd = (event) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			const oldIndex = localSteps.findIndex(step => step.key === active.id);
			const newIndex = localSteps.findIndex(step => step.key === over.id);
			const newSteps = arrayMove(localSteps, oldIndex, newIndex);
			setLocalSteps(newSteps);
		}
	};

	const toggleDragMode = () => setIsDragEnabled(!isDragEnabled);

	const handleCancelDragging = () => {
		toggleDragMode()
		setLocalSteps(all_steps.steps);
	};

	const updateAllSteps = () => {
		dispatch(all_steps_update(messageApi, localSteps, { recipe: recipe_id }));
		toggleDragMode()
	};

	const addStep = () => {
		setShowAddStep(true);
		setUpdateStep(false);
		setStepToUpdate({});
		dispatch(get_all_states({ skip: 0, limit: 0 }));
	}

	return (
		<div style={{ margin: '2rem 0 1rem' }}>
			<StepGameModal
				messageApi={messageApi}
				stepInfo={step_info}
				setHasUpdates={setHasUpdates}
			/>

			<Affix offsetTop={0}>
				<Row style={{ backgroundColor: '#F1F1F1' }}>
					<Col xs={24} sm={18} md={18} lg={18} xl={18}>
						<Typography.Title level={2}>{t('steps.title')}</Typography.Title>
					</Col>
					<Col xs={24} sm={6} md={6} lg={6} xl={6}>
						<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
							{isDragEnabled &&
								<Button
									style={{ marginRight: '1rem' }}
									size='large'
									icon={<CloseOutlined />}
									onClick={handleCancelDragging}
								>
									{t('buttons.cancel')}
								</Button>
							}
							<Button
								type='primary'
								size='large'
								onClick={isDragEnabled ? updateAllSteps : toggleDragMode}
								disabled={all_steps.count < 2}
							>
								<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
									{isDragEnabled
										?	<SaveFilled style={{ marginRight: 8 }} />
										:	<Icon icon='swap_vert' size={16} marginEnd='0.5rem' sider />
									}
									{isDragEnabled ? t('buttons.save') : t('buttons.reorder')}
								</Flex>
							</Button>
						</Flex>
					</Col>
				</Row>
			</Affix>

			<Space style={{ marginBottom: '1rem' }}>
				<Badge color='#F18B71' text={t('rules.title')} />
				<Badge color='#80B4B9' text={t('challenges.title')} />
			</Space>

			{all_steps_loading
				?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
						<Spin size='large' />
					</div>
				:	<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
						<SortableContext items={localSteps.map(step => step.key)} strategy={verticalListSortingStrategy}>
							<Space direction='vertical' size='large' style={{ display: 'flex' }}>
								{localSteps.map((step, idx) => (
									<SortableItem key={step.key} id={step.key} isDragEnabled={isDragEnabled}>
										{stepToUpdate?._id?.$oid === step.key
											?	<StepsForm
													messageApi={messageApi}
													recipeData={recipeData}
													steps={localSteps}
													setShowAddStep={setShowAddStep}
													updateStep={updateStep}
													setUpdateStep={setUpdateStep}
													stepToUpdate={stepToUpdate}
													setStepToUpdate={setStepToUpdate}
												/>
											:	<StepInfo
													messageApi={messageApi}
													stepIndex={idx + 1}
													stepId={step?._id?.$oid}
													localSteps={localSteps}
													setUpdateStep={setUpdateStep}
													setStepToUpdate={setStepToUpdate}
													hasUpdates={hasUpdates}
												/>
										}
									</SortableItem>
								))}

								{showAddStep && (
									<StepsForm
										messageApi={messageApi}
										recipeData={recipeData}
										steps={localSteps}
										setShowAddStep={setShowAddStep}
										updateStep={updateStep}
										setUpdateStep={setUpdateStep}
										stepToUpdate={stepToUpdate}
										setStepToUpdate={setStepToUpdate}
									/>
								)}
							</Space>
						</SortableContext>
					</DndContext>
			}

			{!showAddStep && (
				<Flex justify='center' style={{ padding: '3rem' }}>
					<Button
						type='primary'
						className='btn-add-step'
						shape='circle'
						size={200}
						icon={<PlusOutlined style={{ fontSize: '1.8rem' }} />}
						onClick={addStep}
					/>
				</Flex>
			)}
		</div>
	);
};

Steps.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeData: PropTypes.object.isRequired
};

export default Steps;
