import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_LEVELS_DATA,
	CLEAR_ALL_LEVELS_ERRORS,
	CLEAR_LEVELS_SINGLE_ERROR,
	LEVEL_CREATE_LOADING,
	SET_LEVEL_ID,
	LEVEL_CREATE_ERROR,
	ALL_LEVELS_LOADING,
	ALL_LEVELS_GET,
	ALL_LEVELS_ERROR,
	LEVEL_INFO_LOADING,
	LEVEL_INFO_GET,
	LEVEL_INFO_ERROR,
	LEVEL_UPDATE_LOADING,
	LEVEL_UPDATE_ERROR,
	LEVEL_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_levels_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_LEVELS_DATA
	});
}

export const clear_levels_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_LEVELS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_LEVELS_ERRORS
		});
	}
}

export const set_level_create_id = (levelId) => dispatch => {
	dispatch({
		type: SET_LEVEL_ID,
		payload: levelId
	});
}

export const level_create = (messageApi, levelBody, handleCancel) => dispatch => {
	dispatch(clear_levels_errors('level_create'));

	dispatch({
		type: LEVEL_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/levels/create';

	Axios.post(url, levelBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('levels.create.successAlert'), 4);

		dispatch(set_level_create_id(res.data.level));

		dispatch({
			type: LEVEL_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LEVEL_CREATE_ERROR,
			payload: { type: 'level_create', msg: err }
		});

		dispatch({
			type: LEVEL_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_levels = (filters) => dispatch => {
	dispatch({
		type: ALL_LEVELS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/levels?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newLevelsArray = add_key_prop(res.data.levels);
		res.data.levels = newLevelsArray;

		dispatch({
			type: ALL_LEVELS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_LEVELS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_LEVELS_ERROR,
			payload: { type: 'get_all_levels', msg: err }
		});

		dispatch({
			type: ALL_LEVELS_LOADING,
			payload: false
		});
	})
}

export const get_level_info = (levelId) => dispatch => {
	dispatch({
		type: LEVEL_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/levels/${levelId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: LEVEL_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: LEVEL_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LEVEL_INFO_ERROR,
			payload: { type: 'get_level_info', msg: err }
		});

		dispatch({
			type: LEVEL_INFO_LOADING,
			payload: false
		});
	})
}

export const level_update = (messageApi, levelId, levelBody, handleCancel) => dispatch => {
	dispatch(clear_levels_errors('level_update'));

	dispatch({
		type: LEVEL_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/levels/${levelId}/update`;

	Axios.put(url, levelBody)
	.then((res) => {
		messageApi.success(i18next.t('levels.edit.successAlert'), 4);

		dispatch({
			type: LEVEL_UPDATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: LEVEL_UPDATE_ERROR,
			payload: { type: 'level_update', msg: err }
		});

		dispatch({
			type: LEVEL_UPDATE_LOADING,
			payload: false
		});
	})
}

export const level_delete = (messageApi, levelId, form, handleCancel) => dispatch => {
	dispatch({
		type: LEVEL_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/levels/${levelId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('levels.delete.successAlert'), 4);

		dispatch({
			type: LEVEL_DELETE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('levels.delete.errorAlert'), 4);

		dispatch({
			type: LEVEL_DELETE_LOADING,
			payload: false
		});
	})
}
