import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_ACTIONS_DATA,
	CLEAR_ALL_ACTIONS_ERRORS,
	CLEAR_ACTIONS_SINGLE_ERROR,
	ACTION_CREATE_LOADING,
	ACTION_CREATE_ERROR,
	ALL_ACTIONS_LOADING,
	ALL_ACTIONS_GET,
	ALL_ACTIONS_ERROR,
	ACTION_INFO_LOADING,
	ACTION_INFO_GET,
	ACTION_INFO_ERROR,
	ACTION_UPDATE_LOADING,
	ACTION_UPDATE_ERROR,
	ACTION_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_actions_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_ACTIONS_DATA
	});
}

export const clear_actions_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_ACTIONS_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_ACTIONS_ERRORS
		});
	}
}

export const action_create = (actionBody, handleCancel) => dispatch => {
	dispatch({
		type: ACTION_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/actions/create';

	Axios.post(url, actionBody)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ACTION_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ACTION_CREATE_ERROR,
			payload: { type: 'action_create', msg: err.message }
		});

		dispatch({
			type: ACTION_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_actions = (filters) => dispatch => {
	dispatch({
		type: ALL_ACTIONS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/actions?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newWorldsArray = add_key_prop(res.data.actions);
		res.data.actions = newWorldsArray;

		dispatch({
			type: ALL_ACTIONS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_ACTIONS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_ACTIONS_ERROR,
			payload: { type: 'get_all_actions', msg: err.message }
		});

		dispatch({
			type: ALL_ACTIONS_LOADING,
			payload: false
		});
	})
}

export const get_action_info = (actionId) => dispatch => {
	dispatch({
		type: ACTION_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/actions/${actionId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ACTION_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: ACTION_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ACTION_INFO_ERROR,
			payload: { type: 'get_action_info', msg: err.message }
		});

		dispatch({
			type: ACTION_INFO_LOADING,
			payload: false
		});
	})
}

export const action_update = (messageApi, actionId, actionBody, handleCancel) => dispatch => {
	dispatch({
		type: ACTION_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/actions/${actionId}/update`;

	Axios.put(url, actionBody)
	.then((res) => {
		messageApi.success(i18next.t('actions.edit.successAlert'), 4);

		dispatch({
			type: ACTION_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: ACTION_UPDATE_ERROR,
				payload: { type: 'action_update', msg: err.message }
			});
		}

		dispatch({
			type: ACTION_UPDATE_LOADING,
			payload: false
		});
	})
}

export const action_delete = (messageApi, actionId, filters) => dispatch => {
	dispatch({
		type: ACTION_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/actions/${actionId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('actions.delete.successAlert'), 4);

		dispatch({
			type: ACTION_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_actions(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('actions.delete.errorAlert'), 4);

		dispatch({
			type: ACTION_DELETE_LOADING,
			payload: false
		});
	})
}
