import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();

	const { user_info } = useSelector(state => state.users);

	return (
		<Flex vertical align='center' justify='center' style={{ height: '90vh' }}>
			<img
				className='home-img'
				src={process.env.PUBLIC_URL + '/assets/chef_hat.png'}
			/>
			<Typography.Title className='home-title'>
				{t('home.title')} {user_info.first_name}!
			</Typography.Title>
			<Typography.Text type='secondary'>{t('home.subtitle')}</Typography.Text>
		</Flex>
	)
}

export default Home;
