import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Badge, Card, Modal, Popconfirm, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, QuestionCircleFilled, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import PropTypes from 'prop-types';

// Components
import ImageRender from '../../utils/ImageRender';
import PropertyInfo from '../properties/PropertyInfo';

// Actions
import { set_state_create_id, state_delete } from '../../../redux/actions/statesActions';

// Utils
import create_query_params from '../../../utils/create_query_params';
import { transform_color_format } from '../../../utils/colors_handlers';

const StateInfo = (props) => {
	const { messageApi, ingredientId, state, setIsModalStateOpen, setUpdateState, setStateToUpdate, addStateProperty, updateStateProperty } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { state_delete_loading } = useSelector(state => state.states);

	const [stateProperty, setStateProperty] = useState({ hasProperty: false, property: {} });

	const [isModalPropertyOpen, setIsModalPropertyOpen] = useState(false);
	const [propColor, setPropColor] = useState('');

	useEffect(() => {	
		let isMounted = true;

		const filters = { state: state._id.$oid };

		Axios.get(process.env.REACT_APP_SERVER_URL + '/api/recipes/properties?' + create_query_params(filters))
		.then((response) => {
			if (isMounted) {
				if (response.data.count > 0) {
					setStateProperty({ hasProperty: true, property: response.data.properties[0] });
				}
				else {
					setStateProperty({ hasProperty: false, property: {} });
				}
			}
		})
		.catch(() => {
			if (isMounted) {
				setStateProperty({ hasProperty: false, property: {} });
			}
		});

		return () => {
			isMounted = false;
		};
	}, [state]);

	useEffect(() => {	
		if (stateProperty.hasProperty) {
			setPropColor(`#${transform_color_format(stateProperty.property.color)}`);
		}
	}, [stateProperty]);

	const handleUpdateState = (record) => {
		setIsModalStateOpen(true);
		setUpdateState(true);
		dispatch(set_state_create_id(record._id.$oid));
		setStateToUpdate(record);
	}

	const handleDeleteState = (record) => {
		const filters = { ingredient: ingredientId };
		dispatch(state_delete(messageApi, record._id.$oid, filters));
	}

	const handleCancel = () => {
		setIsModalPropertyOpen(false);
	}

	const viewStateProperty = () => {
		setIsModalPropertyOpen(true);
	}

	const _addStateProperty = () => {
		addStateProperty(state);
	}

	const _updateStateProperty = () => {
		updateStateProperty(stateProperty.property);
		handleCancel();
	}

	return (
		<div>
			<Modal
				title={t('ingredients.properties.state.title')}
				centered
				open={isModalPropertyOpen}
				onCancel={handleCancel}
				maskClosable={false}
				width={700}
				footer={null}
			>
				<div style={{ marginTop: '2rem' }}>
					<PropertyInfo
						messageApi={messageApi}
						ingredientId={ingredientId}
						propertyInfo={stateProperty.property}
						updateProperty={_updateStateProperty}
						isStateProperty={true}
					/>
				</div>
			</Modal>
			
			<Card
				style={{ height: 170 }}
				actions={[
					<CopyOutlined key='copy' onClick={() => navigator.clipboard.writeText(state._id.$oid)} />,
					<div
						key={state._id.$oid}
						onClick={stateProperty.hasProperty ? viewStateProperty : _addStateProperty}
					>
						<Badge className='anticon' dot={stateProperty.hasProperty}>
							<SettingOutlined />
						</Badge>
					</div>,
					<EditOutlined key='edit' onClick={() => handleUpdateState(state)} />,
					<Popconfirm
						title={t('ingredients.states.delete.confirmTitle')}
						description={t('ingredients.states.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => handleDeleteState(state)}
						okButtonProps={{
							loading: state_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<DeleteOutlined />
					</Popconfirm>
				]}
			>
				<Card.Meta
					avatar={
						<ImageRender
							key={state.asset}
							url={`${process.env.REACT_APP_SERVER_URL}/api/recipes/states/${state._id.$oid}/asset`}
							size={{ width: 80, height: 80 }}
							id={state._id.$oid}
							color={propColor}
						/>
					}
					title={state.name}
					description={
						<Typography.Paragraph
							style={{ marginBottom: '0.3rem' }}
							type='secondary'
							title={state.description}
							ellipsis={{ rows: 2 }}
						>
							{state.description}
						</Typography.Paragraph>
					}
				/>
			</Card>
		</div>
	)
}

StateInfo.propTypes = {
	messageApi: PropTypes.object.isRequired,
	ingredientId: PropTypes.string.isRequired,
	state: PropTypes.object.isRequired,
	setIsModalStateOpen: PropTypes.func.isRequired,
	setUpdateState: PropTypes.func.isRequired,
	setStateToUpdate: PropTypes.func.isRequired,
	addStateProperty: PropTypes.func.isRequired,
	updateStateProperty: PropTypes.func.isRequired
}

export default StateInfo;
