export const format_recipe_name = (recipeName) => {
	let preposiciones = ['a', 'ante', 'bajo', 'cabe', 'con', 'contra', 'de', 'desde', 'durante', 'en', 'entre', 'hacia', 'hasta', 'mediante', 'para', 'por', 'según', 'sin', 'so', 'sobre', 'tras', 'versus', 'vía', 'un', 'una', 'unos', 'unas', 'el', 'lo', 'los', 'la', 'las']

	const formattedName = recipeName
		.toLowerCase()
		.split(' ')
		.map(word => {
			if (preposiciones.includes(word)) {
				return word;
			}
			else {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
		})
		.join(' ');

	return formattedName;
}
