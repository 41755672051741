import {
	CLEAR_ALL_PROPERTIES_DATA,
	CLEAR_ALL_PROPERTIES_ERRORS,
	CLEAR_PROPERTIES_SINGLE_ERROR,
	PROPERTY_FORM_MODAL_OPEN,
	PROPERTY_CREATE_LOADING,
	PROPERTY_CREATE_ERROR,
	ALL_PROPERTIES_LOADING,
	ALL_PROPERTIES_GET,
	ALL_PROPERTIES_ERROR,
	PROPERTY_INFO_LOADING,
	PROPERTY_INFO_GET,
	PROPERTY_INFO_ERROR,
	PROPERTY_UPDATE_LOADING,
	PROPERTY_UPDATE_ERROR,
	PROPERTY_DELETE_LOADING
} from '../types';

const initialState = {
	property_form_modal_open: false,
	property_create_loading: false,
	all_properties_loading: false,
	all_properties: { count: 0, properties: [] },
	property_info_loading: false,
	property_info: {},
	property_update_loading: false,
	property_delete_loading: false,
	properties_errors: {}
}

export default function propertiesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_PROPERTIES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_PROPERTIES_ERRORS:
			return {
				...state,
				properties_errors: {}
			}
		case CLEAR_PROPERTIES_SINGLE_ERROR:
			return {
				...state,
				properties_errors: Object.keys(state.properties_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.properties_errors[key];
					}
					return acc;
				}, {})
			}
		case PROPERTY_FORM_MODAL_OPEN:
			return {
				...state,
				property_form_modal_open: action.payload
			}
		case PROPERTY_CREATE_LOADING:
			return {
				...state,
				property_create_loading: action.payload
			}
		case ALL_PROPERTIES_LOADING:
			return {
				...state,
				all_properties_loading: action.payload
			}
		case ALL_PROPERTIES_GET:
			return {
				...state,
				all_properties: {
					count: action.payload.count,
					properties: action.payload.properties
				}
			}
		case PROPERTY_INFO_LOADING:
			return {
				...state,
				property_info_loading: action.payload
			}
		case PROPERTY_INFO_GET:
			return {
				...state,
				property_info: action.payload
			}
		case PROPERTY_UPDATE_LOADING:
			return {
				...state,
				property_update_loading: action.payload
			}
		case PROPERTY_DELETE_LOADING:
			return {
				...state,
				property_delete_loading: action.payload
			}
		case PROPERTY_CREATE_ERROR:
		case ALL_PROPERTIES_ERROR:
		case PROPERTY_INFO_ERROR:
		case PROPERTY_UPDATE_ERROR:
			return {
				...state,
				properties_errors: {
					...state.properties_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
