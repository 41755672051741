import {
	CLEAR_ALL_RECIPES_DATA,
	CLEAR_ALL_RECIPES_ERRORS,
	CLEAR_RECIPES_ERROR,
	SET_RECIPE_ID,
	RECIPE_CREATE_LOADING,
	RECIPE_CREATE_ERROR,
	ALL_RECIPES_LOADING,
	ALL_RECIPES_GET,
	ALL_RECIPES_ERROR,
	RECIPE_DATA_LOADING,
	RECIPE_DATA_GET,
	RECIPE_DATA_ERROR,
	RECIPE_UPDATE_LOADING,
	RECIPE_UPDATE_ERROR,
	RECIPE_DELETE_LOADING,
	RECIPE_INGREDIENT_CREATE_LOADING,
	RECIPE_INGREDIENT_CREATE_ERROR,
	ALL_RECIPE_INGREDIENTS_LOADING,
	ALL_RECIPE_INGREDIENTS_GET,
	ALL_RECIPE_INGREDIENTS_ERROR,
	RECIPE_INGREDIENT_DELETE_LOADING,
	RECIPE_GAME_DATA_LOADING,
	RECIPE_GAME_DATA_GET,
	RECIPE_GAME_DATA_ERROR,
	UNLOCK_USER_RECIPE_LOADING,
	UNLOCK_USER_RECIPE_SUCCESS,
	UNLOCK_USER_RECIPE_ERROR
} from '../types';

const initialState = {
	recipe_create_loading: false,
	recipe_id: null,
	all_recipes_loading: false,
	all_recipes: { count: 0, recipes: [] },
	recipe_data_loading: false,
	recipe_data: {},
	recipe_update_loading: false,
	recipe_delete_loading: false,
	recipe_ingredient_create_loading: false,
	all_recipe_ingredients_loading: false,
	all_recipe_ingredients: [],
	recipe_ingredient_delete_loading: false,
	recipe_game_data_loading: false,
	recipe_game_data: {},
	unlock_user_recipe_loading: false,
	recipes_errors: {}
}

export default function recipesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_RECIPES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_RECIPES_ERRORS:
			return {
				...state,
				recipes_errors: {}
			}
		case CLEAR_RECIPES_ERROR:
			return {
				...state,
				recipes_errors: Object.keys(state.recipes_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.recipes_errors[key];
					}
					return acc;
				}, {})
			}
		case SET_RECIPE_ID:
			return {
				...state,
				recipe_id: action.payload
			}
		case RECIPE_CREATE_LOADING:
			return {
				...state,
				recipe_create_loading: action.payload
			}
		case ALL_RECIPES_LOADING:
			return {
				...state,
				all_recipes_loading: action.payload
			}
		case ALL_RECIPES_GET:
			return {
				...state,
				all_recipes: {
					count: action.payload.count,
					recipes: action.payload.recipes
				}
			}
		case RECIPE_DATA_LOADING:
			return {
				...state,
				recipe_data_loading: action.payload
			}
		case RECIPE_DATA_GET:
			return {
				...state,
				recipe_data: action.payload
			}
		case RECIPE_UPDATE_LOADING:
			return {
				...state,
				recipe_update_loading: action.payload
			}
		case RECIPE_DELETE_LOADING:
			return {
				...state,
				recipe_delete_loading: action.payload
			}
		case RECIPE_INGREDIENT_CREATE_LOADING:
			return {
				...state,
				recipe_ingredient_create_loading: action.payload
			}
		case ALL_RECIPE_INGREDIENTS_LOADING:
			return {
				...state,
				all_recipe_ingredients_loading: action.payload
			}
		case ALL_RECIPE_INGREDIENTS_GET:
			return {
				...state,
				all_recipe_ingredients: action.payload // FIXME:
			}
		case RECIPE_INGREDIENT_DELETE_LOADING:
			return {
				...state,
				recipe_ingredient_delete_loading: action.payload
			}
		case RECIPE_GAME_DATA_LOADING:
			return {
				...state,
				recipe_game_data_loading: action.payload
			}
		case RECIPE_GAME_DATA_GET:
			return {
				...state,
				recipe_game_data: action.payload
			}
		case UNLOCK_USER_RECIPE_LOADING:
			return {
				...state,
				unlock_user_recipe_loading: action.payload
			}
		case RECIPE_CREATE_ERROR:
		case ALL_RECIPES_ERROR:
		case RECIPE_DATA_ERROR:
		case RECIPE_UPDATE_ERROR:
		case RECIPE_INGREDIENT_CREATE_ERROR:
		case ALL_RECIPE_INGREDIENTS_ERROR:
		case RECIPE_GAME_DATA_ERROR:
		case UNLOCK_USER_RECIPE_ERROR:
			return {
				...state,
				recipes_errors: {
					...state.recipes_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
