import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, InputNumber, Popconfirm, Select, Space, Typography } from 'antd';
import { EditFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageRender from '../../utils/ImageRender';
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { challenge_create, challenge_delete, challenge_update } from '../../../redux/actions/challengesActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { challenge_types_options } from '../../../utils/select_options';
import { create_select_options_from_array } from '../../../utils/create_select_options';

const ChallengeForm = (props) => {
	const { messageApi, stepInfo, update, challengeToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { step_game_modal_open } = useSelector(state => state.steps);

	const { challenge_create_loading, challenge_id, challenge_update_loading, challenge_delete_loading, challenges_errors } = useSelector(state => state.challenges);

	const [hovered, setHovered] = useState(false);
	const [editAsset, setEditAsset] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [challengeValueLabel, setChallengeValueLabel] = useState('');

	const challengeValueLabels = {
		1: 'form.placeholders.challengeValue.time',
		2: 'form.placeholders.challengeValue.haptic',
		3: 'form.placeholders.challengeValue.value',
		4: 'form.placeholders.challengeValue.order',
	};

	const handleEditClick = () => {
		setEditAsset(true);
		setHovered(false);
	};

	const [form] = Form.useForm();

	const CHALLENGE_TYPES_OPTIONS = challenge_types_options();

	useEffect(() => {
		if (step_game_modal_open === false) {
			form.resetFields();
		}
	}, [step_game_modal_open]);

	useEffect(() => {
		if (!isEmpty(challengeToUpdate)) {
			form.setFieldsValue(challengeToUpdate);
			setChallengeValueLabel(challengeValueLabels[challengeToUpdate.challenge_type]);
		}
	}, [challengeToUpdate]);

	const onValuesChange = (changedValues, allValues) => {
		if ('challenge_type' in changedValues) {
			const label = challengeValueLabels[changedValues.challenge_type];
			if (label) {
				setChallengeValueLabel(label);
			}
		}	
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					const challengeBody = {
						...values,
						score: 0,
						recipe: stepInfo.recipe.$oid,
						step: stepInfo._id.$oid
					};
					dispatch(challenge_create(messageApi, challengeBody, stepInfo));
				}
				else {
					// Update
					const challengeBody = {
						...values,
						score: 0,
						recipe: stepInfo.recipe.$oid,
						step: stepInfo._id.$oid
					};
					dispatch(challenge_update(messageApi, challengeToUpdate._id.$oid, challengeBody));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Form
			labelCol={{ span: 5 }}
			form={form}
			onValuesChange={onValuesChange}
			onFinish={onOk}
		>
			{challenges_errors.hasOwnProperty('challenge_create') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={t('challenges.create.errorAlert')}
					type='error'
					showIcon
				/>
			}
			{challenges_errors.hasOwnProperty('challenge_update') && challenges_errors.challenge_update.msg.response.status !== 400 &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={t('challenges.edit.errorAlert')}
					type='error'
					showIcon
				/>
			}
			{challenges_errors.hasOwnProperty('challenge_update') && challenges_errors.challenge_update.msg.response.status === 400 &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={t('form.update.noChangesAlert')}
					type='info'
					showIcon
				/>
			}
			<Form.Item
				label={t('form.label.name')}
				name='name'
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<Input placeholder={t('form.placeholders.name')} />
			</Form.Item>
			<Form.Item
				label={t('form.label.description')}
				name='description'
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<Input placeholder={t('form.placeholders.description')} />
			</Form.Item>
			{/* <Form.Item
				label={t('form.label.points')}
				name='score'
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<InputNumber />
			</Form.Item> */}
			<Form.Item
				label={t('form.label.challengeType')}
				name='challenge_type'
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<Select
					showSearch
					placeholder={`${t('form.placeholders.select')} ${t('form.label.challengeType')}`}
					optionFilterProp='children'
					filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
					options={create_select_options_from_array(CHALLENGE_TYPES_OPTIONS)}
				/>
			</Form.Item>
			<Form.Item
				label={t('form.label.challengeValue')}
				name='value'
				extra={t(challengeValueLabel)}
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<InputNumber style={{ width: '100%' }} placeholder={t(challengeValueLabel)} />
			</Form.Item>
			{!isEmpty(challengeToUpdate) && (
				challengeToUpdate?.asset !== null && !editAsset ? (
					<Space align='start' size={10} style={{ marginBottom: '1rem' }}>
						<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
						<div
							className='hover-div'
							onMouseEnter={() => setHovered(true)}
							onMouseLeave={() => setHovered(false)}
						>
							{hovered && (
								<div className='hover-text' onClick={handleEditClick}>
									<EditFilled style={{ color: 'white', fontSize: '30px' }} />
								</div>
							)}
							<ImageRender
								key={challengeToUpdate.asset}
								url={`${process.env.REACT_APP_SERVER_URL}/api/game/challenges/${challengeToUpdate?._id?.$oid}/asset`}
								size={{ width: 100, height: 100 }}
							/>
						</div>
					</Space>
				) : (
					<Space align='start' size={10} style={{ marginBottom: '1rem' }}>
						<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
						<ImageUpload
							action={process.env.REACT_APP_SERVER_URL + `/api/game/challenges/${challengeToUpdate?._id?.$oid}/asset/upload`}
							actionFunction={(options) => dispatch(form_image_upload(options))}
							fileList={fileList}
							setFileList={setFileList}
						/>
					</Space>
				)
			)}
			<Form.Item
				wrapperCol={{
					offset: 5
				}}
			>
				<Space>
					<Button
						type='primary'
						htmlType='submit'
						loading={!update ? challenge_create_loading : challenge_update_loading}
					>
						{t('buttons.save')}
					</Button>
					{!isEmpty(challengeToUpdate) &&
						<Popconfirm
							title={t('challenges.delete.confirmTitle')}
							description={t('challenges.delete.confirmText')}
							icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
							onConfirm={() => dispatch(challenge_delete(messageApi, challengeToUpdate._id.$oid, form, stepInfo))}
							okButtonProps={{
								loading: challenge_delete_loading
							}}
							okText={t('buttons.confirm')}
							cancelText={t('buttons.cancel')}
						>
							<Button htmlType='button'>
								{t('buttons.delete')}
							</Button>
						</Popconfirm>
					}
				</Space>
			</Form.Item>
		</Form>
	)
}

ChallengeForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	stepInfo: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	challengeToUpdate: PropTypes.object
}

export default ChallengeForm;
