import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Flex, Row, Typography  } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import IngredientsForm from './IngredientsForm';
import IngredientsTable from './IngredientsTable';

// Actions
import { get_all_ingredients } from '../../../redux/actions/ingredientsActions';

const Ingredients = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [ingredientToUpdate, setIngredientToUpdate] = useState({});
	const [pageSize, setPageSize] = useState(10);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		const savedSkip = parseInt(localStorage.getItem('ingredientsSkip')) || 0;
		setSkip(savedSkip);

		const savedPageSize = parseInt(localStorage.getItem('ingredientsPageSize')) || 10;
		setPageSize(savedPageSize);

		const filters = { skip: savedSkip, limit: savedPageSize };
		dispatch(get_all_ingredients(filters));
	}, []);

	const handleAdd = () => {
		setIsModalOpen(true);
		setUpdate(false);
		setIngredientToUpdate({});
	}

	return (
		<div>
			<IngredientsForm
				messageApi={messageApi}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				update={update}
				ingredientToUpdate={ingredientToUpdate}
				setIngredientToUpdate={setIngredientToUpdate}
				pageSize={pageSize}
				skip={skip}
			/>

			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Typography.Title>{t('menuItems.ingredients.ingredients')}</Typography.Title>
				</Col>
				<Col xs={24} sm={6} md={6} lg={6} xl={6}>
					<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
						<Button
							type='primary'
							size='large'
							onClick={handleAdd}
						>
							{t('buttons.create')}
						</Button>
					</Flex>
				</Col>
			</Row>

			<IngredientsTable
				messageApi={messageApi}
				setIsModalOpen={setIsModalOpen}
				setUpdate={setUpdate}
				setIngredientToUpdate={setIngredientToUpdate}
				pageSize={pageSize}
				setPageSize={setPageSize}
				skip={skip}
				setSkip={setSkip}
			/>
		</div>
	)
}

Ingredients.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Ingredients;
