import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Flex, Input, Popconfirm, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { DeleteFilled, EditFilled, EyeFilled, FilterFilled, QuestionCircleFilled, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageRender from '../../utils/ImageRender';

// Actions
import { get_all_ingredients, set_ingredient_create_id, ingredient_delete } from '../../../redux/actions/ingredientsActions';
import { get_ingredient_type_desc } from '../../../utils/get_status_desc';

const IngredientsTable = (props) => {
	const { messageApi, setIsModalOpen, setUpdate, setIngredientToUpdate, pageSize, setPageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { all_ingredients_loading, all_ingredients, ingredient_delete_loading } = useSelector(state => state.ingredients);

	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState('');

	const handleSearch = (selectedKeys) => {
		const text = selectedKeys[0];
		setSearchText(text);
		
		dispatch(get_all_ingredients({ name: text }));
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');

		const ingredientsFilters = { skip: skip, limit: pageSize };
		dispatch(get_all_ingredients(ingredientsFilters));
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					style={{ marginBottom: 8, display: 'block' }}
					ref={searchInput}
					placeholder={t('form.placeholders.searchIngredient')}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => {
						handleSearch(selectedKeys);
						close();
					}}
				/>
				<Space>
					<Button
						style={{ width: 95 }}
						type='primary'
						size='small'
						icon={<SearchOutlined />}
						onClick={() => {
							handleSearch(selectedKeys);
							close();
						}}
					>
						{t('buttons.search')}
					</Button>
					<Button
						style={{ width: 110 }}
						size='small'
						icon={<SyncOutlined />}
						onClick={() => {
							clearFilters && handleReset(clearFilters);
							close();
						}}
					>
						{t('buttons.reset')}
					</Button>
				</Space>
			</div>
		),
		filterIcon: () => (
			<SearchOutlined
				style={{
					color: searchText.length > 0 ? '#F18B71' : undefined
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) => text
	});

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		dispatch(set_ingredient_create_id(record._id.$oid));
		setIngredientToUpdate(record);
	}

	const deleteIngredient = (record) => {
		let newSkip = skip;

		if ((all_ingredients.count - 1) % pageSize === 0) {
			newSkip -= pageSize;
			setSkip(newSkip);
		}

		const filters = { skip: newSkip, limit: pageSize };
		dispatch(ingredient_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			key: 'image',
			title: t('form.label.image'),
			dataIndex: 'image',
			render: (_, record) => (
				<Flex justify='center' style={{ width: '50%' }}>
					<ImageRender
						key={record.asset}
						url={`${process.env.REACT_APP_SERVER_URL}/api/recipes/ingredients/${record._id.$oid}/asset`}
						size={{ width: 50, height: 50 }}
					/>
				</Flex>
			)
		},
		{
			key: 'name',
			title: t('form.label.name'),
			dataIndex: 'name',
			...getColumnSearchProps('name'),
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			key: 'description',
			title: t('form.label.description'),
			dataIndex: 'description'
		},
		{
			key: 'category',
			title: t('form.label.category'),
			dataIndex: ['category', 'name'],
			render: (text) => text !== undefined && text !== null ? text : '-'
		},
		{
			key: 'ingredient_type',
			title: t('form.label.type'),
			dataIndex: 'ingredient_type',
			render: (text) => text !== undefined && text !== null ? get_ingredient_type_desc(text) : '-',
			filters: [
				{
					text: get_ingredient_type_desc(0),
					value: 0,
				},
				{
					text: get_ingredient_type_desc(1),
					value: 1,
				}
			],
			filterMultiple: false,
			onFilter: (value, record) => record.ingredient_type === value
		},
		{
			key: 'scale',
			title: t('form.label.scale'),
			dataIndex: 'scale'
		},
		{
			key: 'has_product_asset',
			title: t('form.label.hasProductAsset'),
			dataIndex: 'has_product_asset',
			render: (_, record) => (
				<Typography.Text>
					{record.has_product_asset === true ? t('statusDesc.bool.true') : t('statusDesc.bool.false')}
				</Typography.Text>
			)
		},
		{
			key: 'particles',
			title: t('form.label.particles'),
			dataIndex: 'particles',
			render: (_, record) => (
				<Typography.Text>
					{record.particles === true ? t('statusDesc.bool.true') : t('statusDesc.bool.false')}
				</Typography.Text>
			)
		},
		{
			key: 'action',
			title: t('table.headers.actions'),
			dataIndex: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('buttons.details')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<EyeFilled />}
							onClick={() => navigate(`/ingredients/${record.key}/details`)}
						/>
					</Tooltip>
					<Tooltip title={t('buttons.edit')}>
						<Button
							type='primary'
							style={{ backgroundColor: '#80B4B9' }}
							shape='circle'
							size='large'
							icon={<EditFilled />}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('ingredients.delete.confirmTitle')}
						description={t('ingredients.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteIngredient(record)}
						okButtonProps={{
							loading: ingredient_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<DeleteFilled />}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pagination.pageSize;
		setSkip(skip);
		localStorage.setItem('ingredientsSkip', skip);

		const limit = pagination.pageSize;
		setPageSize(limit);
		localStorage.setItem('ingredientsPageSize', limit);

		const ingredientsFilters = {
			skip,
			limit,
			...Array.isArray(filters.ingredient_type) && { ingredient_type: filters.ingredient_type[0] }
		};
		dispatch(get_all_ingredients(ingredientsFilters));
	};

	return (
		<Spin spinning={all_ingredients_loading}>
			<Table
				columns={columns}
				dataSource={all_ingredients.ingredients}
				onChange={handleTableChange}
				pagination={{
					pageSize: pageSize, 
					total: all_ingredients.count,
					current: (skip / pageSize) + 1,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('table.pagination.of')} ${total}`,
					showTitle: false,
					responsive: true,
					locale: { items_per_page: `/ ${t('table.pagination.page')}` }
				}}
			/>
		</Spin>
	)
}

IngredientsTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setIngredientToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default IngredientsTable;
