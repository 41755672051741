import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RecipesAddForm from './RecipesAddForm';
import RecipeIngredients from '../ingredients/RecipeIngredients';

// Actions
import { clear_all_recipes_data } from '../../../redux/actions/recipesActions';

const RecipesAdd = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { recipe_id } = useSelector(state => state.recipes);

	useEffect(() => {
		return () => {
			dispatch(clear_all_recipes_data());
		}
	}, []);

	return (
		<div>
			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate('/recipes')}
						/>
						<Typography.Title>{t('recipes.create.title')}</Typography.Title>
					</Space>
				</Col>
			</Row>

			<Card
				bordered={false}
			>
				<RecipesAddForm
					messageApi={messageApi}
				/>

				{recipe_id !== null &&
					<RecipeIngredients
						messageApi={messageApi}
						recipeId={recipe_id}
						showFinishButton={true}
					/>
				}
			</Card>
		</div>
	)
}

RecipesAdd.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default RecipesAdd;
