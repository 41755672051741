import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import IngredientsForm from './IngredientsForm';
import StatesForm from '../states/StatesForm';
import States from '../states/States';
import PropertiesForm from '../properties/PropertiesForm';
import Properties from '../properties/Properties';

// Actions
import { clear_all_ingredients_data, get_ingredient_info, set_ingredient_create_id } from '../../../redux/actions/ingredientsActions';
import { clear_all_properties_data } from '../../../redux/actions/propertiesActions';

const IngredientDetails = (props) => {
	const { messageApi } = props;

	const { ingredient_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { ingredient_info } = useSelector(state => state.ingredients);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [ingredientToUpdate, setIngredientToUpdate] = useState({});
	const [isModalStateOpen, setIsModalStateOpen] = useState(false);
	const [updateState, setUpdateState] = useState(false);
	const [stateToUpdate, setStateToUpdate] = useState({});

	const [updateProperty, setUpdateProperty] = useState(false);
	const [propertyToUpdate, setPropertyToUpdate] = useState({});

	useEffect(() => {
		dispatch(get_ingredient_info(ingredient_id));

		return () => {
			dispatch(clear_all_ingredients_data());
			dispatch(clear_all_properties_data());
		}
	}, [dispatch]);

	const handleUpdate = () => {
		setIsModalOpen(true);
		setUpdate(true);
		dispatch(set_ingredient_create_id(ingredient_id));
		setIngredientToUpdate(ingredient_info);
	}
	
	return (
		<div>
			<IngredientsForm
				messageApi={messageApi}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				update={update}
				ingredientToUpdate={ingredientToUpdate}
				setIngredientToUpdate={setIngredientToUpdate}
				pageSize={0}
				skip={0}
			/>

			<StatesForm
				messageApi={messageApi}
				ingredientId={ingredient_id}
				isModalStateOpen={isModalStateOpen}
				setIsModalStateOpen={setIsModalStateOpen}
				updateState={updateState}
				stateToUpdate={stateToUpdate}
				setStateToUpdate={setStateToUpdate}
				filters={{ ingredient: ingredient_id }}
			/>

			<PropertiesForm
				messageApi={messageApi}
				ingredientId={ingredient_id}
				updateProperty={updateProperty}
				setUpdateProperty={setUpdateProperty}
				propertyToUpdate={propertyToUpdate}
				setPropertyToUpdate={setPropertyToUpdate}
			/>

			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate('/ingredients')}
						/>
						<Typography.Title>{ingredient_info?.name}</Typography.Title>
					</Space>
				</Col>
				<Col xs={24} sm={6} md={6} lg={6} xl={6}>
					<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
						<Button
							type='primary'
							size='large'
							onClick={handleUpdate}
						>
							{t('buttons.edit')}
						</Button>
					</Flex>
				</Col>
			</Row>

			<Divider orientation='left'><b>{t('ingredients.states.title')}</b></Divider>
			<States
				messageApi={messageApi}
				setIsModalStateOpen={setIsModalStateOpen}
				setUpdateState={setUpdateState}
				setStateToUpdate={setStateToUpdate}
				setUpdateProperty={setUpdateProperty}
				setPropertyToUpdate={setPropertyToUpdate}
			/>

			<Divider orientation='left'><b>{t('ingredients.properties.title')}</b></Divider>
			<Properties
				messageApi={messageApi}
				setUpdateProperty={setUpdateProperty}
				setPropertyToUpdate={setPropertyToUpdate}
			/>
		</div>
	)
}

IngredientDetails.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default IngredientDetails;
