import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_RULES_DATA,
	CLEAR_ALL_RULES_ERRORS,
	CLEAR_RULES_SINGLE_ERROR,
	RULE_CREATE_LOADING,
	SET_RULE_ID,
	RULE_CREATE_ERROR,
	ALL_RULES_LOADING,
	ALL_RULES_GET,
	ALL_RULES_ERROR,
	RULE_INFO_LOADING,
	RULE_INFO_GET,
	RULE_INFO_ERROR,
	RULE_UPDATE_LOADING,
	RULE_UPDATE_ERROR,
	RULE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_rules_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_RULES_DATA
	});
}

export const clear_rules_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_RULES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_RULES_ERRORS
		});
	}
}

export const set_rule_create_id = (ruleId) => dispatch => {
	dispatch({
		type: SET_RULE_ID,
		payload: ruleId
	});
}

export const rule_create = (messageApi, ruleBody, stepInfo) => dispatch => {
	dispatch(clear_rules_errors('rule_create'));

	dispatch({
		type: RULE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/rules/create';

	Axios.post(url, ruleBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('rules.create.successAlert'), 4);

		dispatch(set_rule_create_id(res.data.rule));

		dispatch({
			type: RULE_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_rules({ step: stepInfo._id.$oid }));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RULE_CREATE_ERROR,
			payload: { type: 'rule_create', msg: err }
		});

		dispatch({
			type: RULE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_rules = (filters) => dispatch => {
	dispatch({
		type: ALL_RULES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/rules?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newRulesArray = add_key_prop(res.data.rules);
		res.data.rules = newRulesArray;

		dispatch({
			type: ALL_RULES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_RULES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_RULES_ERROR,
			payload: { type: 'get_all_rules', msg: err }
		});

		dispatch({
			type: ALL_RULES_LOADING,
			payload: false
		});
	})
}

export const get_rule_info = (ruleId) => dispatch => {
	dispatch({
		type: RULE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/rules/${ruleId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: RULE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: RULE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RULE_INFO_ERROR,
			payload: { type: 'get_rule_info', msg: err }
		});

		dispatch({
			type: RULE_INFO_LOADING,
			payload: false
		});
	})
}

export const rule_update = (messageApi, ruleId, ruleBody) => dispatch => {
	dispatch(clear_rules_errors('rule_update'));

	dispatch({
		type: RULE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/rules/${ruleId}/update`;

	Axios.put(url, ruleBody)
	.then((res) => {
		messageApi.success(i18next.t('rules.edit.successAlert'), 4);

		dispatch({
			type: RULE_UPDATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err.response.status);
		dispatch({
			type: RULE_UPDATE_ERROR,
			payload: { type: 'rule_update', msg: err }
		});

		dispatch({
			type: RULE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const rule_delete = (messageApi, ruleId, form, stepInfo) => dispatch => {
	dispatch({
		type: RULE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/rules/${ruleId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('rules.delete.successAlert'), 4);

		dispatch({
			type: RULE_DELETE_LOADING,
			payload: false
		});

		form.resetFields();
		dispatch(get_all_rules({ step: stepInfo._id.$oid }));
		dispatch(clear_rules_errors());
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('rules.delete.errorAlert'), 4);

		dispatch({
			type: RULE_DELETE_LOADING,
			payload: false
		});
	})
}
