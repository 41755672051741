export const toSeconds = (time) => {
	const parts = time.split(":");
	const minutes = parseInt(parts[0]);
	const seconds = parseInt(parts[1]);

	return (minutes * 60) + seconds;
}

export const toMinutes = (seconds) => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;

	return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}
