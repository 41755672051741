import React from 'react';

// Packages
import { Button, Flex, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const { t } = useTranslation();
	
	const navigate = useNavigate();

	const handleBackHome = () => {
		navigate('/');
	}

	return (
		<Flex vertical align='center' justify='center' style={{ height: '90vh' }}>
			<Result
				status='404'
				title='404'
				subTitle={t('notFound.subTitle')}
				extra={<Button type='primary' onClick={handleBackHome}>{t('notFound.backHome')}</Button>}
			/>
		</Flex>
	)
}

export default NotFound;
