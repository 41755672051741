import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import Haptics from '../haptics/Haptics';
import HapticsForm from '../haptics/HapticsForm';

// Actions
import { clear_all_types_data, get_type_info } from '../../../redux/actions/typesActions';

const TypeDetails = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const { action_id, type_id } = useParams();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { type_info } = useSelector(state => state.types);

	const [isHapticModalOpen, setIsHapticModalOpen] = useState(false);
	const [updateHaptic, setUpdateHaptic] = useState(false);
	const [hapticToUpdate, setHapticToUpdate] = useState({});

	useEffect(() => {
		dispatch(get_type_info(type_id));

		return () => {
			dispatch(clear_all_types_data());
		}
	}, [dispatch]);

	const handleAdd = () => {
		setIsHapticModalOpen(true);
		setUpdateHaptic(false);
		setHapticToUpdate({});
	}
	
	return (
		<div>
			<HapticsForm
				messageApi={messageApi}
				typeId={type_id}
				isHapticModalOpen={isHapticModalOpen}
				setIsHapticModalOpen={setIsHapticModalOpen}
				updateHaptic={updateHaptic}
				hapticToUpdate={hapticToUpdate}
				setHapticToUpdate={setHapticToUpdate}
				filters={{ action: type_id }}
			/>

			<Row>
				<Col xs={24} sm={18} md={18} lg={18} xl={18}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate(`/actions/${action_id}/details`)}
						/>
						<Typography.Title>{type_info?.name}</Typography.Title>
					</Space>
				</Col>
				<Col xs={24} sm={6} md={6} lg={6} xl={6}>
					<Flex align='center' justify='flex-end' style={{ height: '100%' }}>
						<Button
							type='primary'
							size='large'
							onClick={handleAdd}
						>
							{t('buttons.create')}
						</Button>
					</Flex>
				</Col>
			</Row>

			<Divider orientation='left'><b>Haptics</b></Divider>
			<Haptics
				messageApi={messageApi}
				typeId={type_id}
				setIsHapticModalOpen={setIsHapticModalOpen}
				setUpdateHaptic={setUpdateHaptic}
				setHapticToUpdate={setHapticToUpdate}
			/>
		</div>
	)
}

TypeDetails.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default TypeDetails;
