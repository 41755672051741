import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { DeleteFilled, EditFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageRender from '../../utils/ImageRender';

// Actions
import { get_all_packages, set_package_create_id, package_delete } from '../../../redux/actions/packagesActions';
import { get_recipe_status_color } from '../../../utils/get_status_color';
import { get_recipe_status_desc } from '../../../utils/get_status_desc';

const PackagesTable = (props) => {
	const { messageApi, setIsModalOpen, setUpdate, setPackageToUpdate, pageSize, setPageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_packages_loading, all_packages, package_delete_loading } = useSelector(state => state.packages);

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		dispatch(set_package_create_id(record._id.$oid));
		setPackageToUpdate(record);
	}

	const deletePackage = (record) => {
		let newSkip = skip;

		if ((all_packages.count - 1) % pageSize === 0) {
			newSkip -= pageSize;
			setSkip(newSkip);
		}

		const filters = { skip: newSkip, limit: pageSize };
		dispatch(package_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			key: 'image',
			title: t('form.label.image'),
			dataIndex: 'image',
			render: (_, record) => (
				<ImageRender
					key={record.asset}
					url={`${process.env.REACT_APP_SERVER_URL}/api/recipes/packs/${record._id.$oid}/asset`}
					size={{ width: 50, height: 50 }}
				/>
			)
		},
		{
			key: 'name',
			title: t('form.label.name'),
			dataIndex: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			key: 'description',
			title: t('form.label.description'),
			dataIndex: 'description'
		},
		{
			key: 'status',
			title: t('form.label.state'),
			dataIndex: 'status',
			render: (status) => (
				<Tag color={get_recipe_status_color(status)}>{get_recipe_status_desc(status)}</Tag>
			)
		},
		{
			key: 'worlds',
			title: t('menuItems.game.worlds'),
			dataIndex: 'worlds',
			render: (worlds) => (worlds.length > 0 ? worlds.length : '-'),
		},
		{
			key: 'recipes',
			title: t('menuItems.recipes'),
			dataIndex: 'recipes',
			render: (recipes) => (recipes.length > 0 ? recipes.length : '-'),
		},
		{
			key: 'action',
			title: t('table.headers.actions'),
			dataIndex: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('buttons.edit')}>
						<Button
							type='primary'
							style={{ backgroundColor: '#80B4B9' }}
							shape='circle'
							size='large'
							icon={<EditFilled />}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('packages.delete.confirmTitle')}
						description={t('packages.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deletePackage(record)}
						okButtonProps={{
							loading: package_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<DeleteFilled />}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pagination.pageSize;
		setSkip(skip);
		localStorage.setItem('packagesSkip', skip);

		const limit = pagination.pageSize;
		setPageSize(limit);
		localStorage.setItem('packagesPageSize', limit);

		const packagesFilters = { skip, limit };
		dispatch(get_all_packages(packagesFilters));
	};

	return (
		<Spin spinning={all_packages_loading}>
			<Table
				columns={columns}
				dataSource={all_packages.packages}
				onChange={handleTableChange}
				pagination={{
					pageSize: pageSize, 
					total: all_packages.count,
					current: (skip / pageSize) + 1,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('table.pagination.of')} ${total}`,
					showTitle: false,
					responsive: true,
					locale: { items_per_page: `/ ${t('table.pagination.page')}` }
				}}
			/>
		</Spin>
	)
}

PackagesTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setPackageToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default PackagesTable;
