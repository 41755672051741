import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import TypesForm from '../types/TypesForm';
import Types from '../types/Types';

// Actions
import { clear_all_types_data } from '../../../redux/actions/typesActions';
import { get_action_info } from '../../../redux/actions/actionsActions';

const ActionDetails = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const { action_id } = useParams();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { action_info } = useSelector(state => state.actions);

	const [isModalTypeOpen, setIsModalTypeOpen] = useState(false);
	const [updateType, setUpdateType] = useState(false);
	const [typeToUpdate, setTypeToUpdate] = useState({});

	useEffect(() => {
		dispatch(get_action_info(action_id));

		return () => {
			dispatch(clear_all_types_data());
		}
	}, [dispatch]);
	
	return (
		<div>
			<TypesForm
				messageApi={messageApi}
				actionId={action_id}
				isModalTypeOpen={isModalTypeOpen}
				setIsModalTypeOpen={setIsModalTypeOpen}
				updateType={updateType}
				typeToUpdate={typeToUpdate}
				filters={{ action: action_id }}
			/>

			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Space size='large'>
						<Button 
							style={{ marginTop: '5px' }}
							type='text'
							shape='circle'
							size='large'
							icon={<LeftOutlined className='icon-bold' />}
							onClick={() => navigate('/actions')}
						/>
						<Typography.Title>{action_info?.name}</Typography.Title>
					</Space>
				</Col>
			</Row>

			<Divider orientation='left'><b>{t('actions.types.title')}</b></Divider>
			<Types
				messageApi={messageApi}
				setIsModalTypeOpen={setIsModalTypeOpen}
				setUpdateType={setUpdateType}
				setTypeToUpdate={setTypeToUpdate}
			/>
		</div>
	)
}

ActionDetails.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default ActionDetails;
