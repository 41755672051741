import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Avatar, Button, Divider, Flex, Form, Input, Modal, Row, Select, Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256';
import PropTypes from 'prop-types';

// Components
import Icon from '../utils/Icon';

// Actions
import { clear_users_errors, user_password_update } from '../../redux/actions/usersActions';

const Language = () => {
	const { t, i18n } = useTranslation();

	const [form] = Form.useForm();

	const onChangeLanguage = (value) => {
		i18n.changeLanguage(value);
		localStorage.setItem('i18nextLng', value);
	}

	return (
		<Form
			form={form}
			layout='vertical'
			initialValues={{ language: localStorage.getItem('i18nextLng').split('-')[0] }}
		>
			<Form.Item
				label={t('form.label.language')}
				name='language'
			>
				<Select
					showSearch
					placeholder={t('form.placeholders.language')}
					optionFilterProp='children'
					filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
					options={[
						{ label: `🇲🇽 ${t('settings.language.options.spanish')}`, value: 'es' },
						{ label: `🇺🇸 ${t('settings.language.options.english')}`, value: 'en' }
					]}
					onChange={onChangeLanguage}
				/>
			</Form.Item>
		</Form>
	)
}

const Account = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user_info, user_password_update_loading, users_errors } = useSelector(state => state.users);

	const [form] = Form.useForm();

	const resetFields = () => form.resetFields();

	const onFinish = () => {
		form
			.validateFields()
			.then((values) => {
				const passwordBody = {
					password: sha256(values.password),
					confirm: sha256(values.confirm)
				}

				dispatch(clear_users_errors('user_password_update'));
				dispatch(user_password_update(messageApi, user_info?._id?.$oid, passwordBody, resetFields));
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Row>
			<Flex
				align='flex-start'
				justify='flex-start'
			>
				<Avatar style={{ marginRight: '2rem', background: '#F18B71' }} size={64}>
					{user_info?.first_name?.charAt(0)}{user_info?.last_name?.charAt(0)}
				</Avatar>
				<Space direction='vertical'>
					<Typography.Title level={4} style={{ margin: 0 }}>{user_info?.first_name} {user_info?.last_name}</Typography.Title>
					<Typography.Text>{user_info?.username}</Typography.Text>
				</Space>
			</Flex>

			<Divider />

			<Form
				layout='vertical'
				form={form}
				style={{ width: '100%'}}
				onFinish={onFinish}
			>
				{users_errors.hasOwnProperty('user_password_update') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={users_errors.user_password_update.msg}
						type='error'
						showIcon
					/>
				}
				<Form.Item
					label={t('form.label.password')}
					name='password'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						},
					]}
					hasFeedback
				>
					<Input.Password placeholder={t('form.placeholders.password')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.confirm')}
					name='confirm'
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error(t('form.rules.passwordMatch')));
							},
						}),
					]}
				>
					<Input.Password placeholder={t('form.placeholders.confirm')} />
				</Form.Item>
				<Form.Item>
					<Button
						type='primary'
						htmlType='submit'
						loading={user_password_update_loading}
					>
						{t('buttons.save')}
					</Button>
				</Form.Item>
			</Form>
		</Row>
	)
}

const Settings = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen } = props;

	const { t } = useTranslation();

	const handleCancel = () => {
		setIsModalOpen(false);
	}

	const tabItems = [
		{
			key: 'account',
			icon: (
				<span>
					<Icon icon='person' size={20} marginEnd='0.7rem' sider />
					{t('settings.account.title')}
				</span>
			),
			children: <Account messageApi={messageApi} />
		},
		{
			key: 'language',
			icon: (
				<span>
					<Icon icon='language' size={20} marginEnd='0.7rem' sider />
					{t('form.label.language')}
				</span>
			),
			children: <Language />
		}
	];

	return (
		<Modal
			title={t('menuItems.settings')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={1000}
			footer={null}
		>
			<Tabs
				tabPosition='left'
				defaultActiveKey='1'
				items={tabItems}
			/>
		</Modal>
	)
}

Settings.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired
}

export default Settings;
