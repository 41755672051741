import {
	CLEAR_ALL_STEPS_DATA,
	CLEAR_ALL_STEPS_ERRORS,
	CLEAR_STEPS_SINGLE_ERROR,
	STEP_CREATE_LOADING,
	STEP_CREATE_ERROR,
	ALL_STEPS_LOADING,
	ALL_STEPS_GET,
	ALL_STEPS_ERROR,
	STEP_INFO_LOADING,
	STEP_INFO_GET,
	STEP_INFO_ERROR,
	STEP_UPDATE_LOADING,
	STEP_UPDATE_ERROR,
	ALL_STEPS_UPDATE_LOADING,
	ALL_STEPS_UPDATE_ERROR,
	STEP_DELETE_LOADING,
	STEP_GAME_MODAL_OPEN
} from '../types';

const initialState = {
	step_create_loading: false,
	all_steps_loading: false,
	all_steps: { count: 0, steps: [] },
	step_info_loading: false,
	step_info: {},
	step_update_loading: false,
	all_steps_update_loading: false,
	step_delete_loading: false,
	step_game_modal_open: false,
	steps_errors: {}
}

export default function stepsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_STEPS_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_STEPS_ERRORS:
			return {
				...state,
				steps_errors: {}
			}
		case CLEAR_STEPS_SINGLE_ERROR:
			return {
				...state,
				steps_errors: Object.keys(state.steps_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.steps_errors[key];
					}
					return acc;
				}, {})
			}
		case STEP_CREATE_LOADING:
			return {
				...state,
				step_create_loading: action.payload
			}
		case ALL_STEPS_LOADING:
			return {
				...state,
				all_steps_loading: action.payload
			}
		case ALL_STEPS_GET:
			return {
				...state,
				all_steps: {
					count: action.payload.count,
					steps: action.payload.steps
				}
			}
		case STEP_INFO_LOADING:
			return {
				...state,
				step_info_loading: action.payload
			}
		case STEP_INFO_GET:
			return {
				...state,
				step_info: action.payload
			}
		case STEP_UPDATE_LOADING:
			return {
				...state,
				step_update_loading: action.payload
			}
		case ALL_STEPS_UPDATE_LOADING:
			return {
				...state,
				all_steps_update_loading: action.payload
			}
		case STEP_DELETE_LOADING:
			return {
				...state,
				step_delete_loading: action.payload
			}
		case STEP_GAME_MODAL_OPEN:
			return {
				...state,
				step_game_modal_open: action.payload
			}
		case STEP_CREATE_ERROR:
		case ALL_STEPS_ERROR:
		case STEP_INFO_ERROR:
		case STEP_UPDATE_ERROR:
		case ALL_STEPS_UPDATE_ERROR:
			return {
				...state,
				steps_errors: {
					...state.steps_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
