/**
 * @description - Formats a hex color string to ensure it includes an alpha value.
 * @param {string} hexColor - The hexadecimal color string. Can be 6 or 8 characters long.
 * @returns {string} - The formatted hexadecimal color string with the alpha value at the beginning.
 */
export const format_hex_color_with_alpha = (hexColor) => {
	// Check if the color has an alpha value (8 characters)
	let alphaHex, colorHex;

	if (hexColor.length === 8) {
		alphaHex = hexColor.slice(-2); // The last two characters are the alpha value
		colorHex = hexColor.slice(0, -2); // The first six characters are the color
	}
	else if (hexColor.length === 6) {
		alphaHex = "ff"; // Default alpha value (fully opaque)
		colorHex = hexColor;
	}

	// Combine alpha and color
	const transformedColor = alphaHex + colorHex;

	return transformedColor;
}

/**
 * @description - Transforms a hex color string format to ensure it handles alpha values properly. *
 * @param {string} hexColor - The hexadecimal color string. Can be 6 or 8 characters long.
 * @returns {string} - The transformed hexadecimal color string.
 */
export const transform_color_format = (hexColor) => {
	let colorValue;

	// Check if the color has an alpha value (8 characters)
	if (hexColor?.length === 8) {
		const alphaHex = hexColor.slice(0, 2); // The first two characters are the alpha value
		const colorHex = hexColor.slice(2); // The last six characters are the color

		if (alphaHex.toLowerCase() === "ff") {
			// If the alpha is "ff" (fully opaque), return only the color
			colorValue = colorHex;
		}
		else {
			// Otherwise, return the color followed by the alpha
			colorValue = colorHex + alphaHex;
		}
	}
	else if (hexColor?.length === 6) {
		// If it's already a 6-character color, return it as is
		colorValue = hexColor;
	}
	else if (hexColor === null || hexColor === undefined) {
		// If the input is null or undefined, return '00000000'
		colorValue = '00000000';
	}

	return colorValue;
}

/**
 * @description - Gets the contrast color (black or white) based on a given color.
 * @param {string} color - The hexadecimal color string.
 * @returns {string} - The contrast color (black or white).
 */
export const get_color_contrast = (color = '') => {

	const _hexcolor = color;

	let r = parseInt(_hexcolor?.substring(1, 3), 16);
	let g = parseInt(_hexcolor?.substring(3, 5), 16);
	let b = parseInt(_hexcolor?.substring(5, 7), 16);

	let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	return (yiq >= 186) ? 'black' : 'white';
}
