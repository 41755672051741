import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_INGREDIENTS_DATA,
	CLEAR_ALL_INGREDIENTS_ERRORS,
	CLEAR_INGREDIENTS_ERROR,
	INGREDIENT_CREATE_LOADING,
	SET_INGREDIENT_ID,
	INGREDIENT_CREATE_ERROR,
	ALL_INGREDIENTS_LOADING,
	ALL_INGREDIENTS_GET,
	ALL_INGREDIENTS_ERROR,
	INGREDIENT_INFO_LOADING,
	INGREDIENT_INFO_GET,
	INGREDIENT_INFO_ERROR,
	INGREDIENT_UPDATE_LOADING,
	INGREDIENT_UPDATE_ERROR,
	INGREDIENT_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_ingredients_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_INGREDIENTS_DATA
	});
}

export const clear_ingredients_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_INGREDIENTS_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_INGREDIENTS_ERRORS
		});
	}
}

export const set_ingredient_create_id = (ingredientId) => dispatch => {
	dispatch({
		type: SET_INGREDIENT_ID,
		payload: ingredientId
	});
}

export const ingredient_create = (ingredientBody) => dispatch => {
	dispatch({
		type: INGREDIENT_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/ingredients/create';

	Axios.post(url, ingredientBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_ingredient_create_id(res.data.ingredient));

		dispatch({
			type: INGREDIENT_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INGREDIENT_CREATE_ERROR,
			payload: { type: 'ingredient_create', msg: err.message }
		});

		dispatch({
			type: INGREDIENT_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_ingredients = (filters) => dispatch => {
	dispatch({
		type: ALL_INGREDIENTS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/ingredients?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newIngredientsArray = add_key_prop(res.data.ingredients);
		res.data.ingredients = newIngredientsArray;

		dispatch({
			type: ALL_INGREDIENTS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_INGREDIENTS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_INGREDIENTS_ERROR,
			payload: { type: 'get_all_ingredients', msg: err.message }
		});

		dispatch({
			type: ALL_INGREDIENTS_LOADING,
			payload: false
		});
	})
}

export const get_ingredient_info = (ingredientId) => dispatch => {
	dispatch({
		type: INGREDIENT_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/ingredients/${ingredientId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: INGREDIENT_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: INGREDIENT_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INGREDIENT_INFO_ERROR,
			payload: { type: 'get_ingredient_info', msg: err.message }
		});

		dispatch({
			type: INGREDIENT_INFO_LOADING,
			payload: false
		});
	})
}

export const ingredient_update = (messageApi, ingredientId, ingredientBody, handleCancel) => dispatch => {
	dispatch({
		type: INGREDIENT_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/ingredients/${ingredientId}/update`;

	Axios.put(url, ingredientBody)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.edit.successAlert'), 4);

		dispatch({
			type: INGREDIENT_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: INGREDIENT_UPDATE_ERROR,
				payload: { type: 'ingredient_update', msg: err.message }
			});
		}

		dispatch({
			type: INGREDIENT_UPDATE_LOADING,
			payload: false
		});
	})
}

export const ingredient_delete = (messageApi, ingredientId, filters) => dispatch => {
	dispatch({
		type: INGREDIENT_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/ingredients/${ingredientId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('ingredients.delete.successAlert'), 4);

		dispatch({
			type: INGREDIENT_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_ingredients(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('ingredients.delete.errorAlert'), 4);

		dispatch({
			type: INGREDIENT_DELETE_LOADING,
			payload: false
		});
	})
}
